import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { API } from 'aws-amplify'
import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SaveIcon from '@mui/icons-material/Save'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import addIcon from '../../assets/images/icon-add-plus-circle.svg'
import downloadIcon from '../../assets/images/icon-download.svg'
import fileIcon from '../../assets/images/icon-file.svg'
import Success from '../../assets/images/success.svg'
import CustomCountryCodeField from '../../components/CustomCountryCodeField'
import CustomDateComponent from '../../components/CustomDateComponent'
import CustomPurchaseCostField from '../../components/CustomPurchaseCostField'
import CustomSecurityNameField from '../../components/CustomSecurityNameField'
import CustomSharesField from '../../components/CustomSharesField'
import InvalidRowEntriesPopup from '../../components/InvalidRowEntriesPopup'
import SchwabDataPopup from '../../components/SchwabDataPopup'
import { useAuth } from '../../contexts/AuthContext'
import { FundingSource } from '../../contstants/constants'
import { useErrorToast } from '../../hooks/useErrorToast'
import { isNumberOnly, isNumberWithSign, numberOnlyPasteHandler, numberWithSignPasteHandler } from '../../utils/NumberUtils'
import { checkInstrumentSearchQuery } from '../../utils/searchQueryUtils'
import Loader from '../Loader'
import './AccountOnboarding.scss'
import { InputAdornmentTextField } from './components/inputAdornmentTextfield'
import AddPortfolioEntryPopup from '../../components/AddPortfolioEntryPopup'

// Extend dayjs with the utc plugin
dayjs.extend(utc)

const SampleColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'symbol', headerName: 'Symbol', flex: 1 },
  { field: 'shares', headerName: 'Shares', flex: 1, type: 'number' },
  { field: 'purchaseCost', headerName: 'Total Purchase Cost', flex: 1, type: 'number' },
  { field: 'purchaseDate', headerName: 'Purchase Date', flex: 1 },
  { field: 'isoCountryName', headerName: 'ISO Country Code', flex: 1 }
]

const PortfolioTab = ({
  submitRef,
  setData,
  custodianData,
  data,
  fileIdExists,
  accountDetails
}) => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [countryOptions, setCountryOptions] = useState([])
  const [successMessage, setSuccessMessage] = useState('Checking')
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [errorModalContent, setErrorModalContent] = useState([])
  const [validatedPortfolioData, setValidatedPortfolioData] = useState(
    ((data && data?.file && data?.validatedPortfolioData) || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.validatedPortfolioData)) ? data?.validatedPortfolioData : {}
  )
  const [isSchwabDataModelOpen, setIsSchwabDataModelOpen] = useState(false)
  const [isSecurityOrCountryOptionsLoading, setIsSecurityOrCountryOptionsLoading] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [file, setFile] = useState(data && data?.file)
  const [fileId, setFileId] = useState(fileIdExists || '')
  const [stagedData, setStagedData] = useState(
    ((data && data?.file && data?.stagedData) || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData)) ? data?.stagedData : []
  )
  const copyStagedData = useRef(
    ((data && data?.file && data?.stagedData) || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData)) ? data?.stagedData : []
  )
  const [newRowCount, setNewRowCount] = useState(0)
  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showtax, setShowtax] = useState(!!data?.file || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData))
  const [invalidStagedData, setInvalidStagedData] = useState([])
  const invalidCopyStagedData = useRef([])
  const [showInvalid, setShowInvalid] = useState(false)
  const [portfolioDataFromSchwab, setPortfolioDataFromSchwab] = useState({})
  const [portfolioDate, setPortfolioDate] = useState(data && data?.portfolioDate)
  const scrollToTopRef = useRef(null)
  const [rowModesModel, setRowModesModel] = useState({})
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const apiRef = useGridApiRef()
  const [addPortfolioEntryPopUpOpen, setAddPortfolioEntryPopUpOpen] = useState(false)

  const validationSchema = Yup.object({
    uploadType: Yup.string(),
    totalPortfolioMarketValue: Yup.string()
      .when('fundingSource', (fundingSource, schema) => {
        if (fundingSource?.length && (fundingSource[0] === 'SECURITY' || fundingSource[0] === 'CASH_SECURITY')) { return schema.required('Please upload the file.') }
        return schema
      }),
    custodianMarketValue: Yup.string().nullable(),
    fundingSource: Yup.string().required('Funding source required'),
    securityTaxLotsFile: Yup.mixed(),
    totalCashValue: Yup.string()
      .when('fundingSource', (fundingSource, schema) => {
        if (fundingSource?.length && (fundingSource[0] === 'CASH' || fundingSource[0] === 'CASH_SECURITY')) {
          return schema.required('Total Cash Value required')
            .test(
              'value',
              'Total Cash Value Should be greater than 0',
              (val) => fundingSource[0] === 'CASH'
                ? parseInt(val) !== undefined && !isNaN(val) && parseInt(val) > 0
                : parseInt(val) !== undefined && !isNaN(val) && parseInt(val) !== 0
            )
        }
        return schema
      })
  })

  const formateToUsdCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const formateToUsdCurrencyWithTwoDecimal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const csvConfig = {
    headers: [
      {
        name: 'Symbol',
        inputName: 'Symbol',
        required: true
      },
      {
        name: 'Purchase Date',
        inputName: 'purchaseDate',
        required: true
      },
      {
        name: 'Total Purchase Cost',
        inputName: 'purchaseCost',
        required: true
      },
      {
        name: 'Quantity / Shares',
        inputName: 'Quantity / Shares',
        required: true
      },
      {
        name: 'ISO Country Code',
        inputName: 'isoCountryCode',
        required: false
      }
    ]
  }

  const handleCancelClose = () => {
    setAddPortfolioEntryPopUpOpen(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    setError,
    clearErrors,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:
      data && Object.keys(data)?.length === 0
        ? {
            totalPortfolioMarketValue: '',
            custodianMarketValue: '',
            totalCashValue: '',
            fundingSource: '',
            uploadType: 'manual'
          }
        : {
            totalPortfolioMarketValue: data?.totalPortfolioMarketValue,
            custodianMarketValue: data?.custodianMarketValue || data?.validatedPortfolioData?.schwabPortfolioMarketValue,
            totalCashValue:
            data?.fundingSource === 'CASH' || data?.fundingSource === 'CASH_SECURITY'
              ? data?.totalCashValue
              : '',
            fundingSource: data?.fundingSource,
            uploadType: custodianData?.selectedCustodian?.custodianName === 'Charles Schwab' ? (data?.uploadType || 'manual') : 'manual'
          }
  })

  const fundingSourceWatch = watch('fundingSource')
  const uploadTypeWatch = watch('uploadType')

  const isCharlesSchwabAndUrlUpload = custodianData?.selectedCustodian?.custodianName === 'Charles Schwab' && uploadTypeWatch === 'urlupload'

  const [isTotalCashDisabled, setIsTotalCashDisabled] = useState(
    !!(data && data?.file && fundingSourceWatch === 'CASH_SECURITY') || (data && data?.uploadType && data?.uploadType === 'urlupload' && data?.stagedData)
  )
  const [modalShow, setModalShow] = useState(false)

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleCellEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isInvalid: false }
    if (!showInvalid) {
      const updatedData = {}
      stagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Update the staged data
      setStagedData((prevData) =>
        prevData.map((row) => (row.id === newRow.id
          ? updatedRow
          : {
              ...row,
              symbol: updatedData[row?.id]?.symbol,
              instrId: updatedData[row?.id]?.instrId,
              hasSecurityError: updatedData[row?.id]?.hasSecurityError,
              hasSharesError: updatedData[row?.id]?.hasSharesError,
              hasDateError: updatedData[row?.id]?.hasDateError,
              hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError
            }))
      )

      copyStagedData.current = copyStagedData.current.map((data) => (
        data.id === newRow.id
          ? updatedRow
          : {
              ...data
            }
      ))
    } else {
      // Add the updated row to stagedData
      setStagedData((prevData) => [updatedRow, ...prevData])

      copyStagedData.current = [updatedRow, ...copyStagedData.current]
      
      const updatedData = {}
      invalidStagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Remove the row from invalidStagedData
      setInvalidStagedData((prevData) =>
        prevData
          .filter((row) => row.id !== newRow?.id) // Remove the row with matching id
          .map((row) => ({
            ...row,
            symbol: updatedData[row?.id]?.symbol,
            instrId: updatedData[row?.id]?.instrId,
            hasSecurityError: updatedData[row?.id]?.hasSecurityError,
            hasSharesError: updatedData[row?.id]?.hasSharesError,
            hasDateError: updatedData[row?.id]?.hasDateError,
            hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError
          }))
      )

      invalidCopyStagedData.current = invalidCopyStagedData.current.filter(
        (row) => row.id !== newRow?.id
      )
    }
    return updatedRow
  }

  const handleAddNewRow = (values) => {
    const id = uuidv4()
    setStagedData((prevData) => {
      // add the new row at the beginning
      const newRow = {
        id,
        ...values,
        ...values.name,
        newRow: true
      }

      const updatedData = {}
      stagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      // Update existing rows with updated value
      const updatedRows = prevData.map((row) => ({
        ...row,
        symbol: updatedData[row?.id]?.symbol,
        instrId: updatedData[row?.id]?.instrId,
        hasSecurityError: updatedData[row?.id]?.hasSecurityError,
        hasSharesError: updatedData[row?.id]?.hasSharesError,
        hasDateError: updatedData[row?.id]?.hasDateError,
        hasPurchaseCostError: updatedData[row?.id]?.hasPurchaseCostError

      }))

      setNewRowCount((prevCount) => prevCount + 1)

      return [newRow, ...updatedRows]
    })

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View }
    }))

    setAddPortfolioEntryPopUpOpen(false)

    apiRef.current.scrollToIndexes({ rowIndex: 0 })
    apiRef.current.setPage(0)
  }

  const EditToolbar = () => {
    return (
      <GridToolbarContainer sx={{ position: 'absolute', right: 0, bottom: '-50px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          {editModeRowsLength || (newRowCount && !showInvalid)
            ? (
              <Alert
                severity='info'
                sx={{
                  padding: '1px 10px',
                  '& .MuiAlert-icon': {
                    padding: '2px 0'
                  },
                  '& .MuiAlert-message': {
                    padding: '3px 0'
                  }
                }}
              >{`Unsaved Rows: ${editModeRowsLength}${newRowCount && !showInvalid ? `  |  New Rows: ${newRowCount}` : ''}`}
              </Alert>
              )
            : (
                ''
              )}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {showInvalid
            ? (
              <Button
                style={{
                  marginRight: '5px',
                  marginBottom: '10px'
                }}
                variant='contained'
                onClick={filterInvalid}
                className='PortfolioEndBtn'
                disabled={editModeRowsLength}
              >
                Valid Entries
              </Button>
              )
            : (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => setAddPortfolioEntryPopUpOpen(true)}
                  style={{ margin: '8px' }}
                >
                  Add
                </Button>
                <Button
                  style={{
                    backgroundColor: editModeRowsLength || (!invalidStagedData?.length)
                      ? ''
                      : '#EA3D2F',
                    margin: '8px'
                  }}
                  variant='contained'
                  endIcon={<ErrorOutlineIcon />}
                  onClick={filterInvalid}
                  className='PortfolioEndBtn'
                  disabled={editModeRowsLength || (!invalidStagedData?.length)}
                >
                  Invalid Entries
                </Button>
                <Button
                  variant='contained'
                  onClick={() => checkStagingData()}
                  className='PortfolioEndBtn'
                  style={{ margin: '8px' }}
                  disabled={editModeRowsLength || (invalidStagedData && invalidStagedData?.length)}
                >
                  Validate
                </Button>
              </>
              )}
          {rowSelectionModel?.length > 0 && showInvalid && (
            <Button
              style={{
                backgroundColor: '#EA3D2F',
                color: 'white',
                textTransform: 'capitalize',
                border: '1px solid transparent !important',
                borderRadius: '10px',
                fontFamily: 'Open Sans',
                fontSize: '15px',
                marginRight: '5px',
                marginBottom: '10px'
              }}
              variant='contained'
              endIcon={<DeleteIcon />}
              onClick={handleDeleteAllClick}
            >
              {rowSelectionModel?.length === invalidStagedData?.length ? 'Delete All' : 'Delete Selected'}
            </Button>
          )}
        </Box>
      </GridToolbarContainer>
    )
  }

  const onSecurityNameChange = (query, index) => {
    if (checkInstrumentSearchQuery(query)) {
      setIsSecurityOrCountryOptionsLoading(true)
      API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/instruments`, {
        queryStringParameters: { search: query }
      })
        .then((response) => {
          if (response?.data) {
            const filteredOptions = response?.data?.filter(option =>
              option?.instrId !== '__USD' && option?.instrId !== '__CASH' &&
              option?.localSymbol !== '__USD' && option?.localSymbol !== '__CASH'
            )
            setOptions(fundingSourceWatch === 'SECURITY' ? [...filteredOptions] : [...response?.data])
            setIsSecurityOrCountryOptionsLoading(false)
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to upload portfolio.')
        })
        .finally(() => setIsSecurityOrCountryOptionsLoading(false))
    }
  }

  const fetchISOCountryCodeList = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user?.userGroup}/iso-country-codes`)
      .then((response) => {
        if (response?.data) {
          setCountryOptions(response?.data)
          setIsSecurityOrCountryOptionsLoading(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load country code.')
      })
  }

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    setModalShow(false)
    if (document.querySelectorAll('.invalid-entry')?.length > 0) {
      showError('Please correct data')
    } else {
      setShowModal(false)
      setTimeout(() => {
        document.body.classList.remove('modal-open', 'pe-0')
      }, 200)
    }
  }

  const onSubmitHandler = (data) => {
    if (!errors?.totalCashValue && !errors?.totalPortfolioMarketValue) {
      setData({ ...data, stagedData, fileId, file, uploadType: uploadTypeWatch, portfolioDate, validatedPortfolioData })
    }
  }

  const getCurrentDate = () => {
    const date = new Date()
    const day = String(date?.getDate()).padStart(2, '0')
    const month = String(date?.getMonth() + 1).padStart(2, '0')
    const year = String(date?.getFullYear())
    const currentDate = `${year}${month}${day}`
    return currentDate
  }

  const handleUpload = async () => {
    let updatedCsvData
    const fileSize = (file?.size / 1024).toFixed(2) // KB

    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      showError('Please upload csv file')
      setFile('')
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      setFile('')
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig.headers.filter(column => column.required)
            const validationOfColumns = mandatoryColumns.every(ele => headersArray.includes(ele.name))
            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns.filter(column => !headersArray.includes(column.name)).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig.headers) {
              // ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name => name === column?.name)
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // ignore column without target field
                if (header?.inputName) {
                  rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                }
              })
              // if all mandatory column are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
            } else {
              setErrorModalContent([])
              setIsErrorModelOpen(false)
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                updatedCsvData = portfolioJsonData?.map((item) => {
                  if (item?.isoCountryCode === null || item?.isoCountryCode === '') {
                    item.isoCountryCode = 'US'
                  }
                  return item
                })
                const isCashOrUSDExist = portfolioJsonData?.filter(
                  (item) => item?.Symbol === '__USD' || item?.Symbol === '__CASH'
                )
                if (fundingSourceWatch === 'SECURITY' && isCashOrUSDExist?.length > 0) {
                  showError('Cash line not allowed for funding type security only. Please re-check csv')
                  setFile('')
                } else if (
                  fundingSourceWatch === 'CASH_SECURITY' &&
                  isCashOrUSDExist?.length === 0 &&
                  getValues('totalCashValue') === ''
                ) {
                  showError('Cash line is mandatory. Please enter cash value or upload through CSV.')
                  setFile('')
                } else {
                  setIsFileUploading(true)

                  const cashValueFliter = getValues('totalCashValue')

                  if (fundingSourceWatch === 'SECURITY') {
                    getSecurityDetails(updatedCsvData || portfolioJsonData)
                  } else {
                    if (cashValueFliter) {
                      if (cashValueFliter === '') {
                        getSecurityDetails(updatedCsvData || portfolioJsonData)
                      } else {
                        const formatDate = getCurrentDate()
                        updatedCsvData = [
                          {
                            Symbol: '__USD',
                            purchaseDate: formatDate,
                            purchaseCost: 1,
                            'Quantity / Shares': cashValueFliter,
                            isoCountryCode: 'US'
                          },
                          ...(updatedCsvData || portfolioJsonData)
                        ]
                        // }

                        getSecurityDetails(updatedCsvData)
                      }
                    } else {
                      setIsFileUploading(true)
                      getSecurityDetails(updatedCsvData || portfolioJsonData)
                    }
                  }
                }
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const handleCancel = () => {
    setFile('')
    setFileId('')
    setStagedData([])
    copyStagedData.current = []
    setNewRowCount(0)
    setShowtax(false)
    setIsTotalCashDisabled(false)
    setValidatedPortfolioData({})
    setValue('totalPortfolioMarketValue', '')
    setValue('securityTaxLotsFile', '')
    setValue('custodianMarketValue', '')
  }

  const getSecurityDetails = (csvDataProcessed) => {
    API.post(
      'baseURL',
      `account-master/v1/${user.userGroup}/account-imports/preprocessed-data`,
      { body: [...csvDataProcessed] }
    )
      .then((response) => {
        if (response?.data) {
          const template = response?.data?.map((data, index) => {
            return { ...data, id: uuidv4(), hasSecurityError: true, hasPurchaseCostError: false, hasDateError: false, hasSharesError: false, isInvalid: true }
          })
          const invalidEntries = template?.filter((item) => item?.name === null)
          setInvalidStagedData(invalidEntries)
          invalidCopyStagedData.current = invalidEntries
          const validEntries = template?.filter((item) => item?.name !== null)
          if (template && template?.length) {
            setFileId(template[0]?.fileId)
          }
          setIsFileUploading(false)
          const formattedData = validEntries?.map((e, index) => {
            return {
              id: uuidv4(),
              symbol: e?.symbol,
              purchaseDate: e?.purchaseDate,
              purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
              shares: e?.shares,
              isoCountryCode: e?.isoCountryCode,
              originalIsoCountryCode: e?.isoCountryCode,
              hasSecurityError: false,
              hasDateError: false,
              hasPurchaseCostError: false,
              hasSharesError: false,
              instrId: e?.instrId,
              name: e?.name,
              isInvalid: false
            }
          })
          setStagedData(formattedData)
          copyStagedData.current = formattedData
          openModal()
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to upload portfolio.')
        setFile('')
        setFileId('')
        setIsTotalCashDisabled(false)
      })
      .finally(() => {
        setIsFileUploading(false)
      })
  }

  useEffect(() => {
    if (user) {
      fetchISOCountryCodeList()
    }
  }, [user])

  const checkStagingData = () => {
    const emptyFields = stagedData?.filter(
      (item) =>
        item?.shares === '' ||
        item?.purchaseCost === '' ||
        item?.symbol === '' ||
        item?.name === '' ||
        item?.name === null
    )?.length
    const zeroValue = stagedData
      .slice()
      .filter(
        (item) =>
          !(item?.symbol === '__USD' || item?.symbol === '__CASH') &&
          (item?.shares <= 0 || item?.shares === '')
      )?.length
    const zeroPurchasevalue = stagedData
      .slice()
      .filter(
        (item) => item?.purchaseCost < 0 || item?.purchaseCost === ''
      )?.length

    if (emptyFields && emptyFields > 0) {
      showError(
        'Error in uploaded file. Review flagged row(s), make necessary corrections before proceeding.'
      )
    } else if (zeroValue && zeroValue > 0) {
      showError('Shares must be greater than 0')
    } else if (zeroPurchasevalue && zeroPurchasevalue > 0) {
      showError('Total Purchase Cost must be greater than 0')
    } else {
      setModalShow(true)
      const filterCashUsd = stagedData?.map((item) => {
        if (item?.symbol === '__CASH') {
          item.symbol = '__USD'
        }
        return item
      })
      const formattedData = filterCashUsd?.map((e) => {
        return {
          symbol: e?.symbol,
          purchase_date: dayjs(e?.purchaseDate).format('YYYY-MM-DD'),
          purchase_cost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
          shares: e?.shares,
          instrId: e?.instrId,
          ...{
            ...isCharlesSchwabAndUrlUpload
              ? {
                  org_purchase_price: e?.orgPurchasePrice,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  org_purchase_price_unadj: e?.orgPurchasePriceUnadj ?? e?.purchaseCost,
                  purchase_cost_unadj: e?.purchaseCostUnadj ?? e?.purchaseCost,
                  shares_unadj: e?.sharesUnadj ?? e?.shares
                }
              : {}
          }
        }
      })
      const requestBody = {
        securities: formattedData,
        ...(isCharlesSchwabAndUrlUpload
          ? {
              schwabPortfolioMarketValue: portfolioDataFromSchwab?.schwabPortfolioMarketValue,
              schwabTotalLots: portfolioDataFromSchwab?.schwabTotalLots,
              rawSchwabPortfolio: portfolioDataFromSchwab?.rawSchwabPortfolio
            }
          : {})
      }
      const uploadType = uploadTypeWatch === 'urlupload' ? 'schwab' : 'manual'
      API.post(
        'baseURL',
        `account-master/v1/${user.userGroup}/validate-portfolio/${uploadType}`,
        { body: requestBody }
      )
        .then((response) => {
          if (response?.data) {
            setSuccessMessage(response?.message)
            setValidatedPortfolioData({
              ...response?.data,
              portfolio: response?.data?.portfolio?.map(item => ({
                ...item,
                id: randomId()
              }))
            })
            setValue(
              'totalPortfolioMarketValue',
              response?.data?.dbPortfolioMarketValue
            )
            if (response?.data?.schwabPortfolioMarketValue) {
              setValue('custodianMarketValue', response?.data?.schwabPortfolioMarketValue)
            }
            const temp = stagedData?.map((data) => {
              return { ...data, isInvalid: false }
            })
            setStagedData(temp)
            copyStagedData.current = temp
            setShowtax(true)
            if (fundingSourceWatch === 'CASH_SECURITY' && stagedData?.some((item) => item?.symbol === '__USD')) {
              const totalCashValue = stagedData
                .filter((item) => item?.symbol === '__USD' || item?.symbol === '__CASH')
                .reduce((sum, item) => sum + (1 * parseFloat(item?.shares)), 0)
                .toFixed(2)

              setValue('totalCashValue', parseFloat(totalCashValue || '0')?.toString())
              setIsTotalCashDisabled(true)
            }
            errors.totalPortfolioMarketValue = ''
            errors.totalCashValue = ''

            setTimeout(() => {
              closeModal()
            }, 2000)
          }
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to validate portfolio.')
          setModalShow(false)
        })
    }
  }

  function formatDate (dateString) {
    return dayjs(dateString).format('YYYY-MM-DD')
  }
  const filterInvalid = () => {
    setShowInvalid(!showInvalid)
    setOptions([])
    apiRef.current.setPage(0)
  }
  const closePopupDialog = () => {
    setShowInvalid(false)
    setShowModal(false)
    setRowModesModel({})
    if (uploadTypeWatch === 'urlupload') {
      setValue('fundingSource', '')
    }
    handleCancel()
  }

  const editModeRowsLength = Object.values(rowModesModel).filter((row) => row.mode === 'edit').length

  if (showInvalid) {
    if (invalidStagedData?.length === 0) {
      setShowInvalid(false)
    }
  }
  // Handle delete all button click
  const handleDeleteAllClick = () => {
    const isDeleteAll = rowSelectionModel.length === invalidStagedData.length
    if (isDeleteAll) {
      setInvalidStagedData([])
      invalidCopyStagedData.current = []
    } else {
      const currentEntries = invalidStagedData.filter((data) =>
        rowSelectionModel.includes(data.id)
      )

      const updatedData = {}
      invalidStagedData.forEach((d) => {
        updatedData[d?.id] = apiRef.current.getRowWithUpdatedValues(d?.id)
      })

      const remainingData = invalidStagedData
        .filter((data) => !rowSelectionModel.includes(data.id))
        .map((data) => (
          {
            ...data,
            symbol: updatedData[data?.id]?.symbol,
            instrId: updatedData[data?.id]?.instrId,
            hasSecurityError: updatedData[data?.id]?.hasSecurityError,
            hasSharesError: updatedData[data?.id]?.hasSharesError,
            hasDateError: updatedData[data?.id]?.hasDateError,
            hasPurchaseCostError: updatedData[data?.id]?.hasPurchaseCostError
          }
        ))

      setInvalidStagedData(remainingData)

      const updatedRowModesModel = { ...rowModesModel }

      currentEntries.forEach((entry) => {
        updatedRowModesModel[entry.id] = { ignoreModifications: true }
      })

      setRowModesModel(updatedRowModesModel)
    }

    setRowSelectionModel([])
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    if (!showInvalid) {
      const editedRow = copyStagedData?.current?.find((row) => row.id === id)
      apiRef.current.updateRows([{ id: editedRow?.id, ...editedRow }])
    } else {
      const editedRow = invalidCopyStagedData?.current?.find((row) => row.id === id)
      apiRef.current.updateRows([{ id: editedRow?.id, ...editedRow }])
    }
  }

  const handleDeleteClick = (id) => () => {
    if (!showInvalid) {
      setStagedData((prevStagedData) => {
        const rowToDelete = prevStagedData.find((row) => row.id === id)

        if (rowToDelete?.newRow) {
          setNewRowCount((prevCount) => prevCount - 1)
        }

        return prevStagedData.filter((row) => row.id !== id)
      })
      copyStagedData.current = copyStagedData.current.filter((data) => data?.id !== id)
    } else {
      setInvalidStagedData((prevInvalidStagedData) => prevInvalidStagedData?.filter((row) => row.id !== id))
      invalidCopyStagedData.current = invalidCopyStagedData.current.filter((data) => data?.id !== id)
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Security Name',
      flex: 2,
      editable: true,
      renderEditCell: (params, index) => {
        return (
          <CustomSecurityNameField
            {...params}
            onSecurityNameChange={onSecurityNameChange}
            isSecurityOptionsLoading={isSecurityOrCountryOptionsLoading}
            options={options}
          />
        )
      }
    },
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1,
      editable: true,
      valueGetter: (params) =>
        params?.value === '__USD' ? '__CASH' : params?.value,
      renderEditCell: (params) => {
        return params?.row?.symbol
      }
    },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      editable: true,
      type: 'number',
      valueGetter: (params) => {
        return params?.value !== null && params?.value !== undefined && !isNaN(params?.value)
          ? Number(params?.value)
          : params?.value
      },
      renderCell: (params) => {
        return params.value !== null && params.value !== undefined && !isNaN(params.value)
          ? Number(params.value)
          : ''
      },
      renderEditCell: (params) => {
        return (
          <CustomSharesField
            {...params}
          />
        )
      }
    },
    {
      field: 'purchaseCost',
      headerName: 'Total Purchase Cost',
      flex: 1,
      editable: true,
      type: 'number',
      valueGetter: (params) => {
        const sharesValue = Number(params?.row?.shares).toFixed(2)
        if (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH') {
          return !isNaN(sharesValue) ? sharesValue : null
        }
        return Number(params?.value).toFixed(2) || null
      },
      renderCell: (params) => {
        return params.value !== null && params.value !== undefined
          ? formateToUsdCurrency.format(params?.value)
          : ''
      },
      renderEditCell: (params) => {
        if (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH') {
          const sharesValue = Number(params?.row?.shares)
          return formateToUsdCurrency.format(!isNaN(sharesValue) ? sharesValue : 0)
        }
        return (
          <CustomPurchaseCostField
            {...params}
          />
        )
      }
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => dayjs(params?.value).format('YYYY-MM-DD'),
      renderEditCell: (params) => {
        return (
          <CustomDateComponent {...params} />
        )
      }
    },
    {
      field: 'isoCountryCode',
      headerName: 'ISO Country Code',
      flex: 1,
      editable: true,
      renderEditCell: (params, index) => {
        return (
          <CustomCountryCodeField
            {...params}
            isSecurityOptionsLoading={isSecurityOrCountryOptionsLoading}
            options={countryOptions}
          />
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit
        const isSaveDisabled = params?.row?.hasSecurityError || params?.row?.hasSharesError || params?.row?.hasPurchaseCostError || params?.row?.hasDateError
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              disabled={isSaveDisabled}
              key={params.id}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CloseIcon />}
              key={params.id}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(params.id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            key={params.id}
            className='textPrimary'
            onClick={handleEditClick(params.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={params.id}
            label='Delete'
            onClick={handleDeleteClick(params.id)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const rowsTable = showtax
    ? stagedData?.map((data, index) => ({
      id: index,
      name: data?.name || '',
      symbol: data?.symbol,
      shares:
        (data?.symbol === '__USD' || data?.symbol === '__CASH') ? data?.shares : (data?.shares > 0 ? data?.shares : ''),
      purchaseCost: (data?.symbol === '__CASH' || data?.symbol === '__USD')
        ? formateToUsdCurrency.format(parseFloat(data?.shares))
        : (data?.purchaseCost >= 0 ? formateToUsdCurrency?.format(data?.purchaseCost) : ''),
      purchaseDate: formatDate(data?.purchaseDate),
      isoCountryName: data?.isoCountryCode
    }))
    : [
        {
          id: 1,
          name: 'Apple Private Limited',
          symbol: 'APPL',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 2,
          name: 'Investo defensive',
          symbol: 'ABC',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 3,
          name: 'Equity ETF',
          symbol: 'DEF',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 4,
          name: 'Agilent',
          symbol: 'XYZ',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        },
        {
          id: 5,
          name: 'Technologies Inc',
          symbol: 'XAZY',
          shares: '1000.005',
          purchaseCost: '$230,359',
          purchaseDate: '20220501',
          isoCountryName: 'US'
        }
      ]

  const fetchPortfolioFromSchwab = async () => {
    try {
      setIsFileUploading(true)
      const queryParams = {
        masterAccountID: custodianData?.masterAccountId,
        custodianAccountNumber: custodianData?.custodianNumber,
        isTaxManaged: custodianData?.taxManaged
      }
      API.get('baseURL', `account-master/v1/${user?.userGroup}/schwab-portfolio/import`, { queryStringParameters: queryParams })
        .then((response) => {
          if (response?.data) {
            setPortfolioDataFromSchwab(response?.data)
            if (response?.data?.fundingSource) {
              setValue('fundingSource', response?.data?.fundingSource)
            }
            if (response?.data?.portfolioDate) {
              setPortfolioDate(response?.data?.portfolioDate)
            }
            if (response?.data?.securities) {
              const template = response?.data?.securities?.map((data, index) => {
                return { ...data, id: uuidv4(), hasSecurityError: true, hasSharesError: false, hasDateError: false, isInvalid: true }
              })
              const invalidEntries = template?.filter((item) => item?.name === null)
              setInvalidStagedData(invalidEntries)
              invalidCopyStagedData.current = invalidEntries
              const validEntries = template?.filter((item) => item?.name !== null)
              if (template && template?.length) {
                setFileId(template[0].fileId)
              }
              setIsFileUploading(false)
              const formattedData = validEntries.map((e, index) => {
                return {
                  id: uuidv4(),
                  symbol: e?.symbol,
                  purchaseDate: e?.purchaseDate,
                  purchaseCost: (e?.symbol === '__CASH' || e?.symbol === '__USD') ? 1 : e?.purchaseCost,
                  shares: e?.shares,
                  orgPurchasePrice: e?.orgPurchasePrice,
                  name: e?.name,
                  hasSecurityError: false,
                  hasPurchaseCostError: false,
                  hasSharesError: false,
                  hasDateError: false,
                  isoCountryCode: e?.isoCountryCode,
                  schwabLotMv: e?.schwabLotMv,
                  schwabLastClosePrice: e?.schwabLastClosePrice,
                  originalIsoCountryCode: e?.isoCountryCode,
                  instrId: e?.instrId,
                  orgPurchasePriceUnadj: e?.orgPurchasePriceUnadj,
                  purchaseCostUnadj: e?.purchaseCostUnadj,
                  sharesUnadj: e?.sharesUnadj,
                  isInvalid: false
                }
              })
              setStagedData(formattedData)
              copyStagedData.current = formattedData
              if (response?.data?.fundingSource === 'CASH' && response?.data?.securities?.some((item) => item?.symbol === '__USD')) {
                const purchaseCost = response?.data?.securities
                  .filter((item) => item?.symbol === '__USD')
                  .map((item) => item?.shares)
                setValue('totalCashValue', purchaseCost?.toString())
                clearErrors('totalCashValue')
                if (response?.data?.schwabPortfolioMarketValue) {
                  setValue('custodianMarketValue', response?.data?.schwabPortfolioMarketValue)
                }
                setIsTotalCashDisabled(true)
              } else {
                openModal()
              }
            }
          }
        })
        .catch((error) => {
          setIsFileUploading(false)
          showError(error, false, {}, 'Failed to fetch portfolio from schwab')
        })
    } catch (error) {
      showError(error, false, {}, 'Failed to fetch portfolio from schwab.')
    }
  }

  const getBorderColor = () => {
    if (validatedPortfolioData?.mvStatus === 'red') return '#F46A6A !important'
    if (validatedPortfolioData?.mvStatus === 'yellow') return '#F1B44C !important'
    if (validatedPortfolioData?.mvStatus === 'green') return '#34C38F !important'
    return 'default'
  }

  const customBorderColor = validatedPortfolioData?.portfolio?.length > 0 && (uploadTypeWatch === 'urlupload' || validatedPortfolioData?.mvStatus === 'red') ? getBorderColor() : 'default'

  return (
    <Box className='portfolio-tab'>
      {isFileUploading ? <Loader /> : null}
      <form noValidate onSubmit={handleSubmit(onSubmitHandler)} style={{ marginBottom: '16px' }}>
        <Card className='card-layout'>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6' component='div' className='account-label-header' mb={2}>
                Portfolio
              </Typography>
            </Box>
            <Button type='submit' ref={submitRef} style={{ display: 'none' }} />
            <Box>
              {custodianData?.selectedCustodian?.custodianName === 'Charles Schwab' && accountDetails?.accountType === 'trading'
                ? <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} display='flex' alignItems='center' mb={2} justifyContent='space-between'>
                    <Box mx={2}>
                      <Controller
                        name='uploadType'
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            value={field.value || 'manual'}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e)
                              setValue('userType', e?.target?.value)
                              setValue('fundingSource', '')
                              setFile('')
                              setFileId('')
                              setStagedData([])
                              copyStagedData.current = []
                              setNewRowCount(0)
                              setShowtax(false)
                              setIsTotalCashDisabled(false)
                              setValidatedPortfolioData({})
                              setValue('totalPortfolioMarketValue', '')
                              setValue('custodianMarketValue', '')
                              setValue('totalCashValue', '')
                              if (e?.target.value === 'urlupload') {
                                fetchPortfolioFromSchwab()
                              }
                            }}
                          >
                            <FormControlLabel
                              value='manual'
                              control={<Radio size='small' />}
                              label='Manually Upload Portfolio'
                            />
                            <FormControlLabel
                              value='urlupload'
                              control={<Radio size='small' onClick={() => uploadTypeWatch === 'urlupload' && !stagedData?.length ? fetchPortfolioFromSchwab() : ''} />}
                              label='Import from API'
                            />
                          </RadioGroup>
                        )}
                      />
                    </Box>
                    {uploadTypeWatch === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
                  </Grid>
                </Grid>
                : ''}
              {uploadTypeWatch === 'manual' || (uploadTypeWatch === 'urlupload' && stagedData?.length)
                ? <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} mb={2}>
                    <Controller
                      name='fundingSource'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id='funding-source'
                          name='fundingSource'
                          select
                          label='Funding Source *'
                          value={field?.value || ''}
                          sx={{ width: '100%', mt: '8px' }}
                          error={Boolean(errors.fundingSource)}
                          helperText={errors?.fundingSource && errors?.fundingSource?.message}
                          {...field}
                          disabled={uploadTypeWatch === 'urlupload'}
                        >
                          <MenuItem value=''>Select</MenuItem>
                          {Object.keys(FundingSource)?.map((option, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                clearErrors()
                                setFile('')
                                setFileId('')
                                setValue('totalPortfolioMarketValue', '')
                                setValue('custodianMarketValue', '')
                                setValue('totalCashValue', '')
                                setIsTotalCashDisabled(false)
                              }}
                              value={option}
                              selected={field?.value === option}
                            >
                              {FundingSource[option]}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  {(fundingSourceWatch === 'SECURITY' || fundingSourceWatch === 'CASH_SECURITY') && (
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Controller
                        name='totalPortfolioMarketValue'
                        control={control}
                        render={({ field }) => {
                          return (
                            <Box
                              sx={{
                                width: '100%'
                              }}
                            >
                              <InputAdornmentTextField
                                id='total-portfolio-market-value'
                                type='number'
                                label='Total Portfolio Market Value'
                                readOnly
                                disabled
                                {...register('totalPortfolioMarketValue')}
                                value={field?.value ? formateToUsdCurrencyWithTwoDecimal.format(field?.value || '0').replace('$', '') : field?.value}
                                aria-describedby='total-portfolio-market-value'
                                sx={{
                                  width: '100%',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: customBorderColor
                                    }
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: customBorderColor
                                  }
                                }}
                                adornment={<AttachMoneyOutlinedIcon />}
                                endAdornment={
                                 validatedPortfolioData?.portfolio?.length > 0
                                   ? <Tooltip title={validatedPortfolioData?.reason || 'View uploaded portfolio'}>
                                     <Box
                                       sx={{
                                         cursor: 'pointer',
                                         display: 'flex'
                                       }}
                                       onClick={(e) => {
                                         e.stopPropagation()
                                         setIsSchwabDataModelOpen(true)
                                       }}
                                     >
                                       <InfoOutlinedIcon
                                         sx={{
                                           color: '#74788d'
                                         }}
                                       />
                                     </Box>
                                   </Tooltip>
                                   : ''
                                }
                              />
                            </Box>
                          )
                        }}
                      />

                    </Grid>
                  )}
                  {(fundingSourceWatch && isCharlesSchwabAndUrlUpload) && (
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Controller
                        name='custodianMarketValue'
                        control={control}
                        render={({ field }) => (
                          <InputAdornmentTextField
                            id='Schwab-market-value'
                            type='number'
                            label='Custodian Market Value'
                            readOnly
                            disabled
                            {...register('custodianMarketValue')}
                            value={uploadTypeWatch === 'manual' ? 'N/A' : formateToUsdCurrencyWithTwoDecimal.format(field?.value || '0').replace('$', '')}
                            aria-describedby='Schwab-market-value'
                            sx={{
                              width: '100%'
                            }}
                            adornment={<AttachMoneyOutlinedIcon />}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {(fundingSourceWatch === 'CASH' || fundingSourceWatch === 'CASH_SECURITY') && (
                    <Grid item xs={12} sm={6} md={4} mb={2}>
                      <Controller
                        name='totalCashValue'
                        control={control}
                        render={({ field }) => (
                          <InputAdornmentTextField
                            id='total-cash-value'
                            name='totalCashValue'
                            type='text'
                            label='Total Cash Value *'
                            {...register('totalCashValue')}
                            value={isTotalCashDisabled ? Number(field.value).toLocaleString('en-US') : field.value}
                            disabled={isTotalCashDisabled}
                            onChange={(e) => {
                              const newValue = e?.target?.value
                              field?.onChange(newValue)
                              if (Number(newValue) === 0) {
                                setError('totalCashValue', {
                                  type: 'manual',
                                  message: 'Total Cash Value Should be greater than 0'
                                })
                              } else {
                                clearErrors('totalCashValue')
                              }
                            }}
                            sx={{
                              width: '100%'
                            }}
                            onKeyDown={(event) => {
                              if (fundingSourceWatch === 'CASH_SECURITY' || fundingSourceWatch === 'SECURITY') {
                                isNumberWithSign(event)
                              } else {
                                isNumberOnly(event)
                              }
                            }}
                            onPaste={(event) => {
                              if (fundingSourceWatch === 'CASH_SECURITY' || fundingSourceWatch === 'SECURITY') {
                                numberWithSignPasteHandler(event)
                              } else {
                                numberOnlyPasteHandler(event)
                              }
                            }}
                            adornment={<AttachMoneyOutlinedIcon />}
                            error={Boolean(errors?.totalCashValue)}
                            helperText={(errors?.totalCashValue && errors?.totalCashValue?.message)}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {(fundingSourceWatch === 'CASH_SECURITY' || fundingSourceWatch === 'SECURITY') && uploadTypeWatch === 'manual' && (
                    <Grid item md={12} lg={12}>
                      <FormControl fullWidth sx={{ marginY: '16px' }} error={!!errors.totalPortfolioMarketValue}>
                        <Typography sx={{ color: '#74788d', fontSize: '16px', fontWeight: 600 }} mb={1}>Upload Security Tax Lots</Typography>
                        <Box
                          className='import-portfolio file-upload-block'
                          onDragEnter={(e) => e?.preventDefault()}
                          onDragOver={(e) => e?.preventDefault()}
                          onDrop={(e) => {
                            e?.preventDefault()
                            const droppedFile = e?.dataTransfer?.files[0]
                            setFile(droppedFile)
                          }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '180px',
                            ...errors.totalPortfolioMarketValue && errors.totalPortfolioMarketValue?.message ? { border: '0.5px dashed red !important' } : {}
                          }}
                        >
                          <Box>
                            <input
                              type='file'
                              disabled={uploadTypeWatch === 'urlupload'}
                              style={{ width: '100%', height: '100%', position: 'absolute', cursor: 'pointer' }}
                              {...register('securityTaxLotsFile')}
                              onChange={(e) => {
                                setFile(e?.target?.files[0])
                                e.target.value = ''
                                data.file = ''
                              }}
                              accept='.csv'
                            />
                            <Box sx={{ display: 'flex' }}>
                              <img
                                style={{ cursor: 'pointer' }}
                                src={addIcon}
                                alt='add'
                                height={30}
                                width={30}
                              />
                              <Typography
                                pl={1}
                                sx={{ color: '#34475a', fontWeight: 600, cursor: 'pointer' }}
                              >
                                Drop your files here or browse
                              </Typography>
                            </Box>
                          </Box>
                          {file && (
                            <Box className='uploaded-file' sx={{ width: '420px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box className='btn-soft-blue' sx={{ backgroundColor: 'rgba(23, 92, 194, 0.5)', padding: '7px', color: 'white', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }} align='center' mr={1}>
                                  <img
                                    src={fileIcon}
                                    alt=''
                                    height={10}
                                    width={10}
                                  />
                                </Box>
                                <Box>
                                  <Typography>Name: {file?.name}</Typography>
                                  <Typography>Size: {file?.size}</Typography>
                                  <Typography>
                                    Last Modified:{' '}
                                    {file && file?.lastModifiedDate
                                      ? file?.lastModifiedDate?.toLocaleDateString()
                                      : ''}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }} p={1}>
                                  <Button
                                    className=''
                                    type='button'
                                    onClick={handleUpload}
                                    size='small'
                                    variant='contained'
                                    sx={{ fontSize: '12px !important' }}
                                    disabled={uploadTypeWatch === 'urlupload'}
                                  >
                                    Upload
                                  </Button>
                                  <Button
                                    className='btnRemove btn-soft-red rounded-pill'
                                    type='button'
                                    onClick={handleCancel}
                                    size='small'
                                    sx={{ fontSize: '12px !important', marginLeft: '10px' }}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                                {isFileUploading ? <Loader /> : null}
                              </Box>
                            </Box>
                          )}
                        </Box>
                        {errors?.totalPortfolioMarketValue && (
                          <FormHelperText error>{errors?.totalPortfolioMarketValue?.message}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                : ''}
            </Box>
          </CardContent>
        </Card>
      </form>
      {fundingSourceWatch === 'CASH_SECURITY' || fundingSourceWatch === 'SECURITY'
        ? <>
          <Card className='card-layout' mt={3}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(fundingSourceWatch === 'CASH_SECURITY' || fundingSourceWatch === 'SECURITY') && uploadTypeWatch === 'manual' && (
                  <Typography
                    variant='subtitle1'
                    component='p'
                    style={{ fontFamily: 'Open Sans', fontWeight: 600, display: 'inline-flex', alignItems: 'center' }}
                    className='text-title'
                  >
                    <a href={process.env.REACT_APP_ACCOUNT_SAMPLE_URL} style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <img src={downloadIcon} alt='' style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                      Sample File
                    </a>
                  </Typography>
                )}
              </Box>
              <DataGridPro
                columns={SampleColumns}
                rows={rowsTable}
                pagination
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                initialState={{
                  ...rowsTable?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                sx={{
                  border: 'none',
                  fontFamily: 'Open Sans',
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                    fontFamily: 'Open Sans',
                    color: '#34475A'
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                    fontWeight: 600,
                    fontFamily: 'Open Sans',
                    color: '#34475A'
                  }
                }}
              />
            </CardContent>
          </Card>
        </>
        : ''}
      <Dialog open={showModal} onClose={closePopupDialog} fullScreen>
        <DialogTitle>
          <IconButton
            onClick={closePopupDialog}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          {uploadTypeWatch === 'urlupload' && stagedData?.length && portfolioDate ? <h6 style={{ margin: 0, fontSize: '16px' }}>Latest Portfolio Date: {portfolioDate}</h6> : ''}
        </DialogTitle>
        <DialogContent ref={scrollToTopRef}>
          <DataGridPro
            apiRef={apiRef}
            rows={showInvalid
              ? invalidStagedData
              : stagedData}
            columns={columns}
            disableRowSelectionOnClick
            editMode='row'
            autoHeight
            getRowId={(row) => row?.id}
            getRowHeight={() => 40}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            checkboxSelection={!!showInvalid}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
            }}
            onRowEditStop={handleRowEditStop}
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            processRowUpdate={processRowUpdate}
            pagination
            pageSizeOptions={[15, 25, 50, 100]}
            getRowClassName={(params) => {
              return params?.row?.isInvalid ? 'error-row' : params?.row?.newRow ? 'new-row' : ''
            }}
            initialState={{
              ...stagedData?.initialState,
              pagination: { paginationModel: { pageSize: 15 } }
            }}
            slots={{ toolbar: EditToolbar }}
            sx={{
              '& .error-row': {
                backgroundColor: '#f05f5f1a'
              },
              // '& .new-row': {
              //   backgroundColor: '#52d21d38'
              // },
              '& .MuiDataGrid-cell': {
                cursor: 'pointer'
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none !important'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none !important'
              },
              [`.${gridClasses.main}`]: {
                height: 'calc(100vh - 96px - 40px)'
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.virtualScroller}`]: {
                overflowY: 'auto !important',
                scrollbarGutter: 'stable',
                scrollbarWidth: 'none'
              }
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={modalShow} onClose={closeModal}>
        <DialogContent
          style={{ textAlign: 'center', width: '495px', height: '266px' }}
        >
          <img src={Success} alt='' style={{ marginTop: '28px', marginLeft: '5px' }} />
          <Typography variant='h4' style={{ marginTop: '44px', marginLeft: '5px', fontSize: '24px', fontWeight: 600 }}>
            {successMessage}
          </Typography>
        </DialogContent>
      </Dialog>
      {
        isErrorModelOpen ? <InvalidRowEntriesPopup isErrorModelOpen={isErrorModelOpen} errorModalContent={errorModalContent} setIsErrorModelOpen={setIsErrorModelOpen} /> : ''
      }
      {
        isSchwabDataModelOpen
          ? <SchwabDataPopup validatedPortfolioData={validatedPortfolioData} isSchwabDataModelOpen={isSchwabDataModelOpen} setIsSchwabDataModelOpen={setIsSchwabDataModelOpen} />
          : ''
      }
      {
        addPortfolioEntryPopUpOpen && (
          <Dialog open={addPortfolioEntryPopUpOpen} onClose={handleCancelClose} fullWidth maxWidth='sm'>
            <AddPortfolioEntryPopup
              handleCancelClick={handleCancelClose}
              securityOptions={options}
              countryOptions={countryOptions}
              savedData={handleAddNewRow}
              isSecurityOptionsLoading={isSecurityOrCountryOptionsLoading}
              onSecurityNameChange={onSecurityNameChange}
            />
          </Dialog>
        )
      }
    </Box>
  )
}

export default PortfolioTab
