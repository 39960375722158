import { TryOutlined } from '@mui/icons-material'
import { Box, Card, CardContent, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // Import the UTC plugin
import '../../../../assets/styles/AccountDetailspage.scss'
import TradeStatusColumnCell from './TradeStatusColumnCell'
import { HtmlTooltip } from '../../../../components/CustomTooltip'

dayjs.extend(utc)

const AdhocTradeHistory = ({ adhocTradeHistoryData, tradeAdhocHistoryLoading, setAdhocTradeHistoryData }) => {
  const updateAdhocTradeData = (data, orderId) => {
    const index = adhocTradeHistoryData.findIndex((obj) => obj?.orderId === orderId)
    setAdhocTradeHistoryData(prevOptmRun => {
      const newArray = [...prevOptmRun]
      newArray[index] = {
        ...newArray[index],
        tradedPrice: data?.tradedPrice,
        tradedShares: data?.tradedShare,
        fixTradeStatus: data?.tradeStatusCode
      }
      return newArray
    })
  }

  const tableHeaders = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      valueGetter: (params) => params.row?.type === 'ACC' || params.row?.type === 'AGG' ? params.row?.accountName : ''
    },
    {
      field: 'accountCd',
      headerName: 'Account Code',
      valueGetter: (params) => params.row?.type === 'ACC' || params.row?.type === 'AGG' ? params.row?.accountCd || '' : ''
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: (params) => {
        const type = params.row?.type || 'N/A'
        return type === 'NONARIS' ? 'ADHOC' : type
      }
    },
    {
      field: 'accountId',
      headerName: 'Account ID',
      valueGetter: (params) => params.row?.accountId
    },
    {
      field: 'aggName',
      headerName: 'Aggregate Name',
      valueGetter: (params) => params.row?.type === 'AGG' ? params.row?.aggName : ''
    },
    {
      field: 'aggCd',
      headerName: 'Aggregate Code',
      valueGetter: (params) => params.row?.type === 'AGG' ? params.row?.aggCd || '' : ''
    },
    {
      field: 'sponsorName',
      headerName: 'Sponsor Name',
      valueGetter: (params) => params?.row?.sponsorName ? params.row?.sponsorName : ''
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian Account Number',
      renderCell: (params) => params.row?.custodianAccountNumber || 'N/A'
    },
    {
      field: 'masterAccountId',
      headerName: 'Master Account ID',
      renderCell: (params) => params.row?.masterAccountId || 'N/A'
    },
    {
      field: 'trdDate',
      headerName: 'Trade Date',
      valueFormatter: (params) => params?.value ? params?.value?.split('T')[0] : ''
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => (<>
        <span style={{ color: params.row?.trdCode === 'SELL' ? '#FF6161' : params.row?.trdCode === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params?.row?.trdCode || 'N/A'}</span>
      </>)
    },
    {
      field: 'fixTradeStatus',
      headerName: 'Fix Connectivity Status',
      width: 150,
      renderCell: (params) => (<TradeStatusColumnCell {...params} updateAdhocTradeData={updateAdhocTradeData} />),
      valueGetter: (params) => params?.row?.fixTradeStatus
    },
    {
      field: 'tradeStatus',
      headerName: 'Trade Status',
      width: 160,
      valueGetter: (params) => params?.row?.tradeStatus
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      renderCell: (params) => params.row?.localSymbol || 'N/A'
    },
    {
      field: 'instrName',
      headerName: 'Instrument Name',
      renderCell: (params) => params.row?.instrName || 'N/A'
    },
    {
      field: 'trdQty',
      headerName: 'Trade Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? params.value : null,
      renderCell: (params) => params.value !== null ? params.value : ''
    },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? params.value : null,
      renderCell: (params) => params.value !== null ? params.value : ''
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value ? parseFloat(params?.value?.toFixed(2)) : params?.value,
      renderCell: (params) => (
        params?.row?.tradedPrice !== undefined
          ? params?.row?.tradedPrice !== null
            ? params?.row?.tradedPrice < 0
              ? '-$' + Math.abs(parseFloat(params?.value?.toFixed(2)))?.toLocaleString()
              : '$' + parseFloat(params?.value?.toFixed(2))?.toLocaleString()
            : 'NA'
          : ''
      )
    },
    {
      field: 'tradedMarketValue',
      headerName: 'Traded MV',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value ? parseFloat(params?.value?.toFixed(2)) : params?.value,
      renderCell: (params) => (
        params?.row?.tradedMarketValue !== undefined
          ? params?.row?.tradedMarketValue !== null
            ? params?.row?.tradedMarketValue < 0
              ? '-$' + Math.abs(parseFloat(params?.value?.toFixed(2)))?.toLocaleString()
              : '$' + parseFloat(params?.value?.toFixed(2))?.toLocaleString()
            : 'NA'
          : ''
      )
    },
    {
      field: 'rglAsPerTradedPrice',
      headerName: 'RGL As Per Traded Price',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => params?.value ? parseFloat(params?.value?.toFixed(2)) : params?.value,
      renderCell: (params) => (
        params?.row?.rglAsPerTradedPrice !== undefined
          ? params?.row?.rglAsPerTradedPrice !== null
            ? params?.row?.rglAsPerTradedPrice < 0
              ? '-$' + Math.abs(parseFloat(params?.value?.toFixed(2)))?.toLocaleString()
              : '$' + parseFloat(params?.value?.toFixed(2))?.toLocaleString()
            : 'NA'
          : ''
      )
    },
    {
      field: 'vspDate',
      headerName: 'Purchase Date',
      renderCell: (params) => params.row?.vspDate || 'N/A'
    },
    {
      field: 'vspPrice',
      headerName: 'Original Purchase Cost',
      renderCell: (params) => params.row?.vspPrice || 'N/A'
    },
    {
      field: 'optRemarks',
      headerName: 'Trade Comments',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params?.row?.comments) {
          return (
            <HtmlTooltip tooltipStyle={{ marginRight: '10px' }}
              title={
                <Typography sx={{ fontSize: '14px' }} my={1}>{params?.row?.comments}</Typography>
              }
            >
              <TryOutlined />
            </HtmlTooltip>
          )
        } else {
          return 'N/A'
        }
      }
    }
  ]

  const getRowId = (row) => row.id
  const tableHeaderHTML = tableHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tableHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          {tradeAdhocHistoryLoading
            ? (
              <Card>
                <CardContent>
                  <Box className='table-responsive'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeaderHTML}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{rowSkeleton}</TableRow>
                        <TableRow>{rowSkeleton}</TableRow>{' '}
                      </TableBody>
                    </Table>
                  </Box>
                </CardContent>
              </Card>
              )
            : (
              <Card>
                <CardContent>
                  <Box className='table-responsive'>
                    {adhocTradeHistoryData === undefined || adhocTradeHistoryData?.length === 0
                      ? (
                        <>No adhoc trade found for given date</>
                        )
                      : (
                        <DataGridPro
                          density='compact'
                          columns={tableHeaders}
                          rows={adhocTradeHistoryData}
                          autoHeight
                          pageSizeOptions={[15]}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                accountId: false,
                                aggName: false,
                                aggCd: false,
                                sponsorName: false,
                                custodianAccountNumber: false,
                                masterAccountId: false,
                                instrName: false
                              }
                            },
                            pagination: { paginationModel: { pageSize: 15 } }
                          }}
                          pagination
                          getRowId={getRowId}
                        />
                        )}
                  </Box>
                </CardContent>
              </Card>
              )}
        </Grid>
      </Grid>
    </Box>
  )
}
export default AdhocTradeHistory
