import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { isNumberOnly, isWholeNumber, numberOnlyPasteHandler, wholeNumberPasteHandler } from '../../../utils/NumberUtils'

const AdhocOptPropertyValueField = ({ dataType, propertyValue, formik }) => {
  const handleChange = (e) => {
    formik.setFieldValue('propertyValue', e.target.value)
  }

  switch (dataType) {
    case 'INT':
    case 'DECIMAL':
    case 'STRING':
      return (
        <TextField
          fullWidth
          label='Property Value'
          size='small'
          value={propertyValue}
          onChange={handleChange}
          onKeyDown={dataType === 'INT' ? isWholeNumber : dataType === 'DECIMAL' ? isNumberOnly : undefined}
          onPaste={dataType === 'INT' ? wholeNumberPasteHandler : dataType === 'DECIMAL' ? numberOnlyPasteHandler : undefined}
          error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
          helperText={
            formik.errors.propertyValue &&
            formik.touched.propertyValue &&
            String(formik.errors.propertyValue)
          }
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )

    case 'BOOLEAN':
      return (
        <FormControl fullWidth size='small' error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}>
          <InputLabel
            id='label-id' sx={{
              color: Boolean(formik.errors.propertyValue && formik.touched.propertyValue) && formik.values.propertyValue === '' ? '#d32f2f' : ''
            }}
          >Property Value
          </InputLabel>
          <Select
            labelId='label-id'
            size='small'
            value={propertyValue || ''}
            onChange={handleChange}
            label='Property Value'
          >
            <MenuItem value='0'>0</MenuItem>
            <MenuItem value='1'>1</MenuItem>
          </Select>
          {Boolean(formik.errors.propertyValue && formik.touched.propertyValue) && (
            <FormHelperText sx={{ color: '#d32f2f', marginLeft: '5px' }}>Property value is required</FormHelperText>
          )}
        </FormControl>
      )

    case 'DATE':
      return (
        <TextField
          label='Property Value'
          type='date'
          fullWidth
          variant='outlined'
          size='small'
          value={dayjs.utc(propertyValue).format('YYYY-MM-DD')}
          onChange={(event) => {
            formik.setFieldValue('propertyValue', event.target.value)
          }}
          error={Boolean(formik.errors.propertyValue && formik.touched.propertyValue)}
          helperText={
            formik.errors.propertyValue &&
            formik.touched.propertyValue &&
            String(formik.errors.propertyValue)
          }
          InputLabelProps={{ shrink: true }}
          FormHelperTextProps={{
            style: { marginLeft: '5px' }
          }}
        />
      )

    default:
      return null
  }
}

export default AdhocOptPropertyValueField
