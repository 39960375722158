import React, { useCallback, useEffect, useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { DataGridPro, gridClasses, GridFilterInputMultipleSingleSelect, GridFilterInputSingleSelect } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import ApprovalRadioButton from './ApprovalRadioButton'
import CustomGroupingCellDILink from './CustomGroupingCellDILink'
import CustomPolicyBreakCell from './TradeApprovalIconCells/CustomPolicyBreakCell'
import CustomPropertiesDetailsCell from './TradeApprovalIconCells/CustomPropertiesDetailsCell'
import CustomTradeDetailsCell from './TradeApprovalIconCells/CustomTradeDetailsCell'
import CustomTradeSummaryChartCell from './TradeApprovalIconCells/CustomTradeSummaryChartCell'
import CustomUpdatePropertiesCell from './TradeApprovalIconCells/CustomUpdatePropertiesCell'
import PolicyStatusCell from './TradeApprovalIconCells/PolicyStatusCell'
import TradeCommentColumn from './TradeApprovalIconCells/TradeCommentColumn'
import { expansionLookup } from './TradeApprovalsMain'
import './Trade.css'

const groupingColDef = {
  headerName: '',
  cellClassName: 'name-column-row',
  headerClassName: 'name-column-header',
  field: 'group-toggle',
  renderCell: (params) => <CustomGroupingCellDILink {...params} />
}

const policySelectOperator = [
  {
    label: 'has',
    value: 'has',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return Object.values(params?.row?.policyStatus || {}).includes(filterItem?.value)
      }
    },
    InputComponent: GridFilterInputSingleSelect
  },
  {
    label: 'has not',
    value: 'has not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return !Object.values(params?.row?.policyStatus || {}).includes(filterItem.value)
      }
    },
    InputComponent: GridFilterInputSingleSelect
  },
  {
    label: 'has any of',
    value: 'has any of',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
        return null
      }
      return (params) => {
        return Object.values(params?.row?.policyStatus || {}).find(status => filterItem.value.includes(status))
      }
    },
    InputComponent: GridFilterInputMultipleSingleSelect
  },
]

const DIAssistTrading = ({
  dataArray,
  updatePendingApprovalIds,
  allScenarioList,
  apiRef,
  setTradeDatagridState,
  tradeDatagridState
}) => {
  const [data, setData] = useState(dataArray)
  const [filterModel, setFilterModel] = useState({ items: [] })
  const { checkAccess } = useAuth()

  const wholeNumberCurrencyFormatter = (amount) => {
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(0) + 'B'
      : Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(0) + 'M'
        : Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(0) + 'K'
          : Math.abs(Number(amount)).toFixed(0)
  }

  const tradeColumns = [
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.TRADE_OPT_TRADE_DETAILS })
      ? [{
        field: 'tradeDetails',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => params?.row?.disableAction ? '' : (<CustomTradeDetailsCell {...params} data={data} diTrading />)
      }]
      : []),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.VIEW_OPT_TRADE_PROPERTIES })
      ? [{
        field: 'propertiesDetails',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => params?.row?.disableAction ? '' : (<CustomPropertiesDetailsCell {...params} diTrading />)
      }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.POLICY_CHECK })
      ? [{
        field: 'policyCheck',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => params?.row?.disableAction ? '' : (<CustomPolicyBreakCell {...params} diTrading />)
      }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.TRADE_SUMMARY })
      ? [{
        field: 'tradeSummary',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => params?.row?.disableAction ? '' : (<CustomTradeSummaryChartCell {...params} data={data} diTrading />)
      }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.ACCOUNT_COMMENTS })
      ? [{
        field: 'comments',
        headerName: '',
        type: 'actions',
        width: 35,
        minWidth: 35,
        renderCell: (params) => params?.row?.disableAction ? '' : (<TradeCommentColumn {...params} diTrading />)
      }]
      : []
    ),
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.VIEW_POLICY_STATUS })
      ? [{
        field: 'policyStatus',
        headerName: 'Policy Status',
        type: 'singleSelect',
        valueOptions: ['red', 'yellow', 'green', 'gray'],
        sortable: false,
        width: 80,
        filterOperators: policySelectOperator,
        renderCell: (params) => params?.row?.disableAction ? '' : <PolicyStatusCell params={params} diTrading />
      }]
      : []
    ),
    {
      field: 'accountCd',
      headerName: 'Account Code',
    },
    {
      field: 'accOptMapId',
      headerName: 'Account Opt Map Id'
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian No',
      renderCell: (params) => (
        <Tooltip
          title={params.row.custodianAccountNumber || ''}
        >
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {params.row.custodianAccountNumber || ''}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'scenarioDesc',
      headerName: 'Scenario',
      renderCell: (params) => <Box sx={{ display: 'flex', alignItems: 'center' }} className='col col-5'>
        <Box>
          {params?.row?.isDILink ? (params?.row?.DILinkType || '') : (params?.row?.scenarioDesc || '')}
        </Box>
      </Box>
    },
    {
      field: 'approval-1',
      headerName: 'AP 1',
      width: 50,
      valueGetter: (params) => params?.row?.approvalDetails?.length > 0 ? params?.row?.approvalDetails[0].isSelected || params?.row?.approvalDetails[0].approvalStatus : 'disabled',
      sortComparator: (v1, v2) => {
        const order = ['APPROVED']
        const index1 = order.indexOf(v1)
        const index2 = order.indexOf(v2)
        return index2 - index1
      },
      renderCell: (params) => params?.row?.disableAction ? '' : <ApprovalRadioButton
        option={params.row}
        isDIRadio={params.row.isDILink}
        handleApprovalRadioClick={handleApprovalRadioClick}
        levelIndex={0}
        dIndex={params.row.dIndex}
        enabledFromCheckAccess={(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL1 }))}
      />
    },
    {
      field: 'approval-2',
      headerName: 'AP 2',
      width: 50,
      valueGetter: (params) => params?.row?.approvalDetails?.length >= 1 ? params?.row?.approvalDetails[1]?.isSelected || params?.row?.approvalDetails[1]?.approvalStatus : null,
      sortComparator: (v1, v2) => {
        const order = ['APPROVED']
        const index1 = order.indexOf(v1)
        const index2 = order.indexOf(v2)
        return index2 - index1
      },
      renderCell: (params) => params?.row?.disableAction ? '' : <ApprovalRadioButton
        option={params.row}
        isDIRadio={params.row.isDILink}
        handleApprovalRadioClick={handleApprovalRadioClick}
        levelIndex={1}
        dIndex={params.row.dIndex}
        enabledFromCheckAccess={checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL2 })}
      />
    },
    {
      field: 'initTe',
      headerName: 'initTe',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.initTe === undefined || params.row?.summaryDetails?.initTe === null ? null : parseFloat(params.row?.summaryDetails?.initTe?.toFixed(2)),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined ? '' : (params.row.summaryDetails.initTe?.toFixed(2) || '')
      }
    },
    {
      field: 'propTe',
      headerName: 'propTe',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.propTe === undefined || params.row?.summaryDetails?.propTe === null ? null : parseFloat(params.row?.summaryDetails?.propTe?.toFixed(2)),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined ? '' : (params.row.summaryDetails.propTe?.toFixed(2) || '')
      }
    },
    {
      field: 'isTaxable',
      headerName: 'isTaxable',
      headerAlign: 'right',
      align: 'right',
      type: 'boolean',
      renderCell: (params) => {
        return params.row.disableAction ? '' : params.row?.isTaxable !== undefined && params.row?.isTaxable !== null ? params.row?.isTaxable ? 'YES' : 'NO' : 'N/A'
      }
    },
    {
      field: 'taxCost',
      headerName: 'taxCost',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.taxCost?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.taxCost)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.taxCost < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.taxCost)}`
                : params.row?.summaryDetails?.taxCost > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.taxCost)}`
                  : '0'
              ))
            : ''
      }
    },
    {
      field: 'totalTurnover',
      headerName: 'totalTurnover',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter:(params) => params?.row?.summaryDetails?.totalTurnover === undefined || params?.row?.summaryDetails?.totalTurnover === null
        ? null
        : (params.row?.summaryDetails?.totalTurnover || ''),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.totalTurnover || '')
      }
    },
    {
      field: 'tradeAge',
      headerName: 'tradeAge',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.tradeAge !== undefined && params.row?.summaryDetails?.tradeAge !== null ? params.row?.summaryDetails?.tradeAge : null,
      renderCell: (params) => {
        return params.row?.summaryDetails?.tradeAge !== undefined && params.row?.summaryDetails?.tradeAge !== null ? params.row?.summaryDetails?.tradeAge : ''
      }
    },
    {
      field: 'isModelChanged',
      headerName: 'ModelChanged',
      headerAlign: 'right',
      align: 'right',
      type: 'boolean',
      valueGetter: (params) => params.row?.summaryDetails?.isModelChanged !== undefined && params.row?.summaryDetails?.isModelChanged !== null ? params.row?.summaryDetails?.isModelChanged ? 'YES' : 'NO' : null,
      renderCell: (params) => {
        return params.row?.summaryDetails?.isModelChanged !== undefined && params.row?.summaryDetails?.isModelChanged !== null ? params.row?.summaryDetails?.isModelChanged ? 'YES' : 'NO' : ''
      }
    },
    {
      field: 'initNumHoldings',
      headerName: 'initNumHoldings',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.initNumHoldings !== null || params.row?.summaryDetails?.initNumHoldings !== undefined ? params.row?.summaryDetails?.initNumHoldings : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.initNumHoldings)
      }
    },
    {
      field: 'propNumHoldings',
      headerName: 'propNumHoldings',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.numSec !== null || params.row?.summaryDetails?.numSec !== undefined ? params.row?.summaryDetails?.numSec : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.numSec)
      }
    },
    {
      field: 'buy',
      headerName: 'Buy',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.numBuy !== null || params.row?.summaryDetails?.numBuy !== undefined ? params.row?.summaryDetails?.numBuy : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.numBuy || '')
      }
    },
    {
      field: 'sell',
      headerName: 'Sell',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.numSell !== null || params.row?.summaryDetails?.numSell !== undefined ? params.row?.summaryDetails?.numSell : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.numSell || '')
      }
    },
    {
      field: 'initRisk',
      headerName: 'initRisk',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.initRisk !== null || params.row?.summaryDetails?.initRisk !== undefined ? params.row?.summaryDetails?.initRisk : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : (params.row?.summaryDetails?.initRisk?.toFixed(2) || '')
      }
    },
    {
      field: 'preOptAlpha',
      headerName: 'preOptAlpha',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.preOptAlpha !== null || params.row?.summaryDetails?.preOptAlpha !== undefined ? params.row?.summaryDetails?.preOptAlpha : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : params.row?.summaryDetails?.preOptAlpha === null
            ? (params.row?.failedScenario ? 'N/A' : '0')
            : params.row?.summaryDetails?.preOptAlpha
      }
    },
    {
      field: 'initUtil',
      headerName: 'initUtil',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params.row?.summaryDetails?.initUtil !== null || params.row?.summaryDetails?.initUtil !== undefined ? params.row?.summaryDetails?.initUtil : null,
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined ? '' : (params.row?.summaryDetails?.initUtil || '')
      }
    },
    {
      field: 'portMv',
      headerName: 'portMv',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.portMv?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.portMv)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.portMv < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.portMv)}`
                : params.row?.summaryDetails?.portMv > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.portMv)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'propLongRgl',
      headerName: 'propLongRgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.propLongRgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.propLongRgl)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.propLongRgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propLongRgl)}`
                : params.row?.summaryDetails?.propLongRgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propLongRgl)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'propShortRgl',
      headerName: 'propShortRgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.propShortRgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.propShortRgl)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.propShortRgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propShortRgl)}`
                : params.row?.summaryDetails?.propShortRgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propShortRgl)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'initLtUrgl',
      headerName: 'initLtUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.initLtUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params?.row?.summaryDetails?.initLtUrgl)
            ? (params.row?.failedScenario
              ? ''
              : (params?.row?.summaryDetails?.initLtUrgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params?.row?.summaryDetails?.initLtUrgl)}`
                : params?.row?.summaryDetails?.initLtUrgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params?.row?.summaryDetails?.initLtUrgl)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'initStUrgl',
      headerName: 'initStUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.initStUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.initStUrgl)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.initStUrgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.initStUrgl)}`
                : params.row?.summaryDetails?.initStUrgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.initStUrgl)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'propLtUrgl',
      headerName: 'propLtUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.propLtUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.propLtUrgl)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.propLtUrgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propLtUrgl)}`
                : params.row?.summaryDetails?.propLtUrgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propLtUrgl)}`
                  : '0'))
            : ''
      }
    },
    {
      field: 'propStUrgl',
      headerName: 'propStUrgl',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      valueGetter: (params) => params?.row?.summaryDetails?.propStUrgl?.toFixed(0),
      renderCell: (params) => {
        return params?.row?.summaryDetails?.preOptAlpha === undefined
          ? ''
          : !isNaN(params.row?.summaryDetails?.propStUrgl)
            ? (params.row?.failedScenario
              ? ''
              : (params.row?.summaryDetails?.propStUrgl < 0
                ? `-$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propStUrgl)}`
                : params.row?.summaryDetails?.propStUrgl > 0
                  ? `$${wholeNumberCurrencyFormatter(params.row?.summaryDetails?.propStUrgl)}`
                  : '0'))
            : ''
      }
    },
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.RUN_ADHOC })
      ? [{
        field: 'action',
        headerName: 'action',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => params?.row?.disableAction ? '' : (<CustomUpdatePropertiesCell {...params} data={data} allScenarioList={allScenarioList} />)
      }]
      : [])
  ]

  const exportDataGridState = () => {
    if (apiRef?.current?.exportState) {
      setTradeDatagridState(apiRef?.current?.exportState())
    }
  }

  const restoreDatagridState = () => {
    if (apiRef?.current?.restoreState && tradeDatagridState) {
      apiRef?.current?.restoreState({
        pinnedColumns: tradeDatagridState.pinnedColumns,
        columns: tradeDatagridState.columns
      })
    }
  }

  useEffect(() => {
    setData(dataArray)
  }, [dataArray])

  // restore the datagrid state whenever the state variables updates
  // add new state variables in the dependency array to prevent columns from getting resize and reorder
  useEffect(() => {
    restoreDatagridState()
  }, [data, tradeDatagridState, filterModel])


  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      apiRef?.current?.subscribeEvent("rowExpansionChange", (node) => {
        if (node?.groupingKey) {
          expansionLookup[node?.groupingKey] = node?.childrenExpanded
        }
      })
    }
  }, [apiRef])

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return node?.groupingKey && !!expansionLookup?.[node?.groupingKey]
    },
    []
  )

  const handleApprovalRadioClick = (e, selectedOption, dIndex, scenarioDesc, approve) => {
    let newData = data
    const index = newData[dIndex]?.optDetails?.findIndex(
      (option) => option.scenarioDesc === scenarioDesc
    )
    if (
      newData[dIndex] &&
      newData[dIndex]?.optDetails &&
      index !== -1 &&
      newData[dIndex]?.optDetails[index]?.approvalDetails &&
      newData[dIndex]?.optDetails[index]?.approvalDetails[approve]?.isSelected
    ) {
      newData = newData.map((account, actIndex) => {
        // match selected row index
        if (actIndex === dIndex) {
          return {
            ...account,
            optDetails: account.optDetails.map((option, optIndex) => {
              // match selected option index
              if (option?.approvalDetails[approve] && optIndex === index) {
                return {
                  ...option,
                  approvalDetails: option?.approvalDetails?.map((approvalObj, appIndex) => {
                    // match approval level
                    if (appIndex === approve) {
                      return {
                        ...approvalObj,
                        isSelected: false
                      }
                    }
                    return approvalObj
                  })
                }
              }
              return option
            })
          }
        }
        return account
      })
    } else {
      // set isSelected true on radio click
      newData = newData.map((account, actIndex) => {
        // match selected row index
        if (actIndex === dIndex) {
          return {
            ...account,
            optDetails: account.optDetails.map((option, optIndex) => {
              return {
                ...option,
                approvalDetails: option?.approvalDetails?.map((approvalObj, appIndex) => {
                  // match approval level
                  if (appIndex === approve) {
                    return {
                      ...approvalObj,
                      // set isSelected true on radio click
                      // set isSelected false for other scenario
                      isSelected: option?.approvalDetails[approve] && optIndex === index ? (approvalObj?.optApprovalId === selectedOption) : false
                    }
                  }
                  return approvalObj
                })
              }
            })
          }
        }
        return account
      })
    }
    const formattedData = newData
      .flatMap(account =>
        account.optDetails?.flatMap(detail =>
          detail?.approvalDetails
            .filter(approval => approval?.isSelected)
            .map(approval => ({
              accountId: account?.accountId, // store accountId
              accOptMapId: detail?.accOptMapId, // store accOptMapId
              approvalId: approval?.optApprovalId // store optApprovalId
            }))
        )
      )
    setData(newData)
    updatePendingApprovalIds(formattedData)
  }

  const newData = data.flatMap((account, actIndex) => {
    const tradeRows = [{
      id: account.optId,
      dIndex: actIndex,
      path: `${account?.accountCd}-${account?.optId}`,
      disableAction: true,
      ...account
    }
    ]
    account?.optDetails?.forEach((opt, optIndex) => {
      tradeRows.push({
        id: opt.runId,
        dIndex: actIndex,
        optIndex,
        path: opt?.isDILink ? `${account?.accountCd}-${account?.optId}/DI_LINK-${account?.optId}/${opt?.DILinkType}` : `${account?.accountCd}-${account?.optId}/${opt?.scenarioDesc}`,
        account,
        ...account,
        ...opt
      })
    })
    tradeRows.push({
      id: account.optId + "DI_ROW",
      dIndex: actIndex,
      path: `${account?.accountCd}-${account?.optId}/DI_LINK-${account?.optId}`,
      accountName: 'DI Assist',
      disableAction: true,
      DIRow: true
    })
    return tradeRows
  })

  return (
    <>
      <DataGridPro
        apiRef={apiRef}
        columns={tradeColumns}
        density='compact'
        onColumnOrderChange={() => exportDataGridState()}
        onColumnWidthChange={() => exportDataGridState()}
        rows={newData}
        initialState={{
          pinnedColumns: {
            left: ['__tree_data_group__', 'tradeDetails', 'propertiesDetails', 'policyCheck', 'tradeSummary', 'comments', 'policyStatus', 'accountName']
          },
          columns: {
            columnVisibilityModel: {
              accOptMapId: false,
              initUtil: false,
              preOptAlpha: false
            }
          }
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        treeData
        getTreeDataPath={(row) => row.path.split('/')}
        groupingColDef={groupingColDef}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        sx={(theme) => ({
          '.name-column-row': {
            paddingLeft: 0
          },
          '.name-column-header': {
            paddingLeft: '28px'
          },
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 96,
            top: 0
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            '::-webkit-scrollbar': {
              width: '0px !important'
            },
            maxHeight: 'calc(100vh - 70px - 48px - 35px - 90px)'
          }
        })}
      />
    </>
  )
}

export default DIAssistTrading
