import { createTheme } from '@mui/material'

const CustomSponsorTabTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  overrides: {
    MuiCardHeader: {
      title: {
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'center'
      }
    },
    MuiCardContent: {
      root: {
        '& p': {
          fontSize: '17.7075px',
          lineHeight: '23px',
          color: '#848484'
        }
      },
      MuiBox: {
        root: {
          borderRadius: 10
        }
      }
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Open Sans',
          backgroundColor: 'rgba(23, 92, 194, 0.8)',
          color: 'white',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
})

export { CustomSponsorTabTheme }
