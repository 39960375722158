import { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'

const CustomCountryCodeField = (props) => {
  const { id, row, isSecurityOptionsLoading, options, api } = props
  const [inputValue, setInputValue] = useState('')

  const onChangeHandler = (event, newValue) => {
    setInputValue(newValue?.code || '')
    if (newValue) {
      const expectedCountryCode = api.getRow(id)?.originalIsoCountryCode
      api.setEditCellValue({ id, field: 'isoCountryCode', value: newValue?.code })
      api.updateRows([{ id, hasSecurityError: newValue?.code !== expectedCountryCode }])
    }
  }

  return (
    <Autocomplete
      loading={isSecurityOptionsLoading}
      value={row?.isoCountryCode || row?.code}
      inputValue={inputValue}
      options={options || []}
      isOptionEqualToValue={(option, value) => {
        return option?.code === value
      }}
      getOptionDisabled={(option) =>
        option?.code === row?.isoCountryCode}
      getOptionLabel={(option) => {
        return typeof option === 'string' || option instanceof String ? option : option?.name
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={row?.isoCountryCode || row?.code}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '13px',
              fontWeight: 500
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      sx={{
        width: '400px',
        '& .MuiOutlinedInput-root': {
          padding: '1px 1px 0px 5px !important'
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue || '')
      }}
      onChange={(event, newValue) => {
        onChangeHandler(event, newValue)
      }}
    />
  )
}

export default CustomCountryCodeField
