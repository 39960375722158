import { useEffect, useMemo, useState } from 'react'
import CancelIcon from '@mui/icons-material/Close'
import { Autocomplete, Button, debounce, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isNumberOnly, isNumberWithSign } from '../utils/NumberUtils'

dayjs.extend(utc)

const AddPortfolioEntryPopup = ({ savedData, handleCancelClick, securityOptions, countryOptions, isSecurityOptionsLoading, onSecurityNameChange }) => {
  const [securityInputValue, setSecurityInputValue] = useState('')
  const [securityOptionsList, setSecurityOptionsList] = useState(securityOptions || [])
  const createFormValidationSchema = Yup.object().shape({
    name: Yup.object()
      .nullable()
      .when('isoCountryCode', ([isoCountryCode], schema) =>
        schema
          .test(
            'country-code-match',
            'Security does not exist in selected country code',
            function (value) {
              return value?.isoCountryCode === isoCountryCode
            }
          )
      )
      .required('Name is required'),
    shares: Yup.number()
      .typeError('Shares must be a number')
      .nullable()
      .when('symbol', {
        is: (symbol) => symbol === '__USD' || symbol === '__CASH',
        then: (schema) =>
          schema
            .test('not-zero', 'Shares cannot be 0', (value) => value !== 0),
        otherwise: (schema) =>
          schema
            .test('required', 'Shares is Required', (value) => value !== undefined && value !== null)
            .test('positive', 'Shares should be greater than 0', (value) => value > 0)
      }),
    purchaseCost: Yup.string().trim()
      .required('PurchaseCost is Required'),
    purchaseDate: Yup.date()
      .nullable()
      .test('required', 'Purchase Date is required', value => {
        return value !== null
      })
      .max(dayjs().utc().format('YYYY-MM-DD'), 'Purchase Date cannot be in the future'),
    isoCountryCode: Yup.string().trim()
      .test('required', 'Country Code is required', value => {
        return value !== null && value !== undefined
      })
  })

  const initialFormValues = {
    symbol: '',
    shares: '1',
    name: null,
    purchaseCost: '1',
    isoCountryCode: 'US',
    originalIsoCountryCode: 'US',
    schwabLotMv: null,
    schwabLastClosePrice: null,
    hasSecurityError: false,
    hasPurchaseCostError: false,
    hasDateError: false,
    hasSharesError: false,
    purchaseDate: null
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: createFormValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      savedData(values)
    }
  })

  const debouncedOnSecurityNameChange = useMemo(
    () => debounce((newValue) => {
      onSecurityNameChange(newValue)
    }, 400),
    []
  )

  useEffect(() => {
    setSecurityOptionsList(securityOptions)
  }, [securityOptions])

  useEffect(() => {
    if (securityInputValue && securityInputValue?.trim()?.length) {
      debouncedOnSecurityNameChange(securityInputValue)
    } else {
      setSecurityOptionsList([])
    }
  }, [securityInputValue])

  const handleSecurityChange = (e, newValue) => {
    if ((newValue?.localSymbol === '__USD' || newValue?.localSymbol === '__CASH') && !formik?.values?.purchaseCost) {
      formik.setFieldValue('purchaseCost', formik?.values?.shares * 1)
    }
    formik.setFieldValue('name', newValue)
    formik.setFieldValue('symbol', newValue?.localSymbol || '')
    formik.setFieldValue('isoCountryCode', newValue?.isoCountryCode)
    setSecurityInputValue(newValue?.name || '')
    formik.setFieldError('name', '')
    formik.setFieldTouched('name', false, false)
    formik.setFieldError('isoCountryCode', '')
    formik.setFieldTouched('isoCountryCode', false, false)
  }

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
        <Typography
          variant='h6'
          textAlign='center'
          color='#34475A'
          fontWeight={400}
        >
          Add Security
        </Typography>
        <IconButton onClick={() => { handleCancelClick() }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction='column' sx={{ mt: '1px' }}>
            <Grid item>
              <Autocomplete
                size='small'
                id='name'
                options={securityOptionsList}
                inputValue={securityInputValue}
                loading={isSecurityOptionsLoading}
                getOptionLabel={(option) => option?.name ? option?.name : ''}
                isOptionEqualToValue={(option, value) =>
                  option?.instrId === value?.instrId}
                onInputChange={(event, _) => {
                  setSecurityInputValue(event?.target?.value || '')
                }}
                onChange={(e, newValue) => handleSecurityChange(e, newValue)}
                value={formik.values.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Security Name'
                    error={Boolean(formik.errors.name && formik.touched.name)}
                    helperText={
                        formik.errors.name &&
                        formik.touched.name &&
                        String(formik.errors.name)
                      }
                    variant='outlined'
                    InputProps={{ ...params.InputProps }}
                    FormHelperTextProps={{
                      style: { marginLeft: '3px' }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                id='symbol'
                name='symbol'
                label='Symbol'
                variant='outlined'
                size='small'
                fullWidth
                disabled
                slotProps={{
                  input: {
                    readOnly: true
                  }
                }}
                value={formik.values.symbol}
                FormHelperTextProps={{
                  style: { marginLeft: '3px' }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id='shares'
                name='shares'
                label='Shares'
                variant='outlined'
                value={formik.values.shares}
                onKeyDown={(event) => {
                  if (formik?.values?.symbol === '__USD' || formik?.values?.symbol === '__CASH') {
                    isNumberWithSign(event)
                  } else {
                    isNumberOnly(event)
                  }
                }}
                size='small'
                onChange={(e, newValue) => {
                  formik.setFieldValue('shares', e.target.value)
                  if (formik?.values?.symbol === '__USD' || formik?.values?.symbol === '__CASH') {
                    formik.setFieldValue('purchaseCost', e.target.value)
                  }
                }}
                fullWidth
                error={Boolean(formik.errors.shares && formik.touched.shares)}
                helperText={
                    formik.errors.shares &&
                    formik.touched.shares &&
                    String(formik.errors.shares)
                  }
                FormHelperTextProps={{
                  style: { marginLeft: '3px' }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id='purchaseCost'
                name='purchaseCost'
                label='Purchase Cost'
                variant='outlined'
                onKeyDown={(event) => isNumberOnly(event)}
                onChange={formik.handleChange}
                size='small'
                fullWidth
                disabled={formik?.values?.symbol === '__USD' || formik?.values?.symbol === '__CASH'}
                value={formik.values.purchaseCost ? formik.values.purchaseCost : ''}
                error={Boolean(formik.errors.purchaseCost && formik.touched.purchaseCost)}
                helperText={
                    formik.errors.purchaseCost &&
                    formik.touched.purchaseCost &&
                    String(formik.errors.purchaseCost)
                  }
                FormHelperTextProps={{
                  style: { marginLeft: '3px' }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id='purchaseDate'
                name='purchaseDate'
                label='Purchase Date'
                type='date'
                fullWidth
                variant='outlined'
                size='small'
                value={dayjs.utc(formik?.values?.purchaseDate).format('YYYY-MM-DD')}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.purchaseDate && formik.touched.purchaseDate)}
                helperText={
                  formik.errors.purchaseDate &&
                  formik.touched.purchaseDate &&
                  String(formik.errors.purchaseDate)
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: dayjs.utc().format('YYYY-MM-DD')
                }}
                FormHelperTextProps={{
                  style: { marginLeft: '3px' }
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                size='small'
                id='isoCountryCode'
                options={countryOptions}
                loading={isSecurityOptionsLoading}
                isOptionEqualToValue={(option, value) => {
                  return option?.code === value
                }}
                getOptionDisabled={(option) =>
                  option?.code === formik.values.isoCountryCode}
                getOptionLabel={(option) => {
                  return typeof option === 'string' || option instanceof String ? option : option?.name
                }}
                onChange={(e, newValue) => {
                  formik.setFieldValue('isoCountryCode', newValue?.code || '')
                }}
                value={formik.values.isoCountryCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Country Code'
                    error={Boolean(formik.errors.isoCountryCode && formik.touched.isoCountryCode)}
                    helperText={
                      formik.errors.isoCountryCode &&
                      formik.touched.isoCountryCode &&
                      String(formik.errors.isoCountryCode)
                    }
                    variant='outlined'
                    InputProps={{ ...params.InputProps }}
                    FormHelperTextProps={{
                      style: { marginLeft: '3px' }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button color='primary' variant='contained' fullWidth type='submit'>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  )
}

export default AddPortfolioEntryPopup
