import { useEffect, useMemo, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Autocomplete, debounce, IconButton, TextField, Typography } from '@mui/material'
import { HtmlTooltip } from './CustomTooltip'

const CustomSecurityNameField = (props) => {
  const { onSecurityNameChange, api, id, row, isSecurityOptionsLoading, options } = props
  const [securityOptions, setSecurityOptions] = useState(options || [])
  const [value, setValue] = useState(row || null)
  const [inputValue, setInputValue] = useState('')

  const onChangeHandler = (event, newValue) => {
    setInputValue(newValue?.name || '')
    if (newValue) {
      api.setEditCellValue({ id, field: 'name', value: newValue?.name })
      api.setEditCellValue({ id, field: 'isoCountryCode', value: newValue?.isoCountryCode })
      api.setEditCellValue({ id, field: 'symbol', value: newValue?.localSymbol === '__USD' ? '__CASH' : newValue?.localSymbol })
      api.updateRows([{
        id,
        instrId: newValue?.instrId,
        originalIsoCountryCode: newValue?.isoCountryCode,
        hasSecurityError: false
      }])
      setValue(newValue)
    }
  }

  const debouncedOnSecurityNameChange = useMemo(
    () => debounce((newValue) => {
      onSecurityNameChange(newValue)
    }, 400),
    []
  )

  useEffect(() => {
    setSecurityOptions(options)
  }, [options])

  useEffect(() => {
    if (inputValue && inputValue?.trim()?.length) {
      debouncedOnSecurityNameChange(inputValue?.trim())
    } else {
      setSecurityOptions([])
    }
  }, [inputValue])

  return (
    <>
      <Autocomplete
        loading={isSecurityOptionsLoading}
        value={value}
        options={securityOptions}
        getOptionLabel={(option) => option?.name ? option?.name : ''}
        isOptionEqualToValue={(option, value) =>
          option?.instrId === value?.instrId}
        renderInput={(inputParams) => (
          <TextField
            {...inputParams}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '13px',
                fontWeight: 500
              }
            }}
            InputProps={{
              ...inputParams.InputProps,
              endAdornment: <>{inputParams.InputProps.endAdornment}</>
            }}
          />
        )}
        onInputChange={(event, _) => {
          setInputValue(event?.target?.value || '')
        }}
        onChange={(event, newValue) => onChangeHandler(event, newValue)}
        sx={{
          width: '100%',
          maxWidth: '350px',
          '& .MuiOutlinedInput-root': {
            padding: '1px 1px 0px 5px !important'
          }
        }}
      />
      {
      !row.name || row.hasSecurityError
        ? <HtmlTooltip title={
          <Typography sx={{ fontSize: '14px' }}>
            {
                    !row?.name
                      ? 'Name required'
                      : row?.hasSecurityError ? 'Security does not exist in selected country code' : ''
                }
          </Typography>
                }
          >
          <IconButton>
            <InfoOutlinedIcon sx={{ fontSize: '15px', color: '#D32F2F' }} />
          </IconButton>
          </HtmlTooltip>
        : ''
    }
    </>
  )
}

export default CustomSecurityNameField
