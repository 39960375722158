import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { API } from 'aws-amplify'
import { TryOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Input, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import Success from '../../../assets/images/success.svg'
import { HtmlTooltip } from '../../../components/CustomTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'
import AccountMarketValueModal from './AccountMarketValueModal'

const popupStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  py: 2,
  width: '100%',
  minWidth: '600px',
  maxWidth: '1600px',
  borderRadius: '4px',
  '.MuiDataGrid-root': {
    border: 'none'
  },
  '.MuiDataGrid-main': {
    // remove overflow hidden overwise sticky does not work
    overflow: 'unset'
  },
  '.MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '-4px',
    zIndex: 99,
    background: 'white'
  },
  '.MuiDataGrid-virtualScroller': {
    // remove the space left for the header
    marginTop: '-4px!important'
  }
}

const TaxlotSwappingTab = ({
  aggregateAccountData,
  aggregateDataLoading,
  onConfirm,
  accountsList,
  accountsLoading,
  taxlotSwapGridState,
  setTaxlotSwapGridState,
  onMarketValueChange,
  apiRef
}) => {
  const { user } = useAuth()
  const params = useParams()
  const { showError } = useErrorToast()
  const [showSwapOptions, setShowSwapOptions] = useState({})
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [rows, setRows] = useState(
    aggregateAccountData
      ? aggregateAccountData?.map(item => ({
        ...item,
        id: randomId(),
        children: []
      }))
      : []
  )
  const [openModal, setOpenModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [postTaxlotSwapResponse, setPostTaxlotSwapResponse] = useState({})
  const [postTaxlotSwapLoading, setPostTaxlotSwapLoading] = useState(false)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
  const [targetRowId, setTargetRowId] = useState(null)
  const accountRefs = useRef({}) // Object to store refs for account fields
  const quantityRefs = useRef({}) // Object to store refs for quantity fields
  const [showAccountName, setShowAccountName] = useState(true)
  const [openMarketValueChangeModal, setOpenMarketValueChangeModal] = useState(false)

  const handleOpenMarketValueChangeModal = () => {
    setOpenMarketValueChangeModal(true)
  }

  const handleCloseMarketValueChangeModal = () => {
    setOpenMarketValueChangeModal(false)
  }

  // Filter rows where showSwapOptions is true
  const swapOptionFilteredRows = rows?.filter(row => row && row.showSwapOptions) || []

  // Function to handle to focus of the quantity field
  const handleKeyDownQuantity = (event, rowId, isChild) => {
    const parentRow = swapOptionFilteredRows.find(row => row && (row.id === rowId || (row.children && row.children.some(child => child && child.id === rowId))))
    const parentRowIndex = swapOptionFilteredRows.findIndex(row => row && row.id === parentRow.id)
    const currentChildIndex = parentRow?.children?.findIndex(child => child && child.id === rowId)
    const isFirstParent = parentRowIndex === 0
    const isLastParent = parentRowIndex === swapOptionFilteredRows?.length - 1
    const isFirstChild = isChild && currentChildIndex === 0
    const isLastChild = isChild && currentChildIndex === (parentRow?.children?.length ?? 0) - 1
    if (event.key === 'ArrowRight' && accountRefs.current[rowId]) {
      accountRefs.current[rowId].focus()
    } else if (event.key === 'ArrowDown') {
      let nextRowId
      if (!isChild) {
        if (parentRow?.children && parentRow?.children?.length > 0) {
          // Move focus to the first child if present
          nextRowId = parentRow.children[0].id
        } else if (isLastParent) {
          // If last parent, move focus to the first parent
          nextRowId = swapOptionFilteredRows[0]?.id
        } else {
          // Move focus to the next parent row if no children
          nextRowId = swapOptionFilteredRows[parentRowIndex + 1]?.id
        }
      } else {
        if (!isLastChild) {
          // Move focus to the next sibling of the child if not the last child
          nextRowId = parentRow?.children[currentChildIndex + 1]?.id
        } else if (isLastParent) {
          // If last child and last parent, move focus to the first parent
          nextRowId = swapOptionFilteredRows[0]?.id
        } else {
          // Move focus to the next parent row if last child and not the last parent
          nextRowId = swapOptionFilteredRows[parentRowIndex + 1]?.id
        }
      }
      if (nextRowId && quantityRefs.current[nextRowId]) {
        setTimeout(() => {
          quantityRefs.current[nextRowId].focus()
        }, 0)
      }
    } else if (event.key === 'ArrowUp') {
      let previousRowId
      if (!isChild) {
        if (isFirstParent) {
          // If first parent, move focus to the last parent or its last child
          const lastParent = swapOptionFilteredRows[swapOptionFilteredRows?.length - 1]
          const lastParentChildren = lastParent?.children || []
          previousRowId = lastParentChildren?.length > 0 ? lastParentChildren[lastParentChildren?.length - 1].id : lastParent?.id
        } else {
          // Move focus to the previous parent row
          const previousParent = swapOptionFilteredRows[parentRowIndex - 1]
          const previousParentChildren = previousParent?.children || []
          previousRowId = previousParentChildren.length > 0 ? previousParentChildren[previousParentChildren?.length - 1].id : previousParent?.id
        }
      } else {
        if (isFirstChild) {
          // If first child, move focus to the parent
          previousRowId = parentRow.id
        } else if (currentChildIndex !== undefined && currentChildIndex !== -1) {
          // Move focus to the previous sibling of the child
          previousRowId = parentRow?.children[currentChildIndex - 1]?.id
        }
      }
      if (previousRowId && quantityRefs.current[previousRowId]) {
        setTimeout(() => {
          quantityRefs.current[previousRowId].focus()
        }, 0)
      }
    }
    return true
  }

  // Function to handle to focus of the account field
  const handleKeyDownAccount = (event, rowId, isChild) => {
    const parentRow = swapOptionFilteredRows.find(row => row && (row.id === rowId || (row.children && row.children.some(child => child && child.id === rowId))))
    const parentRowIndex = parentRow ? swapOptionFilteredRows.findIndex(row => row && row.id === parentRow.id) : -1
    const currentChildIndex = parentRow?.children?.findIndex(child => child && child.id === rowId)
    const isFirstParent = parentRowIndex === 0
    const isLastParent = parentRowIndex === swapOptionFilteredRows?.length - 1
    const isFirstChild = isChild && currentChildIndex === 0
    const isLastChild = isChild && currentChildIndex === (parentRow?.children?.length ?? 0) - 1
    if (event.key === 'ArrowLeft' && quantityRefs.current[rowId]) {
      quantityRefs.current[rowId].focus()
    } else if (event.key === 'ArrowDown') {
      let nextRowId
      if (!isChild) {
        if (parentRow?.children && parentRow?.children?.length > 0) {
          // Move focus to the first child if present
          nextRowId = parentRow?.children[0]?.id
        } else if (isLastParent) {
          // If last parent, move focus to the first parent
          nextRowId = swapOptionFilteredRows[0]?.id
        } else {
          // Move focus to the next parent row if no children
          nextRowId = swapOptionFilteredRows[parentRowIndex + 1]?.id
        }
      } else {
        if (!isLastChild) {
          // Move focus to the next sibling of the child if not the last child
          nextRowId = parentRow?.children[currentChildIndex + 1]?.id
        } else if (isLastParent) {
          // If last child and last parent, move focus to the first parent
          nextRowId = swapOptionFilteredRows[0]?.id
        } else {
          // Move focus to the next parent row if last child and not the last parent
          nextRowId = swapOptionFilteredRows[parentRowIndex + 1]?.id
        }
      }
      if (nextRowId && accountRefs.current[nextRowId]) {
        setTimeout(() => {
          accountRefs.current[nextRowId].focus()
        }, 0)
      }
    } else if (event.key === 'ArrowUp') {
      let previousRowId
      if (!isChild) {
        if (isFirstParent) {
          // If first parent, move focus to the last parent or its last child
          const lastParent = swapOptionFilteredRows[swapOptionFilteredRows?.length - 1]
          const lastParentChildren = lastParent?.children || []
          previousRowId = lastParentChildren?.length > 0 ? lastParentChildren[lastParentChildren?.length - 1].id : lastParent?.id
        } else {
          // Move focus to the previous parent row
          const previousParent = swapOptionFilteredRows[parentRowIndex - 1]
          const previousParentChildren = previousParent?.children || []
          previousRowId = previousParentChildren?.length > 0 ? previousParentChildren[previousParentChildren?.length - 1].id : previousParent?.id
        }
      } else {
        if (isFirstChild) {
          // If first child, move focus to the parent
          previousRowId = parentRow.id
        } else if (currentChildIndex !== undefined && currentChildIndex !== -1) {
          // Move focus to the previous sibling of the child
          previousRowId = parentRow?.children[currentChildIndex - 1]?.id
        }
      }
      if (previousRowId && accountRefs.current[previousRowId]) {
        setTimeout(() => {
          accountRefs.current[previousRowId].focus()
        }, 0)
      }
    }
  }

  // Function to close the taxlot swapping modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Function to open the taxlot swapping modal and handle onClick of submit
  const handleOpenModal = () => {
    setIsSubmitButtonClicked(true)
    setOpenModal(true)
    handleFilterRows()
  }

  // Function to open the success modal
  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true)
  }

  // Function to close the success modal
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
    onConfirm()
  }

  useEffect(() => {
    if (aggregateAccountData) {
      const updatedRows = aggregateAccountData?.map(item => ({
        ...item,
        id: randomId(),
        children: []
      }))
      setRows(updatedRows)
    }
  }, [aggregateAccountData])

  const getRowId = (row) => row?.id

  // format payload for the POST taxlot swap API
  const createTaxlotSwapPayload = (rows) => {
    return rows?.map(row => {
      const swapOutInfo = {
        accountId: row?.accountId,
        instrId: row?.instrId,
        sharesSwapped: row?.quantity,
        swapCode: 'SWAP_OUT'
      }

      const swapInInfo = {
        accountId: row?.targetAccount?.accountId,
        instrId: row?.instrId,
        sharesSwapped: row?.quantity,
        swapCode: 'SWAP_IN'
      }

      return {
        aggTaxlotId: row?.aggTaxlotId,
        isContribution: row?.isContribution ? 1 : 0,
        comment: row?.comment || '',
        swappingInfo: [swapOutInfo, swapInInfo],
        lastClosePrice: row?.lastClosePrice
      }
    })
  }

  // POST API for taxlot swapping
  const submitTaxlotSwap = () => {
    const apiBody = createTaxlotSwapPayload(filteredRows)
    setPostTaxlotSwapLoading(true)
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user?.userGroup}/taxlot/swapping/${params?.familyId}`,
      { body: apiBody }
    )
      .then((response) => {
        setPostTaxlotSwapResponse(response)
        handleOpenSuccessModal()
        handleCloseModal()
        setPostTaxlotSwapLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to create taxlot swap.')
        setPostTaxlotSwapLoading(false)
      }).finally(() => {
      })
  }

  // Function to open the comment modal
  const handleOpenCommentModal = (rowId) => {
    const selectedRow = rows.find(row => row.id === rowId || row.children?.some(child => child.id === rowId))
    const comment = selectedRow?.id === rowId
      ? selectedRow?.comment
      : selectedRow?.children?.find(child => child.id === rowId)?.comment
    setTargetRowId(rowId)
    setInputValue(comment || '') // Set input value to the existing comment or an empty string if no comment exists
    setOpen(true)
  }

  // Function to handle input change of comment text field
  const handleCommentInputChange = (event) => {
    setInputValue(event.target.value)
  }

  // Function to handle submit of the comment modal
  const handleSubmitComment = () => {
    setRows(prevRows => prevRows?.map(row => {
      if (row?.id === targetRowId) {
        return { ...row, comment: inputValue }
      }
      if (row?.children && row?.children?.some(child => child.id === targetRowId)) {
        return {
          ...row,
          children: row?.children?.map(child => child.id === targetRowId ? { ...child, comment: inputValue } : child)
        }
      }
      return row
    }))
    handleCloseCommentModal()
  }

  // Function to close the comment modal
  const handleCloseCommentModal = () => {
    setOpen(false)
  }

  // Function to handle the account autocomplete change
  const handleAccountChange = (rowId, newValue) => {
    setRows(prevRows => prevRows?.map(row => {
      if (row?.id === rowId) {
        return {
          ...row,
          targetAccount: newValue,
          isAccountEmpty: !newValue || newValue === null || typeof newValue === 'undefined'
        }
      }
      if (row?.children && row?.children?.some(child => child.id === rowId)) {
        return {
          ...row,
          children: row?.children?.map(child => child.id === rowId
            ? {
                ...child,
                targetAccount: newValue,
                isAccountEmpty: !newValue || newValue === null || typeof newValue === 'undefined'
              }
            : child)
        }
      }
      return row
    }))
  }

  // Function to handle the adding of new child row
  const handleAddRow = (rowId) => {
    setIsSubmitButtonClicked(false)
    const parentRow = rows.find(row => row.id === rowId || (row?.children && row?.children?.some(child => child.id === rowId)))
    if (parentRow) {
      const maxShares = parseFloat(parentRow?.shares) || 0
      const parentQuantity = parseFloat(parentRow?.quantity) || 0
      // Flatten rows and combine all decimal places into an array
      const decimalPlacesArray = [parentRow, ...parentRow?.children]?.flatMap(row => {
        const decimalPlaces = []
        // calculate decimal places from quantity field
        if (typeof row?.quantity === 'number' && !isNaN(row?.quantity)) {
          const quantityPlaces = row?.quantity?.toString()?.split('.')[1]
          if (quantityPlaces) {
            decimalPlaces.push(quantityPlaces?.length)
          }
        }
        // calculate decimal places from shares field
        if (typeof row?.shares === 'number' && !isNaN(row?.shares)) {
          const sharesPlaces = row?.shares?.toString()?.split('.')[1]
          if (sharesPlaces) {
            decimalPlaces.push(sharesPlaces?.length)
          }
        }
        return decimalPlaces
      })
      // Calculate the maximum decimal places
      const maxDecimalPlaces = Math.max(...decimalPlacesArray, 0)
      // Calculate total quantity including parent and existing children
      let totalQuantity = parentQuantity
      if (parentRow?.children) {
        totalQuantity += parentRow?.children?.reduce((sum, child) => sum + (parseFloat(child?.quantity) || 0), 0)
      }
      // Calculate available quantity for the new child row
      let availableQuantity = maxShares - totalQuantity
      // Round availableQuantity to the maximum decimal places found
      availableQuantity = parseFloat(availableQuantity.toFixed(maxDecimalPlaces))
      // Create new child row with calculated quantity
      const newChildRow = {
        ...parentRow,
        id: randomId(),
        parentId: parentRow.id,
        quantity: availableQuantity || 0,
        targetAccount: null,
        showSwapOptions: true,
        isQuantityEmpty: true,
        isAccountEmpty: true,
        contribution: true
      }
      if (!parentRow.shares || parentRow.shares === 0 || typeof parentRow.shares === 'undefined') {
        parentRow.isQuantityEmpty = true
      }
      if (!parentRow.targetAccount || parentRow.targetAccount === null || typeof parentRow.targetAccount === 'undefined') {
        parentRow.isAccountEmpty = true
      }
      parentRow.children = parentRow.children ? [...parentRow.children, newChildRow] : [newChildRow]
      setRows([...rows])
    }
  }

  // Function to handle the onClick of the swap icon and quantity and account validations
  const handleSwapIconClick = (rowId) => {
    setIsSubmitButtonClicked(false) // Reset the submit button click state
    setRows((prevRows) => {
      const newShowSwapOptions = { ...showSwapOptions } // Create a copy of the current showSwapOptions state
      const updatedRows = prevRows?.map((row) => {
        if (row?.id === rowId) {
          const showSwapOptionsNewValue = !row?.showSwapOptions
          // If showSwapOptions is being set to false, process row removal or value clearing
          if (!showSwapOptionsNewValue) {
            newShowSwapOptions[rowId] = showSwapOptionsNewValue
            if (row?.children && row?.children?.length > 0) {
              // Remove all children and clear parent row values
              return {
                ...row,
                targetAccount: null,
                quantity: null,
                showSwapOptions: showSwapOptionsNewValue,
                isQuantityEmpty: false,
                isAccountEmpty: false,
                contribution: true,
                quantityError: false,
                children: [],
                comment: ''
              }
            } else {
              // No children rows, clear parent row values
              return {
                ...row,
                targetAccount: null,
                quantity: null,
                showSwapOptions: showSwapOptionsNewValue,
                isQuantityEmpty: false,
                isAccountEmpty: false,
                contribution: true,
                quantityError: false,
                comment: ''
              }
            }
          }

          // Toggle showSwapOptions for the current row
          newShowSwapOptions[rowId] = showSwapOptionsNewValue
          return {
            ...row,
            showSwapOptions: showSwapOptionsNewValue,
            isQuantityEmpty: !row?.quantity || row?.quantity === 0 || typeof row?.quantity === 'undefined',
            isAccountEmpty: !row?.targetAccount || row?.targetAccount === null || typeof row?.targetAccount === 'undefined',
            contribution: true // Set contribution to true for the row
          }
        } else if (row?.children) {
          // If the row has children, map through them to find the child with the matching id
          const updatedChildren = row?.children?.map((child) => {
            if (child?.id === rowId) {
              const showSwapOptionsNewValue = !child?.showSwapOptions
              // If showSwapOptions is being set to false, clear child values
              if (!showSwapOptionsNewValue) {
                newShowSwapOptions[child.id] = showSwapOptionsNewValue
                return {
                  ...child,
                  targetAccount: null,
                  quantity: null,
                  showSwapOptions: showSwapOptionsNewValue,
                  isQuantityEmpty: true,
                  isAccountEmpty: true,
                  contribution: true,
                  quantityError: false
                }
              }
              // Toggle showSwapOptions for the specific child
              newShowSwapOptions[child.id] = showSwapOptionsNewValue
              return {
                ...child,
                showSwapOptions: showSwapOptionsNewValue,
                isQuantityEmpty: !child?.quantity || child?.quantity === 0 || typeof child?.quantity === 'undefined',
                isAccountEmpty: !child?.targetAccount || child?.targetAccount === null || typeof child?.targetAccount === 'undefined',
                contribution: true // Set contribution to true for the child
              }
            }
            return child
          })
          return {
            ...row,
            children: updatedChildren
          }
        }
        return row
      })
      // Update the showSwapOptions state
      setShowSwapOptions(newShowSwapOptions)
      return updatedRows
    })
  }

  // Function to handle the deletion of the child rows and clearing the values for parent row
  const handleDeleteRow = (rowId) => {
    setRows((prevRows) => {
      const updatedRows = prevRows?.map((row) => {
        if (row?.id === rowId) {
          if (row?.children && row?.children?.length > 0) {
            const firstChildWithData = row?.children[0] // Get the first child
            const remainingChildren = row?.children?.filter((child) => child.id !== firstChildWithData.id)
            // Calculate new quantity error for the new parent row
            const newChildrenQuantitySum = remainingChildren?.reduce(
              (sum, child) => sum + (parseFloat(child?.quantity) || 0),
              0
            )
            const maxDecimalPlaces = Math.max(
              ...[row?.shares, firstChildWithData?.quantity, newChildrenQuantitySum]?.flatMap((value) => {
                if (!isNaN(value) && value?.toString()?.includes('.')) {
                  return value?.toString()?.split('.')[1]?.length
                }
                return 0
              })
            )
            const totalQuantity = parseFloat(firstChildWithData?.quantity) + newChildrenQuantitySum
            const totalQuantityFixed = totalQuantity?.toFixed(maxDecimalPlaces)
            const shares = parseFloat(row?.shares?.toFixed(maxDecimalPlaces))?.toFixed(maxDecimalPlaces)
            const quantityError = parseFloat(totalQuantityFixed) > parseFloat(shares)
            return {
              ...row,
              targetAccount: firstChildWithData.targetAccount || null,
              quantity: firstChildWithData.quantity || null,
              contribution: firstChildWithData.contribution || true,
              isQuantityEmpty: !firstChildWithData.quantity,
              isAccountEmpty: !firstChildWithData.targetAccount,
              children: remainingChildren,
              comment: firstChildWithData.comment || '',
              quantityError
            }
          } else {
            // No children, clear parent row values
            return {
              ...row,
              targetAccount: null,
              quantity: null,
              contribution: false,
              isQuantityEmpty: false,
              isAccountEmpty: false,
              quantityError: false,
              showSwapOptions: false,
              comment: ''
            }
          }
        }
        if (row?.children && row?.children?.length > 0) {
          // Filter out the child with matching id and recalculate quantities
          const updatedChildren = row?.children?.filter((child) => child.id !== rowId)
          const newChildrenQuantitySum = updatedChildren?.reduce(
            (sum, child) => sum + (parseFloat(child?.quantity) || 0),
            0
          )
          // Determine the maximum number of decimal places among row.shares, row.quantity, and newChildrenQuantitySum
          const maxDecimalPlaces = Math.max(
            ...[row?.shares, row?.quantity, newChildrenQuantitySum]?.flatMap((value) => {
              if (!isNaN(value) && value?.toString()?.includes('.')) {
                return value?.toString()?.split('.')[1]?.length
              }
              return 0
            })
          )
          // Calculate totalQuantity correctly
          const totalQuantity = parseFloat(row?.quantity) + newChildrenQuantitySum
          const totalQuantityFixed = totalQuantity?.toFixed(maxDecimalPlaces)
          // Convert shares to string with fixed maxDecimalPlaces for accurate summation
          const shares = parseFloat(row?.shares?.toFixed(maxDecimalPlaces))?.toFixed(maxDecimalPlaces)
          // Determine if the total quantity exceeds the shares
          const quantityError = parseFloat(totalQuantityFixed) > parseFloat(shares)
          return {
            ...row,
            quantityError,
            children: updatedChildren?.map((child) => ({
              ...child,
              parentQuantityError: quantityError
            }))
          }
        }
        return row
      })
      // Recalculate the quantity errors for the updated rows
      const finalUpdatedRows = updatedRows?.map((row) => {
        if (row?.children && row?.children?.length > 0) {
          const newChildrenQuantitySum = row?.children?.reduce(
            (sum, child) => sum + (parseFloat(child?.quantity) || 0),
            0
          )
          // Determine the maximum number of decimal places among row.shares, row.quantity, and newChildrenQuantitySum
          const maxDecimalPlaces = Math.max(
            ...[row?.shares, row?.quantity, newChildrenQuantitySum]?.flatMap((value) => {
              if (!isNaN(value) && value?.toString()?.includes('.')) {
                return value?.toString()?.split('.')[1]?.length
              }
              return 0
            })
          )
          // Calculate totalQuantity correctly
          const totalQuantity = parseFloat(row?.quantity) + newChildrenQuantitySum
          const totalQuantityFixed = totalQuantity?.toFixed(maxDecimalPlaces)
          // Convert shares to string with fixed maxDecimalPlaces for accurate summation
          const shares = parseFloat(row?.shares?.toFixed(maxDecimalPlaces))?.toFixed(maxDecimalPlaces)
          // Determine if the total quantity exceeds the shares
          const quantityError = parseFloat(totalQuantityFixed) > parseFloat(shares)
          return {
            ...row,
            quantityError,
            children: row?.children?.map((child) => ({
              ...child,
              parentQuantityError: quantityError
            }))
          }
        }
        return row
      })
      updateShowSwapOptions(finalUpdatedRows) // Recalculate showSwapOptions from rows
      return finalUpdatedRows
    })
  }

  // Function to recalculate showSwapOptions from rows and update the state
  const updateShowSwapOptions = (rows) => {
    const newShowSwapOptions = {}
    rows?.forEach(row => {
      newShowSwapOptions[row.id] = row.showSwapOptions
      row.children?.forEach(child => {
        newShowSwapOptions[child.id] = child.showSwapOptions
      })
    })
    setShowSwapOptions(newShowSwapOptions)
  }

  // Function to handle the Quantity change
  const handleQuantityChange = (e, rowId, isChild) => {
    const newValue = e.target.value.toString().trim() // Convert to string and then trim
    const newValueNumber = newValue === '' ? '' : (parseFloat(newValue) || 0)
    setRows(prevRows =>
      prevRows.map(row => {
        if (row?.id === rowId && !isChild) {
          // Update parent row's quantity
          const newParentQuantity = newValueNumber
          const newChildrenQuantitySum = row?.children?.reduce((sum, child) => sum + (parseFloat(child.quantity) || 0), 0)
          const quantityError = (newParentQuantity + newChildrenQuantitySum) > row?.shares
          // Update each child with the new parent's quantityError
          const updatedChildren = row?.children?.map(child => ({
            ...child,
            parentQuantityError: quantityError,
            quantityError: (parseFloat(child.quantity) || 0) + newParentQuantity > row?.shares
          }))
          return {
            ...row,
            quantity: newParentQuantity,
            quantityError,
            isQuantityEmpty: newValue.trim() === '' || newValueNumber === 0, // Update parent's isQuantityEmpty
            children: updatedChildren
          }
        } else if (row?.children) {
          // Update child row's quantity
          const updatedChildren = row?.children?.map(child => {
            if (child.id === rowId && isChild) {
              const newChildQuantity = newValueNumber
              const newChildrenQuantitySum = row?.children?.reduce((sum, sibling) =>
                sibling.id !== rowId ? sum + (parseFloat(sibling.quantity) || 0) : sum,
              newChildQuantity
              )
              const quantityError = (parseFloat(row?.quantity) || 0) + newChildrenQuantitySum > row?.shares
              return {
                ...child,
                quantity: newChildQuantity,
                quantityError,
                isQuantityEmpty: newValue.trim() === '' || newValueNumber === 0,
                parentQuantityError: quantityError
              }
            }
            return child
          })
          const newChildrenQuantitySum = updatedChildren?.reduce((sum, child) => sum + (parseFloat(child?.quantity) || 0), 0)
          const quantityError = (parseFloat(row?.quantity) || 0) + newChildrenQuantitySum > row?.shares
          return {
            ...row,
            quantityError,
            children: updatedChildren?.map(child => ({
              ...child,
              parentQuantityError: quantityError
            }))
          }
        }
        return row
      })
    )
  }

  useEffect(() => {
    onMarketValueChange(rows)
  }, [rows])

  // Function to handle the contribution checkbox change
  const handleContributionChange = (rowId, checked) => {
    setRows(prevRows => prevRows?.map(row => {
      if (row?.id === rowId) {
        return { ...row, contribution: checked }
      }
      if (row?.children) {
        return {
          ...row,
          children: row?.children?.map(child => child.id === rowId ? { ...child, contribution: checked } : child)
        }
      }
      return row
    }))
  }

  const hasQuantityError = rows?.some(row => row?.quantityError || row?.children?.some(child => child?.quantityError))
  const hasNegativeQuantity = rows?.some(row => row?.quantity < 0 || row?.children?.some(child => child?.quantity < 0))

  const aggregateDataColumns = [
    showAccountName
      ? {
          field: 'accountName',
          headerName: 'Account Name',
          width: 200,
          renderHeader: () => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Account Name</span>
              <IconButton onClick={handleHeaderSwapIconClick} sx={{ padding: 0 }}>
                <SwapHorizIcon sx={{ fontSize: '16px', marginLeft: '4px' }} />
              </IconButton>
            </Box>
          ),
          valueGetter: (params) => params?.row?.accountName,
          renderCell: (params) => params?.row?.parentId ? '' : params?.row?.accountName // hide the value for child rows
        }
      : {
          field: 'accountCd',
          headerName: 'Account Code',
          width: 200,
          renderHeader: () => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Account Code</span>
              <IconButton onClick={handleHeaderSwapIconClick} sx={{ padding: 0 }}>
                <SwapHorizIcon sx={{ fontSize: '16px', marginLeft: '4px' }} />
              </IconButton>
            </Box>
          ),
          valueGetter: (params) => params?.row?.accountCd,
          renderCell: (params) => params?.row?.parentId ? '' : params?.value // hide the value for child rows
        },
    {
      field: 'instrumentName',
      headerName: 'Instrument Name',
      valueGetter: (params) => params?.row?.instrumentName,
      renderCell: (params) => (
        !params.row.parentId
          ? <Tooltip title={`${params?.row?.localSymbol} - ${params?.row?.instrumentName}`}>
            <span>{params?.value}</span>
          </Tooltip>
          : ''

      ),
      flex: 1
    },
    {
      field: 'instrId',
      headerName: 'Instr Id',
      valueGetter: (params) => params?.row?.instrId,
      renderCell: (params) => (
        !params?.row?.parentId
          ? <Tooltip title={`${params?.row?.localSymbol} - ${params?.row?.instrumentName}`}>
            <span>{params?.value}</span>
          </Tooltip>
          : ''

      ),
      flex: 1
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1,
      valueGetter: (params) => params?.row?.localSymbol,
      renderCell: (params) => params?.row?.parentId ? '' : params?.value // hide the value for child rows
    },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if(!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.parentId ? '' : (params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : '') // hide the value for child rows
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.orgPurchasePrice
        return value == null || isNaN(value) ? null : parseFloat(value)
      },
      renderCell: (params) => !isNaN(params?.row?.orgPurchasePrice) && !params?.row?.parentId
        ? params?.row?.orgPurchasePrice < 0
          ? `-$${Math.abs(params?.row?.orgPurchasePrice)}`
          : `$${params?.row?.orgPurchasePrice}`
        : ''
    },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.shares ?? ''
      },
      renderCell: (params) => {
        return params?.row?.parentId
          ? ''
          : (params?.value != null ? params?.value?.toString() : '')
      }
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Market Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.parentId ? '' : params?.row?.lastClosePrice
        return value == null || isNaN(value) ? 0.00 : parseFloat(parseFloat(value).toFixed(2))
      },
      renderCell: (params) => !isNaN(params?.row?.lastClosePrice) && !params?.row?.parentId
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(params?.row?.lastClosePrice, 2)}`
          : `$${formatCurrency(params?.row?.lastClosePrice, 2)}`
        : ''
    },
    {
      field: 'swapIcon',
      headerName: '',
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        !params.row?.parentId &&
          <IconButton sx={{ color: params?.row?.showSwapOptions ? '#3A76D4' : 'inherit' }} onClick={() => handleSwapIconClick(params?.row?.id)}>
            <SwapHorizIcon sx={{ fontSize: '28px' }} />
          </IconButton>
      ),
      valueGetter: (params) => params?.row?.showSwapOptions
    },
    {
      field: 'contribution',
      headerName: Object.values(showSwapOptions).some(value => value === true) ? 'Contribution' : '',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          params?.row?.showSwapOptions
            ? (
              <Checkbox
                checked={params?.row?.contribution !== undefined ? params?.row?.contribution : true} // Default to true
                onChange={(event) => handleContributionChange(params?.row?.id, event.target.checked)}
              />
              )
            : null
        )
      }
    },
    {
      field: 'quantity',
      headerName: showSwapOptions && Object.values(showSwapOptions).some(value => value === true) ? 'Quantity' : '',
      type: 'number',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const isChild = !!params?.row?.parentId
        const quantityValue = params?.row?.quantity ?? params?.row?.shares ?? ''
        params.row.quantity = quantityValue
        params.row.isQuantityEmpty = false
        return (
          params?.row?.showSwapOptions
            ? <TextField
                variant='outlined'
                size='small'
                type='number'
                inputProps={{ min: 0, step: 0.01 }}
                value={quantityValue}
                onChange={(e) => {
                  const value = e.target.value
                  if (value === '' || parseFloat(value) >= 0) {
                    handleQuantityChange(e, params?.row?.id, isChild)
                  }
                }}
                onKeyDown={(e) => {
                  handleKeyDownQuantity(e, params?.row?.id, isChild)
                  // check if Ctrl + A is pressed select all the value
                  if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
                    e.preventDefault()
                    e.target.select() // Manually select all text
                  }
                }}
                inputRef={(el) => (quantityRefs.current[params?.row?.id] = el)}
                error={(params?.row?.quantityError || params?.row?.parentQuantityError) || (isSubmitButtonClicked && params?.row?.isQuantityEmpty)}
                onPaste={(event) => {
                // Function to handle paste event
                  const inputValue = event.clipboardData.getData('text').trim()
                  const regex = /^\d+(\.\d+)?$/ // Regex to allow only digits and decimal numbers
                  if (!regex.test(inputValue)) {
                    event.preventDefault() // Prevent the paste action if content isn't numeric
                  } else {
                    const newValue = parseFloat(inputValue)
                    if (newValue >= 0) {
                      event.preventDefault()
                      handleQuantityChange({ target: { value: newValue } }, params?.row?.id, isChild)
                    } else {
                      event.preventDefault() // Prevent paste if the value is negative
                    }
                  }
                }}
                sx={{
                  width: '100%',
                  '.MuiOutlinedInput-input': {
                    height: '14px !important'
                  }
                }}
              />
            : null
        )
      }
    },
    {
      field: 'account',
      headerName: showSwapOptions && Object.values(showSwapOptions).some(value => value === true) ? 'Account' : '',
      width: 240,
      sortable: false,
      valueGetter: (params) => params.row?.targetAccount?.accountName || '',
      renderCell: (params) => {
        const isParent = !params?.row?.parentId // Check if the row is a parent row
        const isChild = !!params?.row?.parentId
        const hasChildren = params?.row?.children && params?.row?.children.length > 0 // Check if the row has children
        const parentRow = rows.find(row => row?.children?.some(child => child.id === params?.row?.id)) // Find the parent row if it exists
        const isLastChildOfParent = parentRow && parentRow?.children[parentRow?.children.length - 1].id === params?.row?.id // Check if the current row is the last child of its parent
        const isAccountEmpty = params?.row?.isAccountEmpty // Check if the account is empty for the current row
        const selectedAccountNames = new Set() // Create a set of selected account names for the parent row and its children

        if (parentRow) {
          // If parentRow exists, populate selectedAccountNames with account names from parentRow and its children
          if (parentRow?.accountName) selectedAccountNames.add(parentRow.accountName) // Add parent account name to selectedAccountNames
          if (parentRow?.targetAccount?.accountName) selectedAccountNames.add(parentRow.targetAccount.accountName) // Add parent's target account name to selectedAccountNames
          parentRow.children.forEach(child => {
            if (child.targetAccount?.accountName) selectedAccountNames.add(child.targetAccount.accountName) // Add each child's target account name to selectedAccountNames
          })
        } else {
          // If parentRow does not exist, populate selectedAccountNames with account names from params.row and its children
          if (params?.row?.accountName) selectedAccountNames.add(params.row.accountName) // Add account name from params.row to selectedAccountNames
          if (params?.row?.targetAccount?.accountName) selectedAccountNames.add(params.row.targetAccount.accountName) // Add target account name from params.row to selectedAccountNames
          params?.row?.children?.forEach(child => {
            if (child?.targetAccount?.accountName) selectedAccountNames.add(child?.targetAccount?.accountName) // Add each child's target account name to selectedAccountNames
          })
        }
        // Function to check if any row or its child rows have a quantity greater than shares
        const hasQuantityExceedingShares = (row) => {
          const totalQuantity = row?.quantity + (row?.children ? row?.children?.reduce((acc, child) => acc + child?.quantity, 0) : 0)
          return totalQuantity > row?.shares
        }
        // Check if the current row or its parent has quantity exceeding shares
        const isQuantityExceedingShares = hasQuantityExceedingShares(params?.row) || (parentRow && hasQuantityExceedingShares(parentRow))
        return (
          params?.row?.showSwapOptions
            ? <>
              <Autocomplete
                id='account-autocomplete'
                options={accountsList}
                loading={accountsLoading}
                getOptionLabel={(option) => !showAccountName ? option?.accountCd || '' : option?.accountName || ''}
                onChange={(event, newValue) => handleAccountChange(params?.row?.id, newValue)}
                value={params?.row?.targetAccount || null}
                sx={{ width: '100%' }}
                getOptionDisabled={(option) => selectedAccountNames.has(option?.accountName)}
                renderInput={(param) => (
                  <TextField
                    {...param}
                    inputRef={(el) => (accountRefs.current[params?.row?.id] = el)}
                    onKeyDown={(e) => {
                      handleKeyDownAccount(e, params?.row?.id, isChild)
                      if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
                        e.preventDefault()
                        e.target.select() // Manually select all text
                      }
                    }}
                    variant='outlined'
                    size='small'
                    error={isSubmitButtonClicked && isAccountEmpty}
                    sx={{
                      width: '100%',
                      '.MuiOutlinedInput-input': {
                        height: '14px !important'
                      }
                    }}
                    onPaste={(event) => {
                      const pastedValue = event.clipboardData.getData('text').trim() // Get pasted value and trim whitespace
                      if (pastedValue) {
                        // Find matching option in accountsList
                        const matchingOption = accountsList.find(option =>
                          (option.accountCd === pastedValue || option.accountName === pastedValue) &&
                          !selectedAccountNames.has(option.accountName)
                        )
                        if (matchingOption) {
                          // If a matching option is found
                          handleAccountChange(params?.row?.id, matchingOption) // Update selected account
                          event.preventDefault()
                          setTimeout(() => {
                            const input = event.target
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
                            nativeInputValueSetter.call(input, matchingOption[!showAccountName ? 'accountCd' : 'accountName']) // Set input value
                            const ev2 = new Event('input', { bubbles: true })
                            input.dispatchEvent(ev2)
                          }, 0)
                        } else {
                          // If no matching option or if it is disabled, allow pasting but do not select
                          setTimeout(() => {
                            const input = event.target
                            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
                            nativeInputValueSetter.call(input, pastedValue) // Set input value
                            const ev2 = new Event('input', { bubbles: true })
                            input.dispatchEvent(ev2)
                          }, 0)
                        }
                      }
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  const isDisabled = selectedAccountNames.has(option?.accountName)
                  return (
                    <li {...props} disabled={isDisabled}>
                      {!showAccountName ? option?.accountCd : option?.accountName}
                    </li>
                  )
                }}
              />
              {((isParent && !hasChildren) || isLastChildOfParent)
                ? (
                  <IconButton
                    onClick={() => handleAddRow(params?.row?.id)}
                    disabled={isQuantityExceedingShares}
                  >
                    <AddIcon />
                  </IconButton>
                  )
                : (
                  <Box width='48px' />
                  )}
            </>
            : null
        )
      }
    },
    {
      field: 'actions',
      headerName: showSwapOptions && Object.values(showSwapOptions).some(value => value === true) ? 'Actions' : '',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          params?.row?.showSwapOptions
            ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleOpenCommentModal(params?.row?.id)}>
                  <ChatBubbleOutlineIcon sx={{ color: '#34475A' }} />
                </IconButton>
                <IconButton onClick={() => handleDeleteRow(params?.row?.id, params?.row)}>
                  <DeleteIcon sx={{ color: '#34475A' }} />
                </IconButton>
              </Box>
              )
            : null
        )
      }
    }
  ]

  const visibleHeaders = aggregateDataColumns.filter(header => {
    return !['instrumentName', 'localSymbol'].includes(header.field)
  })

  const tableHeaderHTML = visibleHeaders.map(({ headerName }, index) => <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>{headerName}</TableCell>)
  const rowSkeleton = visibleHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  // Function to format the selected rows
  const handleFilterRows = () => {
    const filteredRows = rows?.reduce((acc, row) => {
      if (row?.quantity && row?.targetAccount) {
        acc.push({
          id: randomId(),
          aggTaxlotId: row?.aggTaxlotId,
          accountId: row?.accountId,
          accountCd: row?.accountCd,
          targetAccount: row?.targetAccount,
          accountName: row?.accountName,
          instrId: row?.instrId,
          localSymbol: row?.localSymbol,
          purchaseDate: row?.purchaseDate,
          instrumentName: row?.instrumentName,
          quantity: row?.quantity,
          orgPurchasePrice: row?.orgPurchasePrice,
          isContribution: row?.contribution || false,
          comment: row?.comment,
          shares: row?.shares,
          lastClosePrice: row?.lastClosePrice
        })
      }
      if (row?.children) {
        row?.children?.forEach(child => {
          if (child?.quantity && child?.targetAccount) {
            acc.push({
              id: randomId(),
              accountId: row?.accountId,
              aggTaxlotId: row?.aggTaxlotId,
              accountCd: row?.accountCd,
              targetAccount: child.targetAccount,
              quantity: child.quantity,
              accountName: row?.accountName,
              instrId: row?.instrId,
              localSymbol: row?.localSymbol,
              purchaseDate: row?.purchaseDate,
              instrumentName: row?.instrumentName,
              orgPurchasePrice: row?.orgPurchasePrice,
              isContribution: child?.contribution || false,
              comment: child?.comment,
              shares: row?.shares,
              lastClosePrice: row?.lastClosePrice
            })
          }
        })
      }
      return acc
    }, [])
    setFilteredRows(filteredRows)
  }

  // columns for the submit modal table
  const swappedColumns = [
    { field: 'instrId', headerName: 'Instr ID', width: 120 },
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : 'N/A'
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.orgPurchasePrice
        return value == null || isNaN(value)
          ? 'N/A'
          : parseFloat(parseFloat(value).toFixed(2))
      },
      renderCell: (params) => {
        if (!isNaN(params?.row?.orgPurchasePrice)) {
          const formattedValue = parseFloat(parseFloat(params?.row?.orgPurchasePrice).toFixed(2))
          return formattedValue < 0
            ? `-$${formatCurrency(Math.abs(formattedValue), 2)}`
            : `$${formatCurrency(formattedValue, 2)}`
        }
        return ''
      }
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Close Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.lastClosePrice || 0
        return value == null || isNaN(value)
          ? 'N/A'
          : parseFloat(parseFloat(value).toFixed(2))
      },
      renderCell: (params) => {
        if (params?.row?.lastClosePrice != null || !isNaN(params?.row?.lastClosePrice)) {
          const formattedValue = parseFloat(parseFloat(params?.row?.lastClosePrice).toFixed(2))
          return formattedValue < 0
            ? `-$${formatCurrency(Math.abs(formattedValue), 2)}`
            : `$${formatCurrency(formattedValue, 2)}`
        }
        return ''
      }
    },
    {
      field: 'shares',
      headerName: 'Shares',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.shares ?? ''
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : 'N/A' // Convert the value to string to ensure all decimal points are shown
      }
    },
    {
      field: 'contribution',
      headerName: 'Contribution',
      flex: 1,
      renderCell: (params) => params.row?.isContribution ? 'Yes' : 'No'
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.quantity ?? ''
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : 'N/A' // Convert the value to string to ensure all decimal points are shown
      }
    },
    {
      field: 'accountName',
      headerName: 'Swap Out',
      flex: 1.5,
      valueGetter: (params) => {
        if (showAccountName) {
          return params?.row?.accountName || ''
        } else {
          return params?.row?.accountCd || ''
        }
      },
      renderCell: (params) => (
        <Box sx={{ color: '#F46A6A' }}>{params?.value}</Box>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Swap Out</span>
          <IconButton sx={{ padding: 0 }}>
            <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '4px', color: '#F46A6A' }} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'targetAccount',
      headerName: 'Swap In',
      flex: 1.5,
      valueGetter: (params) => {
        if (showAccountName) {
          return params?.row?.targetAccount?.accountName || ''
        } else {
          return params?.row?.targetAccount?.accountCd || ''
        }
      },
      renderCell: (params) => (
        <Box sx={{ color: '#34C38F' }}>{params?.value}</Box>
      ),
      renderHeader: () => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#74788d', fontWeight: 600, fontSize: '14px' }}>Swap In</span>
          <IconButton sx={{ padding: 0 }}>
            <ArrowBackIcon sx={{ fontSize: '20px', marginLeft: '4px', color: '#34C38F' }} />
          </IconButton>
        </Box>
      )
    },
    {
      field: 'comment',
      headerName: 'Comments',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <HtmlTooltip tooltipStyle={{ marginRight: '10px' }}
            title={
              <Typography sx={{ fontSize: '14px' }} my={1}>{params?.row?.comment || 'N/A'}</Typography>
            }
          >
            <TryOutlined />
          </HtmlTooltip>
        )
      }
    }
  ]

  // Show success modal and redirect to history tab
  useEffect(() => {
    let timer
    if (openSuccessModal) {
      timer = setTimeout(() => {
        handleCloseSuccessModal()
      }, 3000)
    }
    // Clear the timeout if the modal is closed before the 3 seconds
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [openSuccessModal, handleCloseSuccessModal])

  const hasAnySwapOptionsEnabled = rows?.some(row =>
    row?.showSwapOptions || row?.children?.some(child => child?.showSwapOptions)
  )

  // Function to save the current state of the DataGridPro
  const handleTaxlotTableStateChange = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      // Extract the current orderedFields
      const { orderedFields } = state?.columns
      // accountName or accountCd is the first field
      const firstField = !showAccountName ? 'accountName' : 'accountCd'
      // firstField is at the beginning of orderedFields
      const newOrderedFields = [
        firstField,
        ...orderedFields?.filter(field => field !== firstField)
      ]
      state.columns.orderedFields = newOrderedFields
      setTaxlotSwapGridState(state)
    }
  }

  // Effect to restore the state whenever taxlotSwapGridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && taxlotSwapGridState) {
      const resetGridStateObj = {
        columns: taxlotSwapGridState?.columns,
        pinnedColumns: taxlotSwapGridState?.pinnedColumns,
        sorting: taxlotSwapGridState?.sorting
      }
      // if (filterModel) {
      // resetGridStateObj.filterModel = filterModel
      // }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [taxlotSwapGridState, showSwapOptions, open, inputValue, rows, openModal, openSuccessModal, filteredRows, postTaxlotSwapResponse, showAccountName, targetRowId, isSubmitButtonClicked, postTaxlotSwapLoading, postTaxlotSwapResponse, openMarketValueChangeModal])

  const handleHeaderSwapIconClick = () => {
    setShowAccountName((prevShowAccountName) => !prevShowAccountName)
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', position: 'absolute', right: '32px', top: '100px' }}>
        <Button
          type='submit'
          disabled={
            hasQuantityError ||
            !hasAnySwapOptionsEnabled ||
            rows?.some(row =>
              row?.isQuantityEmpty ||
              row?.isAccountEmpty ||
              parseFloat(row?.quantity) === 0 ||
              (row?.children?.some(child => child.isQuantityEmpty || child.isAccountEmpty || parseFloat(child.quantity) === 0))
            )
          }
          variant='contained'
          onClick={handleOpenModal}
        >
          Submit
        </Button>

      </Box>
      {(hasQuantityError || hasNegativeQuantity) && (
        <Chip
          variant='outlined'
          sx={{
            backgroundColor: '#F2DEDF',
            color: '#A77073',
            borderRadius: '5px',
            mb: 2,
            border: '1px solid #DFA4A7',
            fontSize: '12px',
            fontWeight: 600,
            position: 'absolute',
            right: '36px',
            top: '16px',
            zIndex: 100
          }}
          icon={<WarningAmberRoundedIcon sx={{ color: '#A77073 !important' }} />}
          label={hasNegativeQuantity ? 'One or more rows contain a negative quantity value' : 'Total Quantity exceeds from no. of Share in some of the row'}
        />
      )}
      <Card>
        <CardContent>
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              overflowY: 'auto',
              scrollbarWidth: 'none'
            }}
          >
            {aggregateDataLoading
              ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaderHTML}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>{rowSkeleton}</TableRow>
                    <TableRow>{rowSkeleton}</TableRow>
                  </TableBody>
                </Table>
                )
              : (
                  rows === undefined || rows?.length === 0
                    ? (
                      <>No Taxlot Aggregate data</>
                      )
                    : (
                      <DataGridPro
                        apiRef={apiRef}
                        density='compact'
                        autoHeight
                        rows={rows?.reduce((acc, row) => [...acc, row, ...row?.children], [])}
                        columns={aggregateDataColumns}
                        getRowId={(row) => row.id}
                        pagination
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              localSymbol: false,
                              instrumentName: false
                            }
                          },
                          ...rows?.initialState,
                          pagination: { paginationModel: { pageSize: 15 } }
                        }}
                        onColumnWidthChange={() => handleTaxlotTableStateChange()}
                        onColumnOrderChange={() => handleTaxlotTableStateChange()}
                        onSortModelChange={() => handleTaxlotTableStateChange()}
                        onColumnVisibilityModelChange={() => handleTaxlotTableStateChange()}
                        pageSizeOptions={[15, 25, 50, 75, 100]}
                        disableRowSelectionOnClick
                        disableMultipleRowSelection
                        sx={(theme) => ({
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          [`.${gridClasses.main}`]: {
                            overflow: 'unset'
                          },
                          [`.${gridClasses.columnHeaders}`]: {
                            position: 'sticky',
                            backgroundColor: theme.palette.background.paper,
                            top: 0,
                            zIndex: 1
                          },
                          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                            color: '#74788d',
                            fontWeight: 600
                          },
                          [`.${gridClasses.footerContainer}`]: {
                            position: 'sticky',
                            bottom: '-1px',
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1
                          },
                          [`.${gridClasses.virtualScroller}`]: {
                            '::-webkit-scrollbar': {
                              width: '0px !important'
                            },
                            overflowY: 'auto !important',
                            height: 'calc(100vh - 48px - 15px - 20px - 70px - 10px - 48px - 48px - 16px - 24px - 39px - 60px)'
                          }
                        })}
                      />
                      )
                )}
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleCloseCommentModal} maxWidth='sm' fullWidth>
        <DialogTitle sx={{ fontFamily: 'Open Sans', fontSize: '18px', color: '#002A59', fontWeight: 500 }}>Add a comment</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Input
              className='form-control'
              placeholder='Write your comment here'
              disableUnderline
              required
              sx={{
                paddingX: '10px',
                height: '120px',
                alignItems: 'flex-start'
              }}
              value={inputValue}
              onChange={handleCommentInputChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', px: 3 }}>
          <Button onClick={handleCloseCommentModal} m={1} sx={{ fontWeight: 600, fontSize: '12px' }}>Cancel</Button>
          <Button variant='contained' size='small' onClick={handleSubmitComment} sx={{ fontSize: '12px' }}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ ...popupStyle, height: '600px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, alignItems: 'center' }}>
            <Typography
              component='h1'
              sx={{
                color: '#212529',
                fontSize: '22px',
                fontWeight: '400'
              }}
            >
              Taxlot Swapping
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <DataGridPro
              autoHeight
              density='compact'
              rows={filteredRows}
              columns={swappedColumns}
              getRowId={getRowId}
              pagination
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    shares: false
                  }
                },
                ...rows?.initialState,
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 15, 25]}
              disableRowSelectionOnClick
              sx={(theme) => ({
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                [`.${gridClasses.main}`]: {
                  height: 'min(100vh - 16px - 16px - 35px - 16px - 40px - 52px, 600px - 16px - 16px - 35px - 16px - 40px - 52px)'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  backgroundColor: theme.palette.background.paper,
                  top: 0,
                  zIndex: 1
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'none'
                }
              })}
            />
          </Box>
          <Box sx={{ position: 'absolute', bottom: '16px', right: '32px' }}>
            {postTaxlotSwapLoading
              ? (
                <LoadingButton loading loadingPosition='start' startIcon={<SaveIcon />} variant='outlined'>
                  Next
                </LoadingButton>
                )
              : (
                <Button variant='contained' disabled={filteredRows?.length === 0} onClick={handleOpenMarketValueChangeModal}>
                  Next
                </Button>
                )}
          </Box>
        </Box>
      </Modal>

      <Modal open={openSuccessModal} onClose={handleCloseSuccessModal}>
        <Box sx={{
          ...popupStyle,
          width: '500px !important'
        }}
        >
          <Box align='center'>
            <img src={Success} alt='' height={100} width={100} />
            <Typography variant='h4' align='center' mb={2} sx={{ fontFamily: 'Open Sans', fontSize: '24px', fontWeight: '600' }}>
              {postTaxlotSwapResponse?.message}
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openMarketValueChangeModal} onClose={handleCloseMarketValueChangeModal} maxWidth='md' fullWidth
        sx={{
          '.MuiBackdrop-root': {
            opacity: '0 !important'
          }
        }}
      >
        <AccountMarketValueModal
          accountsList={accountsList}
          isMarketValueChange={rows}
          handleClose={handleCloseMarketValueChangeModal}
          showConfirmButton
          submitTaxlotSwap={submitTaxlotSwap}
          postTaxlotSwapLoading={postTaxlotSwapLoading}
        />
      </Modal>
    </>

  )
}

export default TaxlotSwappingTab
