import React, { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Box, Card, CardContent, Grid, Typography, Tooltip, IconButton, Skeleton } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { ErrorBoundary } from 'react-error-boundary'
import { IoIosExpand } from 'react-icons/io'
import { IoReload } from 'react-icons/io5'
import AccountTitle from '../../../components/AccountTitle'
import { useAuth } from '../../../contexts/AuthContext'
import { useAccountService } from '../../../hooks/useAccountService'
import { useErrorToast } from '../../../hooks/useErrorToast'
import ErrorFallback from '../../ErrorFallback'
import AccountAttributes from '../components/AccountAttributes'
import AccountRequests from './AccountRequests'
import AssetAllocation from '../components/AssetAllocation'
import { DashboardTableLoaders } from '../Dashboardloaders/Dashboardtableloader'
import { InternalNavigation } from '../components/NavigateFromDashboard'
import OverWeightedSecurity from './OverWeightedSecurity'
import PortfolioModel from './PortfolioModel'
import { RealativeVolatility } from './RelativeVolatility'
import TopSecurity from './TopSecurity'
import UnderWeightedSecurities from './UnderWeightedSecurities'
import MarketValueAreaChart from '../../Report/components/performanceComponents/MarketValueChart'
import AccountActivityTable from '../components/AccountActivityTable'
import { benchmarkDataProcess } from '../../Report/DataFormatting'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'

const AccountDashboard = () => {
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { getAccountAttributes, getStrategyAttributes } = useAccountService()
  const { SectorAndSecurityLoader, MajorAssetAllocationLoader, TopSecurityLoader, DashboardRequestLoader, ActivitySummaryLoader } = DashboardTableLoaders()
  const [accountinstrumentDrift, setAccountInstrumentDrift] = useState({})
  const [accountParameters, setAccountParameters] = useState({})
  // const [accountInstruments, setAccountInstruments] = useState({})
  const [strategyParameters, setStrategyParameters] = useState({})
  const [strategyInstruments, setStrategyInstruments] = useState([])
  const [cardData, setCardData] = useState({})
  const [assetAllocationData, setAssetAllocationData] = useState([])
  const [accountRequests, setAccountRequests] = useState([])
  const [riskAttributes, setRiskAttributes] = useState({})
  const [marketValueData, setMarketValueData] = useState([])
  const [marketChartData, setMarketChartData] = useState([])
  const [accountActivitySummaryData, setAccountActivitySummaryData] = useState([])
  // const [strategyAttributes, setStrategyAttributes] = useState([])
  const [overWeight, setOverWeight] = useState()
  const [underWeight, setUnderWeight] = useState()
  const [value, setValue] = useState()
  const [noData, setNodata] = useState()
  const params = useParams()
  const [sectorLoading, setSectorLoading] = useState(true)
  const [assetLoading, setAssetLoading] = useState(true)
  const [topPositionLoader, setTopPositionLoader] = useState(true)
  const [requestLoader, setRequestLoader] = useState(true)
  const [resetKey, setResetKey] = useState(0)
  const [isChartZoomed, setIsChartZoomed] = useState(false)
  const [isMVChartLoading, setIsMVChartLoading] = useState(true)
  const [benchmarkReturnsData, setBenchmarkReturnsData] = useState([])
  const [accountDetails, setAccountDetails] = useState({})

  const navigate = useNavigate()

  const dataSecure = localStorage.getItem('object')

  const getStoreData = dataSecure ? JSON.parse(dataSecure) : []

  const filterSecureData = getStoreData?.filter((item) => item?.accountId === params?.accountId)

  const strategyId = filterSecureData?.[0]?.strategyId ?? accountDetails?.strategyId ?? ''

  const routePath = useLocation()
  // add this scroll to top because when user come from book of business page
  // it will navigate to Account Dashboard this component is rendering but the window page location is same
  // so to avoid that add Auto scroll functionality
  const onTop = () => { window.scrollTo(0, 0) }

  useEffect(() => onTop(), [routePath])

  // const [clearCacheFlag, setClearCacheFlag] = useState(false)
  const resetChart = () => {
    setResetKey((prevKey) => prevKey + 1)
  }

  const handleZoomChange = (isZoomed) => {
    setIsChartZoomed(isZoomed)
  }

  const fetchAccountDetails = async () => {
    if (!filterSecureData?.length) {
      try {
        const response = await API.get(
          'baseUriTransactionalMaster',
          `transactional-master/v1/${user?.userGroup}/account-detail/${params?.accountId}`
        )
        if (response?.data) {
          setAccountDetails(response?.data)
        }
      } catch (error) {
        showError(error, false, {}, 'Failed to load account details.')
      }
    } else {
      setAccountDetails(filterSecureData[0])
    }
  }

  useEffect(() => {
    fetchAccountDetails()
  }, [user, params?.accountId])

  // API call functions that will return promise
  // const fetchAccountRequestData = () => {
  //   return API.get(
  //     'baseRequestURL',
  //     `request/v1/${user?.userGroup === 'spn-pm' ? 'adv-classic' : user?.userGroup}/requests/${params.accountId}`,
  //     { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
  //   )
  // }

  // useQuery hook to call all these APIs parallel
  // const [accountParametersQuery] = useQueries({
  //   queries: [
  //     {
  //       queryKey: ['account-parameter', params.accountId], // unique key for query caching
  //       queryFn: fetchAccountParametersData, // function that will return promise
  //       enabled: user !== null, // dependent condition
  //       retry: false, // prevent retry if it fails
  //       staleTime: 0,
  //       gcTime: 0
  //     },
  //   ]
  // })

  useEffect(() => {
    if (user) {
      getAccountInstrumentDriftDetails()
      getAccountParametersDetails()
      getAccountRequests()
      getAssetAllocationData()
      getRiskAttributes()
      getMetricData()
      getActivitySummary()
    }
  }, [user, params?.accountId])

  useEffect(() => {
    if (strategyId) {
      getStrategyParametersDetails()
      getStrategyInstrumentsDetails()
      getBenchmarkData()
    }
  }, [strategyId])

  // account parameter
  // useEffect(() => {
  //   if (accountParametersQuery?.status === 'success') {
  //     if (accountParametersQuery?.data) {
  //       if (accountParametersQuery?.data?.data?.accountParameters && accountParametersQuery?.data?.data?.accountParameters && accountParametersQuery?.data?.data?.accountParameters?.length > 0) {
  //         setAccountParameters(accountParametersQuery?.data?.data?.accountParameters[0])
  //       }
  //     }
  //   } else if (accountParametersQuery?.status === 'error') {
  //     showError(accountParametersQuery?.error, false, {}, 'Failed to load account parameters.)
  //   }
  // }, [accountParametersQuery?.isFetching])

  useEffect(() => {
    if (accountParameters) {
      setCardData(prevData => ({
        ...prevData,
        faName: accountParameters?.faName ?? 0,
        taxSensitivity: accountParameters?.taxSensitivity ?? 0,
        totalMarketValue: accountParameters?.totalMarketValue ?? 0,
        unrealizedGainLoss:
          accountParameters?.unrealizedGainLoss ?? 0,
        unrealizedGainLossWeight:
          accountParameters?.unrealizedGainLossWeight ?? 0,
        ytdRealizedGainLoss:
          accountParameters?.ytdRealizedGainLoss ?? 0,
        assetAllocationDrift:
          accountParameters?.assetAllocationDrift ?? 0,
        incomeDivYield: accountParameters?.incomeDivYield ?? 0,
        cashLevel: accountParameters?.cashLevel ?? 0,
        cashDrift: accountParameters?.cashDrift ?? 0,
        cashAmount: accountParameters?.cashAmount ?? 0,
        totalMvPercentage: accountParameters?.totalMvPercentage ?? 0
      }))
    }
  }, [accountParameters])

  useEffect(() => {
    if (strategyParameters) {
      setCardData(prevData => ({
        ...prevData,
        strategyDividendYield:
          strategyParameters?.strategyDividendYield ?? 0,
        benchmarkLongName:
          strategyParameters?.benchmarkLongName ?? 0,
        cashFloor: strategyParameters?.cashLevel ?? 0
      }))
    }
  }, [strategyParameters])

  useEffect(() => {
    const convertDataForApexCharts = (marketValueData) => {
      if (!marketValueData || !marketValueData?.marketData) {
        return []
      }
      // filter data-points which has valid market value and store it for chart data point
      const graphData = marketValueData?.marketData?.filter(marketValueObj => marketValueObj?.metric?.NET_MV_PLUS_CASH !== undefined && marketValueObj?.metric?.NET_MV_PLUS_CASH !== null)?.map((entry) => {
        const dataDate = new Date(entry.dataDate).getTime()
        const netMvPlusCash = entry.metric.NET_MV_PLUS_CASH
        return { x: dataDate, y: netMvPlusCash, annotation: entry.metric }
      })
      return graphData
    }
    const apexChartData = convertDataForApexCharts(marketValueData)
    setMarketChartData(apexChartData)
  }, [marketValueData])

  // useEffect(() => {
  //   if (benchmarkQuery?.status === 'success') {
  //     if (benchmarkQuery?.data) {
  //       setBenchmarkReturnsData(benchmarkDataProcess(benchmarkQuery?.data))
  //     }
  //   } else if (benchmarkQuery?.status === 'error') {
  //     showError(benchmarkQuery?.error, false, {}, 'Failed to load benchmark data.')
  //   }
  // }, [benchmarkQuery?.isFetched])

  const openExpandedView = (chartName, data) => {
    let chartData = {
      data,
      accountId: params?.accountId,
      strategyId: (filterSecureData && filterSecureData[0])?.strategyId,
      selectedBenchmark: [(filterSecureData && filterSecureData[0])?.strategyId],
      accountType: (filterSecureData && filterSecureData[0])?.accountType,
      startDate: benchmarkReturnsData?.startDate,
      endDate: benchmarkReturnsData?.endDate,
      strategyList: benchmarkReturnsData?.strategyList,
      calculatedMvPlusCashData: marketValueData?.calculatedMvPlusCashData,
      selectedAccountIds: [{ accountId: params?.accountId }]
    }
    if (chartName === 'MV') {
      // set present annotation data as selected annotation
      const annotationsArray = marketValueData?.marketData ? marketValueData?.marketData?.flatMap(entry => ({ annotations: entry.metric })) : []
      const uniqueAnnotations = [...new Set(annotationsArray.flatMap(entry => Object.keys(entry.annotations)))]
      chartData = {
        ...chartData,
        annotationList: uniqueAnnotations,
        selectedAnnotations: uniqueAnnotations
      }
    }
    navigate(`/account-review/expanded-charts/${params?.accountId}`, {
      state: {
        chartName,
        chartData
      }
    })
  }
  // currently the below commented getAccountInstrumentsDetails api is not being use for future purpose

  // const getAccountInstrumentsDetails = (clearCacheFlag = false) => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments': true,
  //     ...(clearCacheFlag ? { 'cache-override': true } : {})
  //   })
  //     .then((response) => {
  //       if (response.data) {
  //         setAccountInstruments(response.data)
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error, false, {}, 'Failed to load account details.')
  //       setSectorLoading(false)
  //     })
  // }

  const getAccountInstrumentDriftDetails = (clearCacheFlag = false) => {
    setSectorLoading(true)
    getAccountAttributes(`${params?.accountId}`, {
      'account-instruments-drift': true,
      ...(clearCacheFlag ? { 'cache-override': true } : {})
    })
      .then((response) => {
        if (response?.data) {
          response?.data?.accountInstrumentsDriftData?.forEach((item) => {
            if (item.localSymbol === '__USD') {
              item.localSymbol = 'CASH'
            }
          })

          setAccountInstrumentDrift(response?.data)

          setUnderWeight(
            response?.data?.accountInstrumentsDriftData?.sort(function (a, b) {
              return a.weight - b.weight
            })
          )
          const overWeightData =
            response?.data?.accountInstrumentsDriftData?.slice()
          setOverWeight(
            overWeightData?.sort(function (a, b) {
              return b.weight - a.weight
            })
          )
        }
        setSectorLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account instrument drift details.')
        setSectorLoading(false)
      })
  }

  const getAccountParametersDetails = (clearCacheFlag = false) => {
    setSectorLoading(true)
    getAccountAttributes(`${params?.accountId}`, {
      'account-parameters': true,
      ...(clearCacheFlag ? { 'cache-override': true } : {})
    })
      .then((response) => {
        if (response?.data?.accountParameters && response?.data?.accountParameters && response?.data?.accountParameters?.length > 0) {
          setAccountParameters(response?.data?.accountParameters[0])
        }
        setSectorLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account parameter details.')
        setSectorLoading(false)
      })
  }

  // const getUnderOverWeight = (clearCacheFlag = false) => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments-drift': true,
  //     ...(clearCacheFlag ? { 'cache-override': true } : {})
  //   })
  //     .then((response) => {
  //       if (response.data.accountInstrumentsDriftData) {
  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //        showError(error, false, {}, 'Failed to load account instrument drift.')
  //      setSectorLoading(false)
  //     })
  // }

  const getStrategyParametersDetails = (clearCacheFlag = false) => {
    setTopPositionLoader(true)
    getStrategyAttributes(`${strategyId}`, {
      'strategy-parameters': true,
      ...(clearCacheFlag ? { 'cache-override': true } : {})
    })
      .then((response) => {
        if (response?.data && response?.data?.strategyParameters && response?.data?.strategyParameters?.length > 0) {
          setStrategyParameters(response?.data?.strategyParameters[0])
        }
        setTopPositionLoader(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load strategy parameters.')
        setTopPositionLoader(false)
      })
  }

  const getStrategyInstrumentsDetails = (clearCacheFlag = false) => {
    setTopPositionLoader(true)
    getStrategyAttributes(`${strategyId}`, {
      'strategy-instruments': true,
      ...(clearCacheFlag ? { 'cache-override': true } : {})
    })
      .then((response) => {
        if (response?.data) {
          setStrategyInstruments(response?.data?.strategyInstruments)
        }
        setTopPositionLoader(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load strategy instruments list.')
        setTopPositionLoader(false)
      })
  }

  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error, false, {}, 'Failed to load account parameters details.')
  //       setSectorLoading(false)
  //     })
  // }
  // const getUnderOverWeight = () => {
  //   setSectorLoading(true)
  //   getAccountAttributes(`${params.accountId}`, {
  //     'account-instruments-drift': true
  //   })
  //     .then((response) => {
  //       if (response.data.accountInstrumentsDriftData) {
  //         setUnderWeight(
  //           response.data.accountInstrumentsDriftData.sort(function (a, b) {
  //             return a.weight - b.weight
  //           })
  //         )
  //         const overWeightData =
  //           response.data.accountInstrumentsDriftData.slice()
  //         setOverWeight(
  //           overWeightData.sort(function (a, b) {
  //             return b.weight - a.weight
  //           })
  //         )
  //       }
  //       setSectorLoading(false)
  //     })
  //     .catch((error) => {
  //       showError(error, false, {}, 'Failed to load account instrument drift.')
  //       setSectorLoading(false)
  //     })
  // }
  // const getStrategyDetails = (clearCacheFlag = false) => {
  //   setTopPositionLoader(true)
  //   getStrategyAttributes(`${strategyId}`, {
  //     'strategy-parameters': true,
  //     'strategy-instruments': true,
  //     ...(clearCacheFlag ? { 'cache-override': true } : {})
  //   })
  //     .then((response) => {
  //       if (response?.data) {
  //         setStrategyAttributes(response?.data)
  //       }
  //       setTopPositionLoader(false)
  //     })
  //     .catch((error) => {
  //       showError(error, false, {}, 'Failed to load strategy details')
  //       setTopPositionLoader(false)
  //     })
  // }

  const getAccountRequests = () => {
    setRequestLoader(true)
    API.get(
      'baseRequestURL',
      `request/v1/${user?.userGroup}/requests/${params?.accountId}`
    )
      .then((response) => {
        if (response?.data?.length === 0) {
          setNodata(response?.message)
        } else if (response?.data) {
          setNodata() // Clear out the 'no data' message in case request is created after rendering
          setAccountRequests(response?.data?.reqData)
        }
        setRequestLoader(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account requests.')
        setRequestLoader(false)
      })
  }

  const getAssetAllocationData = (clearCacheFlag = false) => {
    setAssetLoading(true)
    API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup}/accounts/${params?.accountId}/asset-allocations`,
      { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
    )
      .then((response) => {
        if (response?.data) {
          setAssetAllocationData(response?.data ? response?.data?.map((row) => ({ ...row, id: randomId() })) : [])
        }
        setAssetLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load asset allocation.')
        setAssetLoading(false)
      })
  }
  // accountRiskAttributes Data
  const getRiskAttributes = async (clearCacheFlag = false) => {
    API.get(
      'baseURL',
      `account-master/v1/${user?.userGroup}/accounts/${params?.accountId}/risks/attributes`,
      { queryStringParameters: clearCacheFlag ? { 'cache-override': true } : {} }
    )
      .then((response) => {
        if (response?.data) {
          setRiskAttributes(response?.data)
          setValue(
            Number(response?.data?.accountRiskAttributes[0]?.statsValue).toFixed(2)
          )
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load risk attributes.')
      })
  }

  const getBenchmarkData = () => {
    setAssetLoading(true)
    API.post(
      'baseUriReport',
      `reporting/v1/${user?.userGroup}/benchmark-returns/account/${params?.accountId}/strategy/${strategyId}`, { body: {} }
    )
      .then((response) => {
        if (response?.data) {
          setBenchmarkReturnsData(benchmarkDataProcess(response))
        }
        setAssetLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load benchmark data')
        setAssetLoading(false)
      })
  }

  const getMetricData = () => {
    setIsMVChartLoading(true)
    API.post(
      'baseUriReport',
      `reporting/v1/${user?.userGroup}/metric-data/${params?.accountId}`, { body: {} })
      .then((response) => {
        if (response?.data) {
          setMarketValueData(response?.data)
        }
        setIsMVChartLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load market value data')
        setIsMVChartLoading(false)
      })
  }

  const getActivitySummary = () => {
    setAssetLoading(true)
    API.get(
      'baseUriReport',
      `reporting/v1/${user?.userGroup}/activity-summary/${params?.accountId}`)
      .then((response) => {
        if (response?.data) {
          setAccountActivitySummaryData(response?.data)
        }
        setAssetLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load activity summary data.')
        setAssetLoading(false)
      })
  }

  const screen = 'not main'

  const clearCaching = () => {
    getAccountInstrumentDriftDetails(true)
    getAccountParametersDetails(true)
    getStrategyParametersDetails(true)
    getStrategyInstrumentsDetails(true)
    getAssetAllocationData(true)
    getRiskAttributes(true)
  }

  return (
    <Box className='account-dashboard'>
      <AccountTitle title='Dashboard'>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          {
            riskAttributes?.lastUpdatedTime?.updateTime
              ? (
                <div style={{ marginRight: '10px' }}>
                  Data Date:{' '}
                  {riskAttributes?.lastUpdatedTime?.updateTime ? riskAttributes?.lastUpdatedTime?.updateTime?.split(' ')[0] : '-'}
                </div>
                )
              : (
                <></>
                )
          }
          <InternalNavigation clearCaching={clearCaching} />
        </Box>
      </AccountTitle>
      <ErrorBoundary
        fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Account Attributes' />)} onReset={() => {
          // accountParametersQuery?.refetch()
          // strategyParametersQuery?.refetch()
        }}
      >
        <AccountAttributes data={cardData} />
      </ErrorBoundary>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Allocation Chart' />)} onReset={() => {
              // accountAssetAllocationQuery?.refetch()
            }}
          >
            <PortfolioModel data={assetAllocationData} />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Major Asset Class Allocation' />)} onReset={() => {
              // accountAssetAllocationQuery?.refetch()
            }}
          >
            {assetLoading ? <MajorAssetAllocationLoader title='Major Asset Class Allocation' /> : <AssetAllocation data={assetAllocationData} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={7}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Portfolio Risk' />)} onReset={() => {
              // accountRiskAttributesQuery?.refetch()
            }}
          >
            <RealativeVolatility riskAttributes={riskAttributes.accountRiskAttributes} />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={5}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Requests' />)} onReset={() => {
              // accountRequestsQuery?.refetch()
            }}
          >
            {requestLoader ? <DashboardRequestLoader title='Request' /> : <AccountRequests data={accountRequests || []} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Allocation Chart' />)} onReset={() => {
              // accountAssetAllocationQuery?.refetch()
            }}
          >
            <Card className='card-layout' sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography component='p' className='text-title header-text'>
                    Market Value
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: '3px' }}>
                    {isChartZoomed && (
                      <Tooltip title='Reset Chart'>
                        <IconButton id='basic-button' aria-haspopup='true' onClick={resetChart} sx={{ marginRight: '-8px' }}>
                          <IoReload size='18px' color='#74788D' />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(!isMVChartLoading && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.SUB_MODULE_ACCESS, { subModuleName: moduleConfig.EXPANDED_CHARTS })) && (
                      (filterSecureData && filterSecureData[0]?.accountType === 'TRADING') && (
                        <Tooltip title='Expand'>
                          <IconButton id='expand-button' onClick={() => openExpandedView('MV', marketChartData)}>
                            <IoIosExpand size='18px' color='#74788D' />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </Box>
                </Box>
                {isMVChartLoading
                  ? <Skeleton variant='rectangle' animation='wave' width='100%' height={330} />
                  : <MarketValueAreaChart data={marketChartData || []} resetKey={resetKey} onZoomChange={handleZoomChange} height={315} showAnnotations={false} />}
              </CardContent>
            </Card>
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Activity Summary' />)} onReset={() => {
              // accountAssetAllocationQuery?.refetch()
            }}
          >
            {assetLoading ? <ActivitySummaryLoader title='Activity Summary' label='Beta' /> : <AccountActivityTable data={accountActivitySummaryData} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid container my={1} rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top Model Positions' />)} onReset={() => {
              // strategyInstrumentsDriftQuery?.refetch()
            }}
          >
            {topPositionLoader ? <TopSecurityLoader title='Top Model Positions' /> : <TopSecurity data={strategyInstruments || []} />}
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top OverWeighted Securities' />)} onReset={() => {
              // accountInstrumentsDriftQuery?.refetch()
            }}
          >
            {sectorLoading ? <SectorAndSecurityLoader title='Top Over Weighted Securities' /> : <OverWeightedSecurity data={overWeight || []} />}
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} lg={4} md={6}>
          <ErrorBoundary
            fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} header='Top UnderWeighted Securities' />)} onReset={() => {
              // accountinstrumentDrift.refetch()
            }}
          >
            {sectorLoading ? <SectorAndSecurityLoader title='Top Under Weighted Securities' /> : <UnderWeightedSecurities underWeight={underWeight || []} />}
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AccountDashboard
