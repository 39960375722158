import React, { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Add, Close, Remove } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { formatCurrency } from '../../Report/components/dataProcess/DataProcess'
import LivePriceAlert from '../components/LivePriceAlert'
import TradeFeasibilityReportTab from './TradeFeasibilityReportTab'
import {
  detailColumns,
  modelChangesHeaders,
  restrictionColumns,
  getPositionSecurityTableHeader,
  getCompanyColumns,
  getTradeTableHeaders,
  getTaxlotTableHeaders,
  getPositionGroupTableHeader,
  getTradeBlotterHeaders
} from './TradeDetailsColumns'
import TransferValidationTable from './TransferValidation'
import ExportCSV from './ExportCSV'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'

const renderOptTableSkeleton = () => {
  return (
    <Box sx={{ my: 3 }}>
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '50%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '30%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '20%', my: 2 }} />
      <Skeleton variant='rectangle' sx={{ height: '30px', width: '40%', my: 2 }} />
    </Box>
  )
}

const renderTableSkeleton = (header, hiddenColumns) => {
  if (hiddenColumns)
    header = header.filter(col => !hiddenColumns.includes(col.field))
  return (
    <TableContainer mt={5}>
      <Table className='table-responsive'>
        <TableHead>
          <TableRow sx={{ height: '39px' }}>
            {header.map((item, index) => (
              <TableCell key={index} sx={{ fontSize: '12px !important', whiteSpace: 'nowrap', paddingBlock: 0 }}>{item.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <TableRow key={index} sx={{ height: '30px' }}>
              {Array.from({ length: header.length }).map((_, index) => (
                <TableCell key={index} sx={{ paddingBlock: 0 }}>
                  <Skeleton variant='text' sx={{ fontSize: '0.8rem' }} />
                </TableCell>))}
            </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const renderDetailPanelDataGrid = (rows, columns, columnVisibilityModel = {}) => {
  return (
    <DataGridPro
      autoHeight
      density='compact'
      rows={rows}
      columns={columns}
      getRowId={(row) => row.id}
      hideFooter={rows.length <= 10}
      disableSelectionOnClick
      pagination
      pageSizeOptions={[10]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
        columns: {
          columnVisibilityModel
        }
      }}
      sx={() => ({
        [`.${gridClasses.main}`]: {
          height: 'unset'
        },
        [`.${gridClasses.columnHeaders}`]: {
          minHeight: 'auto !important'
        }
      })}
    />
  )
}

const ViewTradeDetails = ({ accID, index, setIsShown, accountCode, optId,showTitle = true, isDILink, DILinkType }) => {
  const { user, checkAccess } = useAuth()
  const [value, setValue] = useState('Trade')
  const [optmRunSumPro, setOptmRunSPro] = useState([])
  const [positionData, setPositionData] = useState()
  const [optPropAssetData, setOptPropAssetData] = useState()
  const [optMapId] = useState({ index })
  const [currentPositionValue, setCurrentPositionValue] = useState('company')
  const [loading, setLoading] = useState(true)
  const [positionDataLoading, setPositionDataLoading] = useState(true)
  const [otherPropertiesLoading, setOtherPropertiesLoading] = useState(true)
  const [optPropAssetDataLoading, setOptPropAssetDataLoading] = useState(true)
  const [sectorData, setSectorData] = useState()
  const [tradeBlotterData, setTradeBlotterData] = useState([])
  const [optData, setOptData] = useState([])
  const [tradeDataLoading, setTradeDataLoading] = useState(true)
  const [industryData, setIndustryData] = useState()
  const [subIndustryData, setSubIndustryData] = useState()
  const [companyWiseData, setCompanyWiseData] = useState()
  const { showError } = useErrorToast()
  const tradeDetailsTab = ['Trade', 'Taxlot', 'Position', 'Blotter', 'Feasibility Report', 'Model Variance', ...(isDILink ? ['Transfer Validation'] : [])]
  const [securityLevelTradeData, setSecurityLevelTradeData] = useState()
  const [liveCash, setLiveCash] = useState(0)
  const [liveCashInPercent, setLiveCashInPercent] = useState(null)
  const [selectedTrdCode, setSelectedTrdCode] = useState({ buy: true, sell: true, cash: false, modelChange: false, transferRow: false })
  const [optFeasibilityData, setOptFeasibilityData] = useState(null)
  const [transferValidationData, setTransferValidationData] = useState(null)
  const [optFeasibilityLoading, setOptFeasibilityLoading] = useState(true)
  const [tabWiseLatestLivePriceTime, setTabWiseLatestLivePriceTime] = useState({
    'Blotter': null,
    'Taxlot': null
  })
  const [transferValidationLoading, setTransferValidationLoading] = useState(true)

  const canAccessModelChangeWithTrade = checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.SHOW_MODEL_CHANGE_WITH_TRADE })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const activeColumns = [
    { field: 'activeAccountTransferCode', headerName: 'Transfer Code', flex: 1, 
      renderCell: (params) => (
        <>
          {params?.value === 'SENT' && (
            <ArrowCircleDownIcon fontSize="small" sx={{ color: '#FF6161', mr: 0.5 }} />
          )}
          {params?.value === 'RECEIVED' && (
            <ArrowCircleUpIcon fontSize="small" sx={{ color: '#3BBFA3', mr: 0.5 }} />
          )}
          {params?.value && (
            <Typography fontSize="14px" fontWeight="600">{params?.value}</Typography>
          )}
        </>
      )},
    { field: 'activeAccountSymbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'activeAccountPurchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.activeAccountPurchaseDate ? params?.row?.activeAccountPurchaseDate?.split('T')[0] : ''
    },
    {
      field: 'activeAccountPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.activeAccountPurchasePrice
        return value == null || isNaN(value) ? null : parseFloat(value)
      },
      renderCell: (params) => !isNaN(params?.row?.activeAccountPurchasePrice)
        ? params?.row?.activeAccountPurchasePrice < 0
          ? `-$${Math.abs(params?.row?.activeAccountPurchasePrice)}`
          : `$${params?.row?.activeAccountPurchasePrice}`
        : ''
    },
    {
      field: 'activeAccountTransferOutQty',
      headerName: 'Transfer Out Qty',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.activeAccountTransferOutQty !== null && params?.row?.activeAccountTransferOutQty !== undefined ? params?.row?.activeAccountTransferOutQty : null
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    }
  ]

  const passiveColumns = [
    { field: 'passiveAccountTransferCode',
      headerName: 'Transfer Code',
      flex: 1, 
      renderCell: (params) => (
        <>
          {params?.value === 'SENT' && (
            <ArrowCircleDownIcon fontSize="small" sx={{ color: '#FF6161', mr: 0.5 }} />
          )}
          {params?.value === 'RECEIVED' && (
            <ArrowCircleUpIcon fontSize="small" sx={{ color: '#3BBFA3', mr: 0.5 }} />
          )}
          {params?.value && (
            <Typography fontSize="14px" fontWeight="600">{params?.value}</Typography>
          )}
        </>
      )
     },
    { field: 'passiveAccountSymbol', headerName: 'Symbol', flex: 1 },
    {
      field: 'passiveAccountPurchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.passiveAccountPurchaseDate ? params?.row?.passiveAccountPurchaseDate?.split('T')[0] : ''
    },
    {
      field: 'passiveAccountPurchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const value = params?.row?.passiveAccountPurchasePrice
        return value == null || isNaN(value) ? null : parseFloat(value)
      },
      renderCell: (params) => !isNaN(params?.row?.passiveAccountPurchasePrice)
        ? params?.row?.passiveAccountPurchasePrice < 0
          ? `-$${Math.abs(params?.row?.passiveAccountPurchasePrice)}`
          : `$${params?.row?.passiveAccountPurchasePrice}`
        : ''
    },
    {
      field: 'passiveAccountTransferOutQty',
      headerName: 'Transfer Out Qty',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params?.row?.passiveAccountTransferOutQty !== null && params?.row?.passiveAccountTransferOutQty !== undefined ? params?.row?.passiveAccountTransferOutQty : null
      },
      valueFormatter: (params) => {
        return params?.value != null ? params?.value?.toString() : ''
      }
    }
  ]

  const commonColumns = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box style={{ color: params.value === 'MATCHED' ? '#3BBFA3' : 'inherit' }}>{params.value}</Box>
      )
    }
  ]

  useEffect(() => {
    if (user) {
      getoptimSummaryPro(optMapId)
      getPositionData(optMapId)
      getOptPropAssetDriftData(optMapId)
      getOptimizationPersonalization(optId)
      getTradeBlotterData()
      getOptFeasibilityReport(optMapId)
      if (isDILink) {
        getTransferValidationData(optMapId)
      }
    }
  }, [user])

  useEffect(() => {
    setSecurityLevelTradeData(
      positionData
        ? positionData?.filter(
          row =>
            (
              selectedTrdCode['buy'] || selectedTrdCode['sell']
                ? selectedTrdCode[row?.trdCode?.toLowerCase()]
                : false
            )
            ||
            (selectedTrdCode['cash'] ? row.instr_id === '__CASH' : false)
            ||
            (selectedTrdCode['modelChange'] ? row?.isModelTrd : false)
            ||
            (selectedTrdCode['transferRow'] ? row?.isTransferLot : false)
        )
        : [])
  }, [selectedTrdCode])

  const getPositionData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/${isDILink ? 'di-assist/opt-prop-drift' : 'opt-prop-drift'}/${accMapId}`
    )
      .then((response) => {
        setPositionData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
        setSecurityLevelTradeData(response?.data ? response?.data?.filter(row => row.trdCode !== 'NOP').map((row) => ({ ...row, id: randomId() })) : [])
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load position data.')
      })
      .finally(() => {
        setPositionDataLoading(false)
      })
  }

  const getOptimizationPersonalization = (optId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })) {
      // const accMapId = optMapId.index
      API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/personalization/${optId}?source=MODEL_VARIANCE`)
        .then((response) => {
          if (response && response?.data) {
            setOptData(response?.data && response?.data?.length ? response?.data[0]?.value?.map((row) => ({ ...row, id: randomId() })) : [])
            setOtherPropertiesLoading(false)
          }
        })
        .catch((error) => {
          setOtherPropertiesLoading(false)
          showError(error, false, {}, 'Failed to load model variance data.')
        })
    } else {
      setOtherPropertiesLoading(false)
    }
  }

  const getoptimSummaryPro = (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.TRADE_OPT_TRADE_DETAILS })) {
      const accMapId = optMapId.index
      API.post(
        'baseOptimizationURL',
        `optimization/v1/${user?.userGroup}/${isDILink ? `di-assist/opt-prop-details/${accMapId}` : 'opt-prop-details'}`,
        { body: isDILink ? {} : { accOptMapId: [accMapId] } }
      )
        .then((response) => {
          setOptmRunSPro(response?.data?.data ? response?.data?.data?.map((row) => ({ ...row, id: randomId() })) : [])
          const taxlotData = response?.data?.data?.slice() || []
          const latestLivePriceTs = taxlotData?.length ? taxlotData?.sort((a, b) => new Date(b.livePriceTs).getTime() - new Date(a.livePriceTs).getTime())[0]?.livePriceTs : null
          setTabWiseLatestLivePriceTime(prev => ({
            ...prev,
            'Taxlot': latestLivePriceTs ? latestLivePriceTs : null
          }))
        })
        .catch((error) => {
          showError(error, false, {}, 'Failed to load taxlot data.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const getOptPropAssetDriftData = (optMapId) => {
    const accMapId = optMapId.index

    API.post(
      'baseOptimizationURL',
      `optimization/v1/${user?.userGroup}/${isDILink ? 'di-assist/opt-prop-asset-drift' : 'opt-prop-asset-drift'}/${accMapId}`
    )
      .then((response) => {
        setOptPropAssetData(response?.data ? response?.data.map((row) => ({ ...row, id: randomId() })) : [])
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load property asset drift data.')
      })
      .finally(() => {
        setOptPropAssetDataLoading(false)
      })
  }

  const getBlotterAPI = () => {
    return isDILink
      ? API.get('baseOptimizationURL', `optimization/v1/${user?.userGroup}/di-assist/trade-blotter/accounts/${optMapId?.index}`)
      : API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-blotter/accounts/${optMapId?.index}`)
  }

  const getTradeBlotterData = () => {
    getBlotterAPI().then((res) => {
      setLiveCash(res?.data?.liveCashInPortfolio?.liveCashInPortfolio || null)
      setLiveCashInPercent(res?.data?.liveCashInPortfolio?.liveCashInPortfolioInPercentage || null)
      setTradeBlotterData(res?.data ? res?.data?.blotterData?.map((row) => ({ ...row, id: randomId() })) : [])
      setTradeDataLoading(false)
      const blotterData = res?.data?.blotterData?.slice() || []
      const latestLivePriceTs = blotterData?.length ? blotterData?.sort((a, b) => new Date(b.livePriceTs).getTime() - new Date(a.livePriceTs).getTime())[0]?.livePriceTs : null
      setTabWiseLatestLivePriceTime(prev => ({
        ...prev,
        'Blotter': latestLivePriceTs ? latestLivePriceTs : null
      }))
    }).catch((error) => {
      setTradeDataLoading(false)
      showError(error, false, {}, 'Failed to load trade blotter data.')
    })
  }

  const getOptFeasibilityReport = async (optMapId) => {
    if (checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })) {
      try {
        const response = await API.post('baseOptimizationURL', `optimization/v1/${user?.userGroup}/opt-feasibility-report/`, { body: { accOptMapId: [optMapId?.index] } })
        if (response && response?.data?.length) {
          setOptFeasibilityData(response?.data[0]?.report)
        }
        setOptFeasibilityLoading(false)
      } catch (error) {
        setOptFeasibilityLoading(false)
        showError(error, false, {}, 'Failed to load feasibility report.')
      }
    }
  }

  const getTransferValidationData = (optMapId) => {
    API.post('baseOptimizationURL', `optimization/v1/${user?.userGroup}/di-assist/transfer-validation/${optMapId?.index}`)
      .then((response) => {
        setTransferValidationData(response?.data)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load transfer validation data.')
      })
      .finally(() => {
        setTransferValidationLoading(false)
      })
  }

  const getData = (tableName) => {
    const parentRow = {}
    optPropAssetData?.forEach(item => {
      const currItem = item[tableName]
      if (parentRow?.hasOwnProperty(currItem)) {
        parentRow[currItem].tradeDrift += (item.propDrift - item.initDrift)
        parentRow[currItem].modelWeight += item.modelWeight
        parentRow[currItem].initWeight += item.initWeight
        parentRow[currItem].propDrift += item.propDrift
        parentRow[currItem].initDrift += item.initDrift
        parentRow[currItem].propWeight += item.propWeight
        parentRow[currItem].transferInWeight += item.transferInWeight
        parentRow[currItem].transferOutWeight += item.transferOutWeight
        parentRow[currItem].tradeWeight += item.propWeight - item.initWeight
      } else {
        const hierarchy = [item[tableName]]
        const modelWeight = item.modelWeight
        const initWeight = item.initWeight
        const propDrift = item.propDrift
        const initDrift = item.initDrift
        const propWeight = item.propWeight
        const transferInWeight = item.transferInWeight
        const transferOutWeight = item.transferOutWeight
        const tradeWeight = item.propWeight - item.initWeight
        const tradeDrift = item.propDrift - item.initDrift
        parentRow[currItem] = { hierarchy, initWeight, propDrift, initDrift, propWeight, modelWeight, tradeDrift, tradeWeight, transferInWeight, transferOutWeight  }
      }
    })

    const childRow = {}
    optPropAssetData?.forEach(item => {
      if (childRow.hasOwnProperty(item[tableName])) {
        // if this field is available then add up all the values
        let isCurrentObjectAdded = 0
        if (tableName !== 'companyName') {
          childRow[item[tableName]].forEach(obj => {
            // but add up fields only if companyName matches
            // e.g: one sector can have one company multiple times
            if (obj.companyName === item.companyName) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.transferInWeight += item.transferInWeight
              obj.transferOutWeight += item.transferOutWeight
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        } else {
          // roll up same instrument data
          childRow[item[tableName]].forEach(obj => {
            if (obj.instrId === item.instrId) {
              obj.modelWeight += item.modelWeight
              obj.initWeight += item.initWeight
              obj.propWeight += item.propWeight
              obj.initDrift += item.initDrift
              obj.propDrift += item.propDrift
              obj.transferInWeight += item.transferInWeight
              obj.transferOutWeight += item.transferOutWeight
              obj.tradeWeight += item.propWeight - item.initWeight
              obj.tradeDrift += (item.propDrift - item.initDrift)
              isCurrentObjectAdded = 1
            }
          })
        }
        if (isCurrentObjectAdded === 0) {
          // if field matches but companyName doesn't match (flag != 1) then it's a new company
          // add that into item[tableName]
          childRow[item[tableName]].push({
            instrId: item.instrId,
            hierarchy: tableName === 'companyName' ? [item.companyName, item.instrId] : [item[tableName], item.companyName],
            companyName: item.companyName,
            modelWeight: item.modelWeight,
            initWeight: item.initWeight,
            propWeight: item.propWeight,
            initDrift: item.initDrift,
            propDrift: item.propDrift,
            transferInWeight: item.transferInWeight,
            transferOutWeight: item.transferOutWeight,
            tradeDrift: item.propDrift - item.initDrift,
            tradeWeight: item.propWeight - item.initWeight,
            ...(tableName === 'companyName' ? { instrRestr: item.instrRestr } : {})
          })
          if (tableName === 'companyName' && item.instrRestr) {
            parentRow[item[tableName]].doesChildHaveInstrRestr = true
          }
        }
      } else {
        // if this field is not available then add that into array of object
        const temp = {
          instrId: item.instrId,
          hierarchy: [item[tableName], tableName === 'companyName' ? item.instrId : item.companyName],
          companyName: item.companyName,
          modelWeight: item.modelWeight,
          initWeight: item.initWeight,
          propWeight: item.propWeight,
          initDrift: item.initDrift,
          propDrift: item.propDrift,
          transferInWeight: item.transferInWeight,
          transferOutWeight: item.transferOutWeight,
          tradeDrift: item.propDrift - item.initDrift,
          tradeWeight: item.propWeight - item.initWeight,
          ...(tableName === 'companyName' ? { instrRestr: item.instrRestr } : {})
        }
        if (tableName === 'companyName' && item.instrRestr) {
          parentRow[item[tableName]].doesInstrRestrExists = true
        }
        childRow[item[tableName]] = [temp]
      }
    })

    let finalArray = []
    for (const key in parentRow) {
      finalArray.push(parentRow[key])
      finalArray = finalArray.concat(childRow[key])
    }

    finalArray = finalArray.map(obj => {
      return {
        ...obj,
        id: randomId(),
        modelWeight: obj.modelWeight?.toFixed(7),
        initWeight: obj.initWeight?.toFixed(7),
        tradeDrift: obj.tradeDrift?.toFixed(7),
        initDrift: obj.initDrift?.toFixed(7),
        propDrift: obj.propDrift?.toFixed(7),
        propWeight: obj.propWeight?.toFixed(7),
        tradeWeight: obj.tradeWeight?.toFixed(7),
        transferInWeight: obj.transferInWeight?.toFixed(7),
        transferOutWeight: obj.transferOutWeight?.toFixed(7),
      }
    })
    return finalArray
  }

  useEffect(() => {
    setSectorData(getData('sector'))
    setIndustryData(getData('industry'))
    setSubIndustryData(getData('subIndustry'))
    setCompanyWiseData(getData('companyName'))
  }, [optPropAssetData])

  const redirectToPersonalization = () => {
    localStorage.setItem('selectedPersonalizationTab', 'Security')
    window.open(`/account-review/personalization/${accID}`)
  }

  const tradeBlotterHeaders = getTradeBlotterHeaders(isDILink)

  const positionSecurityTableHeader = getPositionSecurityTableHeader(redirectToPersonalization, isDILink)

  const companyColumns = getCompanyColumns(redirectToPersonalization, isDILink)

  const tradeTableHeaders = getTradeTableHeaders(redirectToPersonalization, isDILink, canAccessModelChangeWithTrade)

  const taxlotTableHeaders = getTaxlotTableHeaders(isDILink)

  const positionGroupTableHeader = getPositionGroupTableHeader(isDILink)

  const getRowId = (row) => row.id

  const handlePositionChange = (e) => {
    setCurrentPositionValue(e.target.value)
  }

  const getDetailPanelContent = (params) => {
    const detailRows = params.row.propDetail.map((details) => ({ ...details, id: randomId() }))

    return (
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          density='compact'
          autoHeight
          rows={detailRows}
          columns={detailColumns}
          getRowId={(row) => row.id}
          hideFooter
          disableSelectionOnClick
          sx={() => ({
            [`.${gridClasses.main}`]: {
              height: 'auto !important'
            },
            [`.${gridClasses.columnHeaders}`]: {
              minHeight: 'auto !important'
            },
            [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
              fontSize: '12px'
            }
          })}
        />
      </Box>
    )
  }

  const renderDataGridPro = (rows, column, header, detailPanel = 0, getDetailPanelContent) => {
    const hiddenColumns = column.filter(column => column.defaultHidden)
    const columnVisibilityModel = {}
    if (hiddenColumns?.length) {
      hiddenColumns?.forEach(columnObj => {
        columnVisibilityModel[columnObj?.field || ''] = false
      })
    }
    return (
      <DataGridPro
        autoHeight
        rows={rows || []}
        columns={column}
        getRowId={getRowId}
        rowHeight={30}
        columnHeaderHeight={39}
        disableSelectionOnClick
        disableRowSelectionOnClick
        getDetailPanelHeight={detailPanel ? (row) => 'auto' : null}
        getDetailPanelContent={detailPanel ? getDetailPanelContent : null}
        pagination
        pageSizeOptions={[15, 25, 50, 75, 100]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } },
          columns: {
            columnVisibilityModel: Object.keys(columnVisibilityModel)?.length ? columnVisibilityModel : {}
          }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)'
          },
          [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
            fontSize: '12px'
          },
          [`.${gridClasses.columnHeaders}`]: {
            fontSize: '12px',
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            top: 0,
            minHeight: (header === 'Blotter') ? '60px !important' : 'unset !important' // Set minHeight conditionally
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            overflowY: 'auto !important',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'none',
            marginTop: 'auto !important'
          }
        })}
      />
    )
  }

  const renderDataPanel = () => {
    switch (currentPositionValue) {
      case 'security':
        return (
          <>
            {positionDataLoading
              ? renderTableSkeleton(positionSecurityTableHeader)
              : <DataGridPro
                density='compact'
                autoHeight
                rows={positionData || []}
                columns={positionSecurityTableHeader}
                getTreeDataPath={(row) => ([getRowId(row)])}
                getRowId={getRowId}
                disableSelectionOnClick
                disableRowSelectionOnClick
                getDetailPanelHeight={({ row }) => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                pagination
                pageSizeOptions={[15]}
                initialState={{
                  ...positionData?.initialState,
                  pagination: { paginationModel: { pageSize: 15 } }
                }}
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)'
                  },
                  [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
                    fontSize: '12px'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    fontSize: '12px',
                    position: 'sticky',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1,
                    top: 0,
                    minHeight: '60px !important'
                  },
                  [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                    color: '#74788d',
                    fontWeight: 600
                  },
                  [`.${gridClasses.virtualScroller}`]: {
                    overflowY: 'auto !important',
                    scrollbarGutter: 'stable',
                    scrollbarWidth: 'none',
                    marginTop: 'auto !important'
                  }
                })}
              />}
          </>
        )
      case 'sector':
        let headerSector = [
          {
            field: 'sector',
            headerName: 'Sector',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerSector = headerSector.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={sectorData} columns={headerSector} loading={optPropAssetDataLoading} />
        )
      case 'industry':
        let headerIndustry = [
          {
            field: 'industry',
            headerName: 'Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerIndustry = headerIndustry.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={industryData} columns={headerIndustry} loading={optPropAssetDataLoading} />
        )
      case 'sub-industry':
        let headerSubIndustry = [
          {
            field: 'subIndustry',
            headerName: 'Sub-Industry',
            flex: 1,
            valueGetter: (params) => {
              const hierarchy = params?.row?.hierarchy
              return hierarchy ? hierarchy[hierarchy.length - 1] : null
            }
          }
        ]
        headerSubIndustry = headerSubIndustry.concat(positionGroupTableHeader)
        return (
          <PositionDataGrid rows={subIndustryData} columns={headerSubIndustry} loading={optPropAssetDataLoading} />
        )
      case 'company':
        return (
          <PositionDataGrid rows={companyWiseData} columns={companyColumns} loading={optPropAssetDataLoading} />
        )
      default:
        return ''
    }
  }

  const TradeDetailPanelContent = ({ row }) => {
    const [selectedValue, setSelectedValue] = useState('taxlot')
    const [data, setData] = useState((optmRunSumPro || [])?.filter(obj => obj?.instrId === row?.instr_id))
    const taxlotColumns = [...taxlotTableHeaders.slice(0, 2), taxlotTableHeaders[3], taxlotTableHeaders[2], ...taxlotTableHeaders.slice(4)]

    useEffect(() => {
      if (selectedValue === 'taxlot') {
        setData(optmRunSumPro.filter(obj => obj?.instrId === row?.instr_id))
      } else if (selectedValue === 'blotter') {
        setData(tradeBlotterData.filter(obj => obj?.instrId === row?.instr_id))
      } else if (selectedValue === 'restrictions') {
        setData((row?.instrRestrInfo || [])?.map((obj, index) => ({ ...obj, id: index })))
      }
    }, [selectedValue, row, optmRunSumPro, tradeBlotterData])

    const selectedValueStyle = { color: '#1976d2' }

    return (
      <>
        <Box sx={{ py: 1 }}>
          <RadioGroup
            row
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <FormControlLabel value='taxlot' control={<Radio size='small' />} label='Taxlot'
              sx={
                selectedValue === 'taxlot' ? selectedValueStyle : {}
              }
            />
            <FormControlLabel value='blotter' control={<Radio size='small' />} label='Blotter'
              sx={
                selectedValue === 'blotter' ? selectedValueStyle : {}
              }
            />
            <FormControlLabel value='restrictions' control={<Radio size='small' />} label='Restrictions'
              sx={
                selectedValue === 'restrictions' ? selectedValueStyle : {}
              }
            />
          </RadioGroup>
          <Box sx={{ boxShadow: 3, mt: '10px', padding: '5px' }}>
            {
              selectedValue === 'taxlot' ? (
                loading
                  ? renderTableSkeleton(taxlotColumns, ['instrId', 'localSymbol'])
                  : renderDetailPanelDataGrid(data, taxlotColumns, { instrId: false, localSymbol: false })
              ) : selectedValue === 'blotter' ? (
                tradeDataLoading
                  ? renderTableSkeleton(tradeBlotterHeaders)
                  : renderDetailPanelDataGrid(data, tradeBlotterHeaders, {})
              ) : renderDetailPanelDataGrid(data, restrictionColumns, {})
            }
          </Box>
        </Box>
      </>
    )
  }

  const getTradeDetailPanelContent = useCallback(({ row }) => <TradeDetailPanelContent row={row} />, [optmRunSumPro, tradeBlotterData])

  const renderTabPanel = (item) => {
    switch (item) {
      case 'Taxlot':
        return (
          <>
            {!loading
              ? renderDataGridPro(optmRunSumPro, taxlotTableHeaders)
              : renderTableSkeleton(taxlotTableHeaders)}
          </>
        )
      case 'Position':
        return (
          renderDataPanel()
        )
      case 'Trade':
        return (
          !positionDataLoading
            ? <DataGridPro
              rows={securityLevelTradeData}
              columns={tradeTableHeaders}
              density='compact'
              autoHeight
              getRowId={(row) => row?.id}
              {
              ...(canAccessModelChangeWithTrade ? {
                getDetailPanelHeight: ({ row }) => 'auto',
                getDetailPanelContent: getTradeDetailPanelContent
              } : {})
              }
              pagination
              pageSizeOptions={[15]}
              initialState={{
                ...positionData?.initialState,
                pagination: { paginationModel: { pageSize: 15 } }
              }}
              sx={(theme) => ({
                [`.${gridClasses.main}`]: {
                  height: 'calc(100vh - 16px - 16px - 40px - 48px - 53px)'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  backgroundColor: theme.palette.background.paper,
                  zIndex: 1,
                  top: 0
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'none',
                  marginTop: 'auto !important'
                }
              })}
            />
            : renderTableSkeleton(tradeTableHeaders)
        )
      case 'Blotter':
        return (
          <>
            {
              !tradeDataLoading ? renderDataGridPro(tradeBlotterData, tradeBlotterHeaders, 'Blotter') : renderTableSkeleton(tradeBlotterHeaders)
            }
          </>
        )
      case 'Feasibility Report':
        return (
          <Box sx={{ height: 'calc(100vh - 8px - 8px - 40px - 32px)', overflowY: 'auto' }}>
            {optFeasibilityLoading
              ? renderOptTableSkeleton()
              : <TradeFeasibilityReportTab optFeasibilityReport={optFeasibilityData || {}} />}
          </Box>
        )
      case 'Model Variance':
        return (
          <>
            {!otherPropertiesLoading ? renderDataGridPro(optData, modelChangesHeaders) : renderTableSkeleton(modelChangesHeaders)}
          </>
        )
      case 'Transfer Validation':
        return (
          <Box sx={{ height: 'calc(100vh - 16px - 16px - 40px - 48px)', overflowY: 'auto' }}>
            {!transferValidationLoading
              ? (
                <TransferValidationTable
                  data={transferValidationData || {}}
                  activeColumns={activeColumns}
                  passiveColumns={passiveColumns}
                  commonColumns={commonColumns}
                  DILinkType={DILinkType}
                />
                )
              : (
                  renderTableSkeleton([...commonColumns, ...activeColumns, ...passiveColumns])
                )}
          </Box>
        )
      default:
        return 'Something went wrong'
    }
  }

  const filteredTradeDetailsTab = tradeDetailsTab.filter(item => {
    if (item === 'Feasibility Report') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.FEASIBILITY_REPORT })
    } else if (item === 'Model Variance') {
      return checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, component_name: moduleConfig.MODEL_VARIANCE })
    }
    return true
  })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Trade Details</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <ExportCSV
            value={value}
            currentPositionValue={currentPositionValue}
            securityLevelTradeData={securityLevelTradeData}
            optmRunSumPro={optmRunSumPro}
            positionData={positionData}
            sectorData={sectorData}
            industryData={industryData}
            subIndustryData={subIndustryData}
            companyWiseData={companyWiseData}
            tradeBlotterData={tradeBlotterData}
            optData={optData}
            accountCode={accountCode}
            optMapId={optMapId}
            optId={optId}
            isDILink={isDILink}
            canAccessModelChangeWithTrade={canAccessModelChangeWithTrade}
          />
          <IconButton aria-label='close' edge='end' onClick={() => setIsShown()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <TabContext value={value}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='standard'
              scrollButtons={true}
              className='report-tab-header'
              TabIndicatorProps={{
                style: { backgroundColor: '#34475A' }
              }}
              sx={{ minHeight: '32px' }}
              indicatorColor='#34475A'
            >
              {
                filteredTradeDetailsTab?.map((item, index) => (
                  <Tab className='reportCardNav'
                    key={index}
                    value={item}
                    label={item}
                    sx={{
                      fontSize: '14px !important', padding: '6px 8px', minHeight: '32px'
                    }}
                  />
                ))
              }
              <LivePriceAlert dateTime={tabWiseLatestLivePriceTime[value]} />
            </Tabs>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
        </Box>
        <Box>
          {filteredTradeDetailsTab?.map((item, index) => (
            <TabPanel
              key={index}
              index={index}
              value={item}
              sx={{ padding: 0 }}
            >
              {renderTabPanel(item)}
            </TabPanel>
          ))}
        </Box>
      </Box>
      </TabContext>
    </>
  )
}

const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0
          ? (
            <IconButton onClick={handleClick} tabIndex={-1} size='small'>
              {rowNode.childrenExpanded ? <Remove fontSize='small' /> : <Add fontSize='small' />}
            </IconButton>
          )
          : (
            <span />
          )}
      </div>
    </Box>
  )
}

const PositionDataGrid = ({ columns, rows, loading }) => {
  return (
    loading
      ? renderTableSkeleton(columns)
      : <DataGridPro
        density='compact'
        treeData
        getTreeDataPath={(row) => row.hierarchy}
        autoHeight
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row?.id}
        disableSelectionOnClick
        disableRowSelectionOnClick
        groupingColDef={{
          headerName: '',
          width: 50,
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
        }}
        pagination
        pageSizeOptions={[15]}
        initialState={{
          ...rows?.initialState,
          pagination: { paginationModel: { pageSize: 15 } }
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            height: 'calc(100vh - 8px - 8px - 40px - 32px - 53px)',
          },
          [`.${gridClasses.cell}, .${gridClasses.cellContent}, .${gridClasses.columnHeaderTitle}`]: {
            fontSize: '12px'
          },
          [`.${gridClasses.columnHeaders}`]: {
            fontSize: '12px',
            position: 'sticky',
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            top: 0,
            minHeight: '60px !important'
          },
          [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
            color: '#74788d',
            fontWeight: 600
          },
          [`.${gridClasses.virtualScroller}`]: {
            overflowY: 'auto !important',
            scrollbarGutter: 'stable',
            scrollbarWidth: 'none',
            marginTop: 'auto !important'
          }
        })}
      />
  )
}

export default ViewTradeDetails