import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { TryOutlined } from '@mui/icons-material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box, Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tooltip,
  Typography
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { CSVLink } from 'react-csv'
import { ErrorBoundary } from 'react-error-boundary'
import { HtmlTooltip } from '../../components/CustomTooltip'
import StrategyTitle from '../../components/StrategyTitle'
import { useAuth } from '../../contexts/AuthContext'
import { moduleConfig } from '../../contexts/data'
import { ACCESS_LEVEL, MODEL_WEIGHT_DISPLAY_MODULES } from '../../contstants/constants'
import useDownloadLogs from '../../hooks/useDownloadLogs'
import { useErrorToast } from '../../hooks/useErrorToast'
import '../../pages/AccountOnboarding/AccountOnboarding.scss'
import '../../pages/AccountReview/import-portfolio.scss'
import ErrorFallback from '../ErrorFallback'
import Loader from '../Loader'
import { CurrentImport } from './CurrentStratgeyPortfolio'
import ManageRestriction from './ManageRestriction'
import StrategyImport from './StrategyImportPortfolio'
import StrategyPlaceholder from './StrategyPlaceholder'
import './strategyPortfolio.scss'

const currentPortfolioFormatHeaders = [
  { label: 'Name', key: 'instrName' },
  { label: 'Symbol', key: 'localSymbol' },
  { label: 'Weight(%)', key: 'weight' },
  { label: 'ISO Country Code', key: 'isoCountryName' }
]

const StrategyImportPage = () => {
  const { user, userAclData, refreshACL } = useAuth()
  const [taxlotData, setTaxlotData] = useState()
  const [noDataMsg, setNoDataMsg] = useState()
  const [downloadTaxlotData, setDownloadTaxlotData] = useState()
  const [currentTaxlotLoading, setCurrentTaxlotLoading] = useState(false)
  const [isDynamicPortfolioLoading, setIsDynamicPortfolioLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('current-portfolio')
  const { checkAccess } = useAuth()
  const params = useParams()
  const { showError } = useErrorToast()
  const [strategyInfo, setStrategyInfo] = useState(null)
  const [strategyRestrictionInfo, setStrategyRestrictionInfo] = useState(null)
  const [strategyInfoLoading, setStrategyInfoLoading] = useState(true)
  const [strategyRestrictionInfoLoading, setStrategyRestrictionInfoLoading] = useState(true)
  const [configModel, setConfigModel] = useState([])
  const [isConfigApiLoading, setIsConfigApiLoading] = useState(false)
  const [showAdditionalMessagePopup, setShowAdditionalMessagePopup] = useState(false)
  const [isCountrySearchLoading, setIsCountrySearchLoading] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [isSamplePortfolioFetching, setIsSamplePortfolioFetching] = useState(false)
  const [samplePortfolioData, setSamplePortfolioData] = useState({})
  const [file, setFile] = useState(null)
  const [fileSize, setFileSize] = useState(null)
  const [additionalMessage, setAdditionalMessage] = useState('')
  const [driftSwitch, setDriftSwitch] = useState(false)
  const [isDownloadingPortfolio, setIsDownloadingPortfolio] = useState(false)
  const { createCSVDownloadLog } = useDownloadLogs()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const currentPortfolioAccess = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.CURRENT_STRATEGY_PORTFOLIO })
  const uploadPortfolioAccess = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.UPLOAD_STRATEGY_PORTFOLIO })
  const manageRestrictionAccess = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.MANAGE_RESTRICTION })
  const dynamicPortfolioAccess = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS,
    { subModuleName: moduleConfig.STRATEGY_PORTFOLIO, component_name: moduleConfig.DYNAMIC_PORTFOLIO })

  useEffect(() => {
    if (user) {
      getStrategyData()
      getStrategyConfigData()
      if (currentPortfolioAccess || uploadPortfolioAccess) {
        getCurrentTaxlotData()
      }
      if (uploadPortfolioAccess) {
        fetchISOCountryCodeList()
      }
      if (uploadPortfolioAccess) {
        getSamplePortfolio()
      }
      if (manageRestrictionAccess) {
        getStrategyRestrictionData()
      }
    }
  }, [user])

  useEffect(() => {
    // set current value as current-portfolio if user only has current-portfolio tab access
    currentPortfolioAccess ? setCurrentTab('current-portfolio')
      // set current value as upload-portfolio if user only has upload-portfolio tab access
      : uploadPortfolioAccess ? setCurrentTab('upload-portfolio')
        // set current value as default useEffect value if both access are false or both are true
        : setCurrentTab(currentTab)
  }, [])

  const downloadFileFromBuffer = (data, filename) => {
    // Create a Blob object from the data array
    // Specify the type of the Blob based on configModel or default to 'text/csv'
    const blob = new Blob([data], { type: configModel ? configModel[0]?.contentType : 'text/csv' })
    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob)
    const fileLink = document.createElement('a')
    fileLink.href = url
    fileLink.download = filename
    document.body.appendChild(fileLink)
    fileLink.click()
    // Revoke the Blob URL to free up resources
    URL.revokeObjectURL(url)
    document.body.removeChild(fileLink)
  }

  const getStrategyConfigData = () => {
    setIsConfigApiLoading(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/s3-file-config/${params?.strategyId}`)
      .then((res) => {
        if (res && res?.success) {
          setConfigModel(res?.data)
          setIsConfigApiLoading(false)
        }
      }).catch((error) => {
        setIsConfigApiLoading(false)
        showError(error, false, {}, 'Failed to load strategy config data.')
      }).finally(() => {
        setIsConfigApiLoading(false)
      })
  }

  const downloadPortfolioImportFormat = async () => {
    try {
      if (downloadTaxlotData) {
        setIsDownloadingPortfolio(true)
        const response = await API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/models/${params?.strategyId}/download`)
        downloadFileFromBuffer(atob(response.data), `${strategyInfo?.strategyName || 'Current-Portfolio'}-Import-Format${configModel ? configModel[0]?.extension : '.csv'}`)
        createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.STRATEGY_IMPORT_FORMAT_PORTFOLIO, params?.strategyId, `${strategyInfo?.strategyName || 'Current-Portfolio'}-Import-Format${configModel ? configModel[0]?.extension : '.csv'}`, {
          batchId: (taxlotData?.length && taxlotData[0] && taxlotData[0].batchId) || ''
        })
        setIsDownloadingPortfolio(false)
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to download portfolio.')
    }
  }

  const getSamplePortfolio = () => {
    setIsSamplePortfolioFetching(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/models/${params?.strategyId}/sample`)
      .then((res) => {
        if (res && res?.data) {
          // create data grid column object format for each field of sample data
          setSamplePortfolioData({
            fields: res?.data?.fields?.length
              ? res?.data?.fields?.map(fieldObj => ({
                field: fieldObj,
                headerName: fieldObj,
                flex: 1
              }))
              : [],
            // create data grid row object format for each row of sample data
            data: res?.data?.data?.length ? res?.data?.data?.map(sampleRow => ({ ...sampleRow, id: randomId() })) : []
          })
          setIsSamplePortfolioFetching(false)
        }
      }).catch((error) => {
        setIsSamplePortfolioFetching(false)
        showError(error, false, {}, 'Failed to load sample portfolio.')
      }).finally(() => {
        setIsSamplePortfolioFetching(false)
      })
  }

  const currentPortfolioFormatCsvConfig = {
    filename: `${strategyInfo?.strategyName || 'Current-Portfolio'}.csv`,
    headers: currentPortfolioFormatHeaders,
    data: downloadTaxlotData || []
  }

  const openAdditionalMessagePopup = () => {
    setShowAdditionalMessagePopup(true)
  }

  const closeAdditionalMessagePopup = () => {
    setShowAdditionalMessagePopup(false)
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const getStrategyData = () => {
    setStrategyInfoLoading(true)
    API.get(
      'baseStrategyMaintainURL',
      `data-maintenance/v1/${user?.userGroup}/strategies/${params?.strategyId}/master`
    )
      .then((response) => {
        if (response?.data?.data?.length && response?.data?.data[0]) {
          setStrategyInfo(response?.data?.data[0])
          setStrategyInfoLoading(false)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load strategy data.')
        setStrategyInfoLoading(false)
      })
  }

  const getStrategyRestrictionData = () => {
    setStrategyRestrictionInfoLoading(true)
    API.get(
      'baseUriAccountOptimization',
      `account-customization/v1/${user?.userGroup}/active-manager/${params?.strategyId}/instr-restrictions`
    )
      .then((response) => {
        if (response?.data?.data) {
          const data = response?.data?.data?.map((item) => ({
            ...item,
            id: randomId()
          }))
          setStrategyRestrictionInfo(data)
          setStrategyRestrictionInfoLoading(false)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load strategy restriction data.')
        setStrategyRestrictionInfoLoading(false)
      })
      .finally(() => {
        setStrategyRestrictionInfoLoading(false)
      })
  }

  const getCurrentTaxlotData = async () => {
    setCurrentTaxlotLoading(true)
    const response = await API.get(
      'baseStrategyURL',
      `strategy/v1/${user?.userGroup}/strategies/models/${params?.strategyId}`
    )
    try {
      if (response.data.length === 0) {
        setCurrentTaxlotLoading(false)
        setNoDataMsg(response.message)
      }
      if (response.data) {
        setTaxlotData(response.data)
        setDownloadTaxlotData(response?.data?.map(portfolio => ({
          ...portfolio,
          weight: portfolio?.weight,
          holdingDate: portfolio?.holdingDate?.split('T')[0],
          CUSIP: '',
          ISIN: '',
          SEDOL: '',
          FIGI: ''
        })))
        setCurrentTaxlotLoading(false)
      }
    } catch (error) {
      setCurrentTaxlotLoading(false)
      showError(error, false, {}, 'Failed to load strategy portfolio.')
    }
  }

  const fetchISOCountryCodeList = () => {
    API.get('baseUriTransactionalMaster', `transactional-master/v1/${user.userGroup}/iso-country-codes`)
      .then((response) => {
        if (response?.data) {
          setCountryOptions(response?.data)
          setIsCountrySearchLoading(false)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load country code.')
      })
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // switch tab to current portfolio
  const switchToCurrentPortfolio = () => {
    setCurrentTab('current-portfolio')
  }

  // PATCH API request when the Dynamic Portfolio switch changes
  const handleDriftSwitchChange = (event) => {
    setDriftSwitch(!driftSwitch)
    const updatedDynamicSwitch = !driftSwitch
    setIsDynamicPortfolioLoading(true)
    API.patch('baseStrategyURL', `strategy/v1/${user?.userGroup}/dynamic-strategy`,
      {
        body: {
          dynamicStrategy: updatedDynamicSwitch
        }
      })
      .then(response => {
        if (response) {
          getCurrentTaxlotData()
          refreshACL(user?.sub, true)
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to update drifted portfolio mode.')
      })
      .finally(() => {
        setIsDynamicPortfolioLoading(false)
      })
  }

  function convertToCsv (data) {
    const customColumns = ['Strategy Name', 'Strategy Code', 'User Id', 'User Name', 'Data Definition', 'Download Date (UTC timezone)', 'Data Downloaded']

    const headerData = customColumns.join(',') + '\n'

    const rowValues = data?.map(obj => Object.values(obj).join(',')).join('\n')

    return headerData + rowValues
  }

  const getLogData = () => {
    setIsDownloadingPortfolio(true)
    API.get('baseDataValidation', `data-validation/v1/${user?.userGroup}/data/download/log?data-id=${params?.strategyId}`)
      .then((res) => {
        if (res?.data) {
          const originalData = res?.data?.map((originalRow) => {
            return {
              strategyName: strategyInfo?.strategyName,
              strategyCode: strategyInfo?.strategyCd,
              faId: originalRow?.faId || '',
              faName: originalRow?.faName || '',
              dataDefinition: originalRow?.dataDefinition,
              downloadDate: originalRow?.downloadDate,
              dataDownloaded: originalRow?.dataDownloaded
            }
          })
          const csvData = convertToCsv(originalData)
          const blob = new Blob([csvData], { type: 'text/csv' })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Downloaded-Log.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          showError(res?.message, false, {}, 'Failed to load download log data.')
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load download log data.')
      })
      .finally(() => {
        setIsDownloadingPortfolio(false)
      })
  }

  const getRawStrategyPortfolio = async () => {
    setIsDownloadingPortfolio(true)
    API.get('baseStrategyURL', `strategy/v1/${user?.userGroup}/strategies/raw-portfolio/${params?.strategyId}`)
      .then((res) => {
        if (res?.data?.presignedUrl) {
          fetch(res.data?.presignedUrl)
            .then(async (response) => {
              const fileName = res?.data?.presignedUrl.split('?')[0].split('/').pop()
              const blob = await response?.blob()
              const linkTag = document.createElement('a')
              const url = URL.createObjectURL(blob)
              linkTag.href = url
              linkTag.download = fileName
              document.body.appendChild(linkTag)
              linkTag.click()
              document.body.removeChild(linkTag)
              URL.revokeObjectURL(url)
              createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.STRATEGY_RAW_PORTFOLIO, params?.strategyId, fileName, {
                fileName
              })
            })
        } else {
          showError(res?.message, false, {}, 'Failed to download raw strategy portfolio.')
        }
      })
      .catch(error => {
        showError(error, false, {}, 'Failed to load raw portfolios.')
      })
      .finally(() => {
        setIsDownloadingPortfolio(false)
      })
  }

  // Get the Switch status from ACL
  useEffect(() => {
    const strategyPortfolio = userAclData?.strategyIds?.find(
      strategy => strategy.resourceId === 'strategy-portfolio'
    )
    if (strategyPortfolio && strategyPortfolio?.dynamicStrategy) {
      setDriftSwitch(true)
    }
  }, [userAclData])

  const logReport = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.STRATEGY_PORTFOLIO,
    component_name: moduleConfig.EXPORT_DOWNLOAD_LOG_REPORT
  })

  const rawPortfolio = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.STRATEGY_PORTFOLIO,
    component_name: moduleConfig.DOWNLOAD_RAW_STRATEGY_PORTFOLIO
  })

  const currentStrategyPortfolio = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.STRATEGY_PORTFOLIO,
    component_name: moduleConfig.DOWNLOAD_CURRENT_STRATEGY_PORTFOLIO
  })

  const importFormatPortfolio = checkAccess(moduleConfig.MODEL, ACCESS_LEVEL.COMPONENT_ACCESS, {
    subModuleName: moduleConfig.STRATEGY_PORTFOLIO,
    component_name: moduleConfig.DOWNLOAD_PORTFOLIO_IMPORT_FORMAT
  })

  const handleSecurityRestrictionData = (data) => {
    setStrategyRestrictionInfo([...data])
  }

  const screen = 'not main'
  return (
    <>
      <Box className='strategy-portfolio'>
        {isDynamicPortfolioLoading || isDownloadingPortfolio ? <Loader /> : ''}
        <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
          <StrategyTitle title='Strategy' strategyName={strategyInfo?.strategyName} strategyCode={strategyInfo?.strategyCd} infoLoading={strategyInfoLoading} />
        </ErrorBoundary>
        <TabContext value={currentTab}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <TabList
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons={false}
              id='portfolio-tabs'
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#34475A'
                }
              }}
              indicatorColor='#34475A'
            >
              {
                currentPortfolioAccess
                  ? (
                    <Tab
                      className='tab-header' value='current-portfolio' label='Current Portfolio'
                    />
                    )
                  : null
              }
              {
                uploadPortfolioAccess
                  ? (
                    <Tab
                      className='tab-header' value='upload-portfolio' label='Upload Portfolio'
                    />
                    )
                  : null
              }
              {
                manageRestrictionAccess && strategyInfo?.strTypeCode === 'MANAGED'
                  ? (
                    <Tab
                      className='tab-header' value='manage-restriction' label='Manage Restriction'
                    />
                    )
                  : null
              }
            </TabList>
            {dynamicPortfolioAccess && strategyInfo?.strTypeCode === 'MANAGED'
              ? <Box sx={{ display: 'flex', alignItems: 'center', mr: '12px', columnGap: 1 }}>
                <HtmlTooltip title={'Toggle the switch for dynamic portfolio view. The dynamic portfolio updates daily based on security\'s total return.'}>
                  <FormControlLabel
                    labelPlacement='start'
                    control={
                      <Switch
                        checked={driftSwitch}
                        onChange={handleDriftSwitchChange}
                        name='switchName'
                        sx={{
                          '& .MuiSwitch-switchBase:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                      />
                  }
                    label='Dynamic Portfolio'
                    componentsProps={{
                      typography: {
                        sx: {
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#34475A'
                        }
                      }
                    }}
                  />
                </HtmlTooltip>
              </Box>
              : null}
          </Box>
          <Divider sx={{
            bgcolor: 'rgba(116, 120, 141, 0.15)'
          }}
          />
          {
            currentPortfolioAccess
              ? (
                <TabPanel
                  sx={{
                    p: 0
                  }}
                  value='current-portfolio'
                >
                  <Box>
                    <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} onReset={() => window.location.reload()} />)}>
                      <Grid container spacing={2} mb={6}>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Typography variant='h5' sx={{ fontWeight: '600', color: '#212529', fontFamily: 'Open Sans', display: 'flex', alignItems: 'center', width: '100%' }}>
                              <>
                                <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>
                                  <Box display='flex' alignItems='center' gap={1}>
                                    <Typography sx={{ color: '#74788D', fontWeight: 600, fontSize: '14px', whiteSpace: 'nowrap' }}>
                                      Update Date: <span style={{ color: '#34475A' }}>
                                        {taxlotData && taxlotData.length > 0 && taxlotData[0]?.insTs ? formatDate(taxlotData[0].insTs) : 'N/A'}
                                      </span>
                                    </Typography>
                                    <Typography sx={{ color: '#74788D', fontWeight: 600, fontSize: '14px', whiteSpace: 'nowrap' }}>
                                      Updated by: <span style={{ color: '#34475A' }}>
                                        {taxlotData && taxlotData.length > 0 && taxlotData[0]?.lastUpdatedBy === 'SYSTEM' ? 'ETL Process' : (taxlotData && taxlotData.length > 0 && taxlotData[0]?.lastUpdatedBy || 'N/A')}
                                      </span>
                                    </Typography>
                                    {taxlotData && taxlotData.length > 0 && taxlotData[0]?.additionalText
                                      ? (
                                        <Tooltip title='Additional Message'>
                                          <TryOutlined sx={{ fontSize: '16px', cursor: 'pointer' }} onClick={openAdditionalMessagePopup} />
                                        </Tooltip>
                                        )
                                      : null}
                                  </Box>
                                  <Box className='strategy-download-button' sx={{ visibility: currentTaxlotLoading ? 'hidden' : 'visible' }}>
                                    {(importFormatPortfolio || currentStrategyPortfolio || rawPortfolio || logReport) && (
                                      <>
                                        <Button
                                          sx={{ textTransform: 'none' }}
                                          onClick={handleMenuOpen}
                                          startIcon={<FileDownloadOutlinedIcon sx={{ color: '#3A76D4', fontSize: '16px' }} />}
                                        >
                                          Export CSV
                                        </Button>

                                        <Menu
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleMenuClose}
                                          elevation={4}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                          }}
                                        >
                                          {rawPortfolio && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose()
                                                getRawStrategyPortfolio()
                                              }}
                                              disabled={isConfigApiLoading}
                                            >
                                              Raw Portfolio
                                            </MenuItem>
                                          )}

                                          {currentStrategyPortfolio && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose()
                                                createCSVDownloadLog(
                                                  MODEL_WEIGHT_DISPLAY_MODULES.STRATEGY_CURRENT_PORTFOLIO,
                                                  params?.strategyId,
                                                  currentPortfolioFormatCsvConfig.filename,
                                                  {
                                                    batchId: (taxlotData?.length && taxlotData[0] && taxlotData[0].batchId) || ''
                                                  }
                                                )
                                              }}
                                            >
                                              <CSVLink {...currentPortfolioFormatCsvConfig} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                Current Strategy Portfolio
                                              </CSVLink>
                                            </MenuItem>
                                          )}

                                          {importFormatPortfolio && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose()
                                                downloadPortfolioImportFormat()
                                              }}
                                              disabled={isConfigApiLoading}
                                            >
                                              Portfolio In Import Format
                                            </MenuItem>
                                          )}

                                          {logReport && (
                                            <MenuItem
                                              onClick={() => {
                                                handleMenuClose()
                                                getLogData()
                                              }}
                                              disabled={isConfigApiLoading}
                                            >
                                              Downloaded Log Report
                                            </MenuItem>
                                          )}
                                        </Menu>
                                      </>
                                    )}
                                  </Box>
                                </Box>

                              </>
                            </Typography>
                          </Box>
                          <Box>
                            {taxlotData?.length === 0 || taxlotData === undefined || currentTaxlotLoading
                              ? (
                                <>
                                  <Grid container my={0}>
                                    <Grid item xs={12}>
                                      <Card className='card-layout'>
                                        <CardContent>
                                          <Box className='table-responsive-import'>
                                            {currentTaxlotLoading
                                              ? (
                                                <StrategyPlaceholder />
                                                )
                                              : (
                                                <Typography style={{ minHeight: '30px' }}>{noDataMsg}</Typography>
                                                )}
                                          </Box>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                </>
                                )
                              : (
                                <>
                                  <CurrentImport taxlotData={taxlotData || []} />
                                </>
                                )}
                          </Box>
                        </Grid>
                      </Grid>
                    </ErrorBoundary>
                  </Box>
                </TabPanel>
                )
              : null
          }
          {
            uploadPortfolioAccess
              ? (
                <TabPanel
                  sx={{
                    px: 0,
                    py: 1,
                    position: 'relative'
                  }}
                  value='upload-portfolio'
                >
                  <StrategyImport getCurrentTaxlotData={getCurrentTaxlotData} configModel={configModel} loading={isConfigApiLoading} downloadFileFromBuffer={downloadFileFromBuffer} switchToCurrentPortfolio={switchToCurrentPortfolio} countryOptions={countryOptions} isCountrySearchLoading={isCountrySearchLoading} samplePortfolioData={samplePortfolioData} isSamplePortfolioFetching={isSamplePortfolioFetching} file={file} setFile={setFile} additionalMessage={additionalMessage} setAdditionalMessage={setAdditionalMessage} fileSize={fileSize} setFileSize={setFileSize} hasDynamicPortfolioAccess={dynamicPortfolioAccess && strategyInfo?.strTypeCode === 'MANAGED'} />
                </TabPanel>
                )
              : null
          }
          {
            manageRestrictionAccess && strategyInfo?.strTypeCode === 'MANAGED'
              ? (
                <TabPanel
                  sx={{
                    px: 0,
                    py: 1,
                    position: 'relative'
                  }}
                  value='manage-restriction'
                >
                  <ManageRestriction strategyRestrictionInfo={strategyRestrictionInfo} loading={strategyRestrictionInfoLoading} onRestrictionUpdate={handleSecurityRestrictionData} />
                </TabPanel>
                )
              : null
          }
        </TabContext>
        <Dialog
          open={showAdditionalMessagePopup}
          onClose={closeAdditionalMessagePopup}
        >
          <DialogTitle>Additional Message</DialogTitle>
          <DialogContent>
            <Box className='strategy-message-area'>
              <Box className='rsw-editor rsw-ce' width={500} maxHeight='80vh' dangerouslySetInnerHTML={{ __html: (taxlotData && taxlotData[0] && taxlotData[0]?.additionalText) || '' }} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAdditionalMessagePopup}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default StrategyImportPage
