import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip'

export const HtmlTooltip = styled(({ className, tooltipStyle = {}, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, tooltipStyle }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    padding: '16px',
    boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
    borderRadius: '10px',
    ...tooltipStyle
  }
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    position: absolute;
    width: max-content;
    margin-left: -20px;
  }
`
