import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { StyledTooltip } from '../../../components/CustomTooltip'
import { MODEL_WEIGHT_DISPLAY_MODULES } from '../../../contstants/constants'
import useDownloadLogs from '../../../hooks/useDownloadLogs'

const TopSecurity = ({ data }) => {
  const apiRef = useGridApiRef()
  const params = useParams()
  const { createCSVDownloadLog } = useDownloadLogs()
  const [showModal, setShowModal] = useState(false)
  const [topSecurityRow, setTopSecurityRow] = useState([])

  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  const downloadCsv = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: 'All-Model-Positions',
        allColumns: true
      })
      createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.DASHBOARD_TOP_MODEL_POSITIONS, params?.accountId, 'All-Model-Positions.csv')
    }
  }

  useEffect(() => {
    setTopSecurityRow(data
      ? data.sort((a, b) => {
        return b.weight - a.weight
      })?.map((item) => ({ id: randomId(), ...item }))
      : [])
  }, [data])

  const getRowId = (row) => row?.id

  const columns = [
    {
      field: 'security',
      headerName: 'Security',
      flex: 1,
      valueGetter: (params) => (params?.row?.localSymbol || params?.row?.instrId || 'N/A')
    },
    {
      field: 'name',
      headerName: 'Security Name',
      width: 0,
      hideable: true,
      valueGetter: (params) => (params?.row?.name || '')
    },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    }
  ]

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            component='p'
            className='text-title header-text'
          >
            Top Model Positions
          </Typography>
          {data?.length > 0 && (
            <IconButton
              onClick={openModal}
              className='tooltip-trade'
            >
              <img src={redirect} alt='' />
              <span
                className='tooltiptext'
                style={{ minWidth: '80px', marginLeft: '-20px' }}
              >
                All Model Positions
              </span>
            </IconButton>
          )}
        </Box>
        <TableContainer mt={5}>
          <Table className='risk-page-table'>
            <TableHead>
              <TableRow>
                <TableCell>Security</TableCell>
                <TableCell align='right'>Weight (%)</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0
                ? (
                  topSecurityRow?.slice(0, 5).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <StyledTooltip title={item?.name || 'N/A'}>
                          <span>{item?.localSymbol || 'N/A'}</span>
                        </StyledTooltip>
                      </TableCell>
                      <TableCell align='right'>
                        {item.weight
                          ? (Number(item.weight) * 100).toFixed(2).toString()
                          : '0.00'}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    ))
                  )
                : (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                      No Rows
                    </TableCell>
                  </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          maxWidth='md'
          open={showModal}
          onClose={() => closeModal()}
        >
          <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 0 }}>
            <Typography
              variant='h6'
              textAlign='center'
              color='#34475A'
              fontWeight={400}
            >
              All Model Positions
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              {data?.length > 0 && (
                <Button
                  variant='text'
                  color='primary'
                  onClick={downloadCsv}
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Export as CSV
                </Button>
              )}
              <IconButton
                aria-label='close'
                onClick={closeModal}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {topSecurityRow?.length > 0
              ? (
                <Box style={{
                  maxHeight: 650,
                  width: '100%',
                  minWidth: '600px'
                }}
                >
                  <DataGridPro
                    columns={columns}
                    rows={topSecurityRow}
                    apiRef={apiRef}
                    disableRowSelectionOnClick
                    getRowId={getRowId}
                    // Hide column 'name'
                    columnVisibilityModel={{
                      name: false
                    }}
                    pagination
                    pageSizeOptions={[10]}
                    initialState={{
                      ...topSecurityRow?.initialState,
                      pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    autoHeight
                    sx={(theme) => ({
                      [`.${gridClasses.main}`]: {
                        overflow: 'unset'
                      },
                      [`.${gridClasses.columnHeaders}`]: {
                        position: 'sticky',
                        top: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      },
                      [`.${gridClasses.footerContainer}`]: {
                        position: 'sticky',
                        bottom: -20,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      },
                      [`.${gridClasses.toolbarContainer}`]: {
                        position: 'relative',
                        top: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      }
                    })}
                  />
                </Box>
                )
              : (
                <Typography variant='body1' textAlign='center'>
                  No Rows
                </Typography>
                )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default TopSecurity
