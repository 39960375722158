import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Box, Grid, Skeleton, Tab, Typography, styled } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { useAuth } from '../../contexts/AuthContext'
import { useErrorToast } from '../../hooks/useErrorToast'
import ErrorFallback from '../ErrorFallback'
import DataMetricsTab from './components/DataMetricsTab'
import ProcessMetricsTab from './components/ProcessMetricsTab'
import './process-monitoring.css'

const processMonitoringTabs = ['Data Metrics', 'Process Metrics']

const ProcessMonitoring = () => {
  const [activeTab, setActiveTab] = useState(processMonitoringTabs[0])
  const screen = 'not main'
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [metricsData, setMetricsData] = useState({})
  const [monitoringDate, setMonitoringDate] = useState('')
  const [monitoringMessage, setMonitoringMessage] = useState('')

  const handleMonitoringTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(() => {
    if (user) {
      getMonitoringData()
    }
  }, [user])

  const getMonitoringData = async () => {
    setIsLoading(true)
    try {
      const response = await API.get(
        'baseUriMonitoring',
        `monitoring/v1/${user?.userGroup}/process-monitor-data`
      )
      if (response && response.data) {
        setMonitoringDate(response?.data?.date || '')
        setMetricsData(response?.data?.metricsData || {})
        setMonitoringMessage(response?.data?.message || '')
      }
    } catch (error) {
      showError(error, false, {}, 'Failed to load monitoring data.')
    } finally {
      setIsLoading(false)
    }
  }

  const renderTabPanel = (tabName) => {
    if (!Object.keys(metricsData).length) {
      return 'No data found'
    }
    switch (tabName) {
      case 'Data Metrics':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <DataMetricsTab data={metricsData.dataMetrics || []} />
          </ErrorBoundary>
        )
      case 'Process Metrics':
        return (
          <ErrorBoundary fallbackRender={(props) => (<ErrorFallback {...props} screen={screen} />)}>
            <ProcessMetricsTab data={metricsData.processMetrics || []} />
          </ErrorBoundary>
        )
      default:
        return 'Something went wrong'
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }} className='process-monitoring-page'>
        <Box sx={{ padding: '0 9px 15px' }}>
          <Typography
            sx={{
              color: '#34475A',
              fontFamily: 'Lora',
              fontWeight: 400,
              fontSize: '26px',
              marginTop: '0px'
            }}
          >
            Process Monitoring
          </Typography>
        </Box>
        <Box sx={{ position: 'relative' }}>
          {monitoringDate
            ? (
              <Alert
                severity='info'
                sx={{
                  position: 'absolute',
                  right: '0px',
                  zIndex: 98,
                  '& .MuiAlert-icon': {
                    padding: '3px 0'
                  },
                  '& .MuiAlert-message': {
                    padding: '3px 0'
                  }
                }}
              >{monitoringMessage} - {monitoringDate}
              </Alert>
              )
            : ''}
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleMonitoringTabChange} aria-label='process-monitoring' TabIndicatorProps={{
                  style: {
                    backgroundColor: '#34475A'
                  }
                }}
              >
                {processMonitoringTabs.map((item, index) => (
                  <Tab key={index} value={item} label={item} />
                ))}
              </TabList>
            </Box>
            {processMonitoringTabs.map((item, index) => (
              <TabPanel
                key={index}
                value={item}
                sx={{ paddingX: '0px' }}
              >
                {/* show skeleton while data is loading  */}
                {isLoading
                  ? (
                    <Grid container>
                      {[...Array(4)]?.map((e, i) => (
                        <Grid item xs={12} key={i}>
                          <Skeleton height={70} width='100%' />
                        </Grid>
                      ))}
                    </Grid>
                    )
                  : renderTabPanel(item)}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Box>
    </>
  )
}

export default ProcessMonitoring
