import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import dayjs, { utc } from 'dayjs'
import { FilterAlt, FilterAltOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogTitle, Divider, FormControlLabel, IconButton, Popover, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import CustomEditDate from '../../../components/CustomEditDate'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import Loader from '../../Loader'
import CreateWashSaleRestrictionForm from './CreateWashSaleRestrictionForm'
import FileUploadToolbar from './FileUploadToolBar'

dayjs.extend(utc)

const WashSalesRestrictionTable = (props) => {
  const { data, setData, loading, srcAccNames, sourceAccountsList, restrictionPaginationModel, setSrcAccNames, selectedGroup, setRestrictionPaginationModel, filterModel, setFilterModel } = props
  const [rows, setRows] = useState(data.filter(obj => (filterModel?.length > 0 ? filterModel.includes(obj?.sourceAccName) : true)))
  const { user } = useAuth()
  const { showSuccess } = useSuccessToast()
  const params = useParams()
  const { showError } = useErrorToast()
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(null)
  const [rowModesModel, setRowModesModel] = useState({})
  const [selectedRow, setSelectedRow] = useState(null)
  const [createRestrictionPopUpOpen, setCreateRestrictionPopUpOpen] = useState(false)
  const [isRestrictionUpdateApiLoading, setIsRestrictionUpdateApiLoading] = useState(false)
  const apiRef = useGridApiRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedRowIds, setSelectedRows] = useState([])

  useEffect(() => {
    if (data) {
      setRows(data.filter(obj => (
        filterModel?.length > 0
          ? filterModel.includes(obj?.sourceAccName)
          : true
      )))
    }
  }, [data, filterModel])

  const groupRestrictionColumns = [
    {
      field: 'instrId',
      headerName: 'Instrument Id',
      flex: 1
    },
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1
    },
    {
      field: 'instrName',
      headerName: 'Instrument Name',
      flex: 1
    },
    {
      field: 'restrictionDesc',
      headerName: 'Restriction',
      flex: 1
    },
    {
      field: 'sourceAccName',
      headerName: 'Source Account Name',
      flex: 2,
      editable: false,
      renderCell: (params) => {
        return params?.value || 'N/A'
      }
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      type: 'date',
      valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : ''
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      type: 'date',
      valueFormatter: (params) => params.value ? dayjs.utc(params.value).format('ddd MMM DD YYYY') : '',
      renderEditCell: (params) => <CustomEditDate {...params} minDate={dayjs.utc().format('YYYY-MM-DD')} />,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params?.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={params.id}
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleRowSaveClick(params)}
            />,
            <GridActionsCellItem
              key={params.id}
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleRowCancelClick(params?.id)}
              color='inherit'
            />
          ]
        }

        return [
          <GridActionsCellItem
            key={params.id}
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(params?.id)}
            color='inherit'
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(params)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleCancelClick = () => {
    setCreateRestrictionPopUpOpen(false)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id) => () => {
    // If the clicked row is in selectedRowIds, update all selected rows to edit mode
    const isRowChecked = selectedRowIds.includes(id)
    if (isRowChecked) {
      const newRowModes = {}
      selectedRowIds.forEach((selectedId) => {
        newRowModes[selectedId] = { mode: GridRowModes.Edit }
      })
      setRowModesModel({ ...rowModesModel, ...newRowModes })
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }
  }

  const handleRowSaveClick = (props) => () => {
    setIsRestrictionUpdateApiLoading(true)
    const rowToSave = apiRef.current.getRowWithUpdatedValues(props.id)
    const isRowChecked = selectedRowIds.includes(props.id)
    let rowsToSave = []
    if (isRowChecked) {
      // If checked, save all selected rows
      rowsToSave = selectedRowIds.map(id => apiRef.current.getRowWithUpdatedValues(id))
    } else {
      rowsToSave = [rowToSave]
    }
    // Filter out rows that were not modified
    rowsToSave = rowsToSave.filter(row => {
      const originalRow = rows.find(r => r.id === row.id)
      return row.endDate !== originalRow.endDate
    })

    if (rowsToSave.length === 0) {
      setIsRestrictionUpdateApiLoading(false)
      return
    }

    const payload = rowsToSave.map(updatedRow => ({
      isExternal: updatedRow?.isExternal === 1,
      ...(updatedRow?.isExternal === 1
        ? { aggGroupInstrRestrId: updatedRow?.aggGroupInstrRestrId }
        : { accountInstrRestrId: updatedRow?.accountInstrRestrId }),
      ...(updatedRow?.endDate ? { endDate: dayjs.utc(updatedRow?.endDate).format('YYYY-MM-DD') } : {})
    })).filter(row => row.endDate)

    if (payload.length === 0) {
      setIsRestrictionUpdateApiLoading(false)
      return
    }

    API.patch('baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/restrictions/${params?.aggGroupId}`, {
      body: payload
    }).then((response) => {
      if (response?.success) {
        // find the matching row in response and if found then replace the field from response
        const updatedRows = rows?.map(row => {
          const index = response?.data?.findIndex(res => res.isExternal ? row.aggGroupInstrRestrId === res.aggGroupInstrRestrId : row.accountInstrRestrId === res.accountInstrRestrId)
          if (index > -1) {
            return { ...row, ...response?.data[index] }
          } else {
            return row
          }
        })
        setRows(updatedRows)
        setData(updatedRows)
        showSuccess(response?.message)
        if (selectedRowIds.length && selectedRowIds.includes(props.id)) {
          const obj = {}
          updatedRows.forEach((row) => {
            if (selectedRowIds.includes(row.id) || row.id === props.id) {
              obj[row.id] = { mode: GridRowModes.View }
            }
          })
          setRowModesModel({ ...rowModesModel, ...obj })
        } else {
          setRowModesModel({ ...rowModesModel, [props.id]: { mode: GridRowModes.View } })
        }
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to update restriction.')
    }).finally(() => setIsRestrictionUpdateApiLoading(false))
  }

  const handleDeleteClick = (params) => () => {
    const isRowChecked = selectedRowIds.includes(params.id)
    if (isRowChecked) {
      setSelectedRow(selectedRowIds.map(id => rows.find(row => row.id === id)))
    } else {
      setSelectedRow([params?.row])
    }
    setOpenConfirmationDialog('delete-restriction')
  }

  const handleRowCancelClick = (id) => () => {
    const isRowChecked = selectedRowIds.includes(id)
    if (isRowChecked) {
      // If the clicked row is in selectedRowIds, cancel changes for all selected rows
      selectedRowIds.forEach((selectedId) => {
        setRowModesModel((prevState) => ({
          ...prevState,
          [selectedId]: { mode: GridRowModes.View, ignoreModifications: true }
        }))
      })
    } else {
      // If the clicked row is not in selectedRowIds, cancel only for that row
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      })
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const createRestriction = (values, setRows, setCreateApiLoading, params) => {
    const requestBody = {
      accountCd: values?.accoundCd,
      instrId: values?.instrId,
      restrictionId: values?.restrictionId,
      sourceAccountCd: values?.sourceAccountCd,
      isExternal: values?.isExternalRestriction,
      startDate: values?.startDate,
      ...(values?.endDate ? { endDate: values?.endDate } : {})
    }

    API.post('baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/restrictions/${params?.aggGroupId}`, {
      body: [requestBody]
    }).then((data) => {
      if (data && data?.data && data?.data?.length && data.success) {
        setCreateRestrictionPopUpOpen(false)
        const newRow = {
          id: randomId(),
          ...(data?.data[0] ? data?.data[0] : values),
          isNew: false
        }
        setRows((oldRows) => [newRow, ...oldRows])
        setData((oldRows) => [newRow, ...oldRows])
        setSrcAccNames((prevSet) => [...new Set([...prevSet, newRow?.sourceAccName])])
        showSuccess(data?.message)
      }
    }).catch((error) => {
      showError(error, false, {}, 'Failed to create restriction.')
    }).finally(() => setCreateApiLoading(false))
  }

  const handleConfirmAction = (e, id, setIsConfirmApiLoading, params) => {
    if (e.target.innerText === 'CONFIRM') {
      setIsConfirmApiLoading(true)
      const selectedRowsArray = Array.isArray(selectedRow) ? selectedRow : [selectedRow]
      const payload = selectedRowsArray.map(row => ({
        isExternal: row?.isExternal === 1,
        ...(row?.isExternal === 1
          ? { aggGroupInstrRestrId: row?.aggGroupInstrRestrId }
          : { accountInstrRestrId: row?.accountInstrRestrId })
      }))
      API.del('baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/restrictions/${params?.aggGroupId}`, {
        body: payload
      })
        .then((res) => {
          if (res && res?.success) {
            const newRows = rows.filter(row => !selectedRowsArray.some(selected => selected.id === row.id))
            setRows(newRows)
            setData(newRows)
            setSrcAccNames([...new Set(newRows.map(row => row.sourceAccName))])
            showSuccess(res?.message)
          }
        }).catch((error) => {
          showError(error, false, {}, 'Failed to delete restriction.')
        }).finally(() => {
          setIsConfirmApiLoading(false)
          setOpenConfirmationDialog(null)
        })
    }
  }

  const handleCheckboxChange = (selectedOption, isChecked) => {
    setFilterModel(prevModel => {
      const currentAccounts = prevModel || []
      const updatedAccountList = isChecked
        ? [...currentAccounts, selectedOption]
        : currentAccounts?.filter(account => account !== selectedOption)
      return updatedAccountList
    })
  }

  function RestrictionToolbar (props) {
    const {
      setRows, params
    } = props
    const [createApiLoading, setCreateApiLoading] = useState(false)
    const [restrictionPreProcessApiLoading, setIsRestrictionPreProcessApiLoading] = useState(false)
    const fileInputRef = useRef(null)

    const handleCreateSaveClose = (values) => {
      createRestriction(values, setRows, setCreateApiLoading, params)
    }

    return (
      <>
        {restrictionPreProcessApiLoading ? <Loader /> : ''}
        <GridToolbarContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => setCreateRestrictionPopUpOpen(true)}
            >
              Add Restriction
            </Button>
            <Box sx={{ display: 'flex', columnGap: 1 }}>
              <FileUploadToolbar
                fileInputRef={fileInputRef}
                setSrcAccNames={setSrcAccNames}
                setIsRestrictionPreProcessApiLoading={setIsRestrictionPreProcessApiLoading}
                rows={rows}
                setRows={setRows}
                setData={setData}
              />
            </Box>
          </Box>
          <Dialog open={createRestrictionPopUpOpen} onClose={handleCancelClick} fullWidth maxWidth='sm'>
            <CreateWashSaleRestrictionForm
              handleCancelClick={handleCancelClick}
              sourceAccountsList={sourceAccountsList}
              selectedGroup={selectedGroup}
              savedData={handleCreateSaveClose}
              createApiLoading={createApiLoading}
              setCreateApiLoading={setCreateApiLoading}
            />
          </Dialog>
        </GridToolbarContainer>
      </>
    )
  }

  const ConfirmDeleteActionDialog = () => {
    const [isConfirmApiLoading, setIsConfirmApiLoading] = useState(false)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={Boolean(openConfirmationDialog)}
          onClose={() => setOpenConfirmationDialog(null)}
        >
          <DialogTitle>
            {Array.isArray(selectedRow) && selectedRow.length > 1
              ? `Are you sure you want to delete the ${selectedRow.length} selected rows?`
              : `Are you sure you want to delete ${selectedRow?.[0]?.instrName} restriction?`}
          </DialogTitle>
          <DialogActions sx={{ marginBottom: '5px' }}>
            {isConfirmApiLoading
              ? (
                <LoadingButton
                  loading
                  loadingPosition='start'
                  variant='outlined'
                  sx={{ width: '130px' }}
                >
                  Confirm
                </LoadingButton>
                )
              : (
                <Button variant='contained' onClick={(e) => handleConfirmAction(e, selectedRow?.id, setIsConfirmApiLoading, params)}>
                  Confirm
                </Button>
                )}
            <Button autoFocus onClick={() => setOpenConfirmationDialog(null)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const handleRowSelection = (selectedList) => {
    setSelectedRows(selectedList)
  }

  return (
    <>
      {isRestrictionUpdateApiLoading ? <Loader /> : ''}
      <Box
        sx={{ position: 'relative' }} className='aggregate-portfolio-table'
      >
        {
          rows && rows?.length
            ? (
              <>
                <Tooltip title='Filter By Source Name'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    sx={{
                      minWidth: 'auto',
                      border: '2px solid #dee2e6',
                      padding: '7px',
                      borderRadius: '10px',
                      position: 'absolute',
                      top: '4px',
                      right: '95px',
                      zIndex: 99,
                      color: 'rgb(52, 71, 90)',
                      marginRight: '0px',
                      textAlign: 'center',
                      ':hover': {
                        background: 'transparent',
                        border: '2px solid #dee2e6'
                      }
                    }}
                    className='tooltip-trade'
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    {
                filterModel?.length
                  ? <FilterAlt fontSize='small' />
                  : <FilterAltOutlined fontSize='small' />
              }
                  </IconButton>
                </Tooltip>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  disableScrollLock
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <Box sx={{ width: '300px', p: '8px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: '14px', color: '#34475A' }}>Add Filter</Typography>
                      <IconButton onClick={() => setAnchorEl(null)}>
                        <CancelIcon sx={{ height: '18px', width: '18px' }} />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', mt: '5px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          disableClearable
                          value={filterModel || []}
                          options={srcAccNames || []}
                          onChange={(e, value) => setFilterModel(value)}
                          size='small'
                          fullWidth
                          ListboxProps={{ sx: { fontSize: 14, py: 1 } }}
                          renderInput={(params) => <TextField {...params} label='Source Account' variant='outlined' />}
                          renderTags={() => null}
                        />
                        {filterModel?.map((selectedOption) => {
                          return (
                            <Box key={selectedOption} sx={{ mt: '4px', display: 'flex', alignItems: 'baseline' }}>
                              <FormControlLabel
                                sx={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  '& .MuiCheckbox-root': {
                                    padding: '2px 9px'
                                  },
                                  '.MuiFormControlLabel-label': {
                                    fontSize: '14px'
                                  }
                                }}
                                control={
                                  <Checkbox
                                    size='small'
                                    checked
                                    onChange={(e) => handleCheckboxChange(selectedOption, e.target.checked)}
                                    sx={{
                                      borderRadius: '10px'
                                    }}
                                  />
                              }
                                label={selectedOption}
                              />
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                    <Divider sx={{ mt: '10px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button onClick={() => setFilterModel(null)} sx={{ fontSize: '14px', textTransform: 'capitalize', color: '#74788D' }}>
                        Clear Filters
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </>
              )
            : (
              <></>
              )
        }
        {
      loading
        ? <TableContainer mt={5}>
          <Table className='risk-page-table'>
            <TableHead>
              <TableRow>
                {groupRestrictionColumns.map((item, index) => {
                  return (
                    <TableCell key={index}>{item.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  {Array.from({ length: groupRestrictionColumns.length }).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                    </TableCell>))}
                </TableRow>))}
            </TableBody>
          </Table>
          </TableContainer>
        : <DataGridPro
            density='compact'
            rows={rows}
            columns={groupRestrictionColumns}
            autoHeight
            checkboxSelection
            rowSelectionModel={selectedRowIds}
            onRowSelectionModelChange={handleRowSelection}
            getRowId={(row) => row.id}
            apiRef={apiRef}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No Restriction Found' }}
            hideFooter={rows?.length === 0}
            pageSizeOptions={[10, 15, 25, 50, 100]}
            onPaginationModelChange={(event) => {
              setRestrictionPaginationModel({ page: event.page, pageSize: event.pageSize })
            }}
            paginationModel={{ pageSize: restrictionPaginationModel.pageSize, page: restrictionPaginationModel.page }}
            pagination
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            slots={{ toolbar: RestrictionToolbar }}
            slotProps={{
              toolbar: {
                setRows, params
              }
            }}
            sx={(theme) => ({
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                color: '#74788d',
                fontWeight: 600
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                top: 0,
                zIndex: 3,
                backgroundColor: 'white'
              },
              [`.${gridClasses.footerContainer}`]: {
                position: 'sticky',
                bottom: 0,
                zIndex: 3,
                backgroundColor: 'white'
              }
            })}
          />
    }
      </Box>
      {
        openConfirmationDialog === 'delete-restriction'
          ? (
            <ConfirmDeleteActionDialog />
            )
          : ''
      }
    </>
  )
}

export default WashSalesRestrictionTable
