import React, { useContext, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import reviewedPolicy from '../../../assets/images/review-policy.svg'
import eyefill from '../../../assets/images/eye-fill.svg'
import { HtmlTooltip } from '../../../components/CustomTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { accountDataContext } from '../Monitoring/index'
import { IPSTable } from '../components/IPSTable'
import { useErrorToast } from '../../../hooks/useErrorToast'

const Account = (selectedPolicyRow) => {
  const { sendSentryError } = useErrorToast()
  const { accountData, setAccountData, showTradeData } = useContext(accountDataContext)
  const [selectedID, setSelectedID] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useAuth()

  const [rows, setRows] = useState((accountData?.rows || []).map((item, index) => ({
    ...item,
    pId: index + 1
  }
  )))

  const [columns, setColums] = useState([...(accountData?.columns || []), {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 100,
    align: 'center',
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title='Review'>
            <IconButton
              disabled={params.row.reviewerStatus !== 'Review Required' || user.userGroup !== 'pm'}
              onClick={() => {
                handleReviewClick(params)
              }}
              sx={{ opacity: (params.row.reviewerStatus !== 'Review Required' || user.userGroup !== 'pm') ? 0.4 : 1 }}
            >
              <img src={reviewedPolicy} alt='review-icon' />
            </IconButton>
          </Tooltip>
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Reason</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '12px' }} my={1}>{params.row.reason || ''}</Typography>
              </>
            }
          >
            <AssignmentLateOutlinedIcon />
          </HtmlTooltip>
          {user?.userGroup === 'pm'
            ? <Tooltip title='View Trade'>
              <IconButton
                disabled={params.row.reviewerStatus === 'Completed' || user?.userGroup !== 'pm'}
                onClick={() => {
                  handleViewTradeClick(params)
                }}
                sx={{ opacity: (params.row.reviewerStatus === 'Completed' || user?.userGroup !== 'pm') ? 0.4 : 1 }}
              >
                <img src={eyefill} alt='eyefill' />
              </IconButton>
            </Tooltip>
            : ''}
        </div>
      )
    }

  }])

  const [filteredRows, setFilteredRows] = useState([])
  const [openReviewPolicyDialog, setOpenReviewPolicyDialog] = useState(false)

  const handleReviewClick = (params) => {
    setSelectedID(params.row?.spnPolicyAccountStatusId)
    setOpenReviewPolicyDialog(true)
  }

  const handleViewTradeClick = (params) => {
    if (showTradeData) {
      showTradeData('', [params?.row?.accountCd])
    }
  }

  const handleFilterModelChange = (props) => {
    const { items } = props
    setFilteredRows(items)
  }

  const reviewPolicy = async () => {
    setIsSubmitting(true)
    await API
      .patch('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user?.userGroup}/ips/accounts/policy-status/${selectedID}`)
      .then(response => {
        if (response.success) {
          setSelectedID('')
          setAccountData(prevData => ({
            ...prevData,
            rows: [...prevData.rows.map(row => row.spnPolicyAccountStatusId === response.data?.spnPolicyAccountStatusId ? { ...row, reviewerStatus: response.data?.reviewerStatus } : row)]
          }))
        }
      })
      .catch(error => {
        sendSentryError(error)
      })
      .finally(() => handleClose())
  }

  useEffect(() => {
    setFilteredRows(selectedPolicyRow?.selectedPolicyRow?.reviewerStatus
      ? [
          {
            field: 'policyName',
            operatorValue: 'equals',
            value: selectedPolicyRow?.selectedPolicyRow?.policyName
          },
          {
            field: 'reviewerStatus',
            operatorValue: 'equals',
            value: selectedPolicyRow?.selectedPolicyRow?.reviewerStatus
          }
        ]
      : [{
          field: 'policyName',
          operatorValue: 'equals',
          value: selectedPolicyRow?.selectedPolicyRow?.policyName
        }])
  }, [selectedPolicyRow])

  useEffect(() => {
    setRows((accountData?.rows || []).map((item, index) => ({
      ...item,
      pId: index + 1
    }
    )))
  }, [accountData])

  const handleClose = () => {
    setOpenReviewPolicyDialog(false)
    setIsSubmitting(false)
  }

  return (
    <Box px={4} pb={3}>
      <IPSTable
        columns={columns}
        rows={rows}
        filterModel={{
          items: filteredRows
        }}
        onFilterModelChange={handleFilterModelChange}
      />
      <Dialog
        open={openReviewPolicyDialog}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText sx={{
            fontWeight: '600',
            fontSize: '20px',
            color: '#34475A',
            fontFamily: 'Open Sans !important',
            textAlign: 'center',
            maxWidth: 350
          }}
          >
            Are you sure you want to mark this policy as reviewed?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          paddingBottom: 2,
          paddingTop: 0,
          justifyContent: 'center'
        }}
        >
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {isSubmitting
            ? <LoadingButton
                loading
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='outlined'
                sx={{ mx: '10px' }}
              >
              Confirm
              </LoadingButton>
            : <Button variant='contained' onClick={reviewPolicy}>Confirm</Button>}
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Account
