import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DataGridPro, gridClasses, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { randomId } from '@mui/x-data-grid-generator'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import redirect from '../../../assets/images/pop-out-line.svg'
import { StyledTooltip } from '../../../components/CustomTooltip'

const OverWeightedSecurity = ({ data }) => {
  const apiRef = useGridApiRef()
  const [showModal, setShowModal] = useState(false)
  const [OverWeightedData, setOverWeightedData] = useState([])

  const downloadCsv = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: 'All-Overweighted-Securities',
        allColumns: true
      })
    }
  }

  useEffect(() => {
    setOverWeightedData(data.map(item => ({ id: randomId(), ...item })))
  }, [data])

  // this openModal to show popup modal
  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  // this CloseModal to close popup modal
  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  const columns = [
    { field: 'security', headerName: 'Security', flex: 1, valueGetter: (props) => props?.row.localSymbol || props.row.instrId || 'N/A' },
    {
      field: 'name',
      headerName: 'Security Name',
      width: 0,
      hideable: true,
      valueGetter: (params) => (params?.row?.name || '')
    },
    {
      field: 'weightDrift',
      headerName: 'Drift (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      type: 'number',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    }
  ]

  const getRowId = (row) => row?.id
  // we have to show only five data in table.
  // popup will show the all the data
  // this over weighted securities is sorted according to weight in descending order
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            component='p'
            className='text-title header-text'
          >
            Top Overweighted Securities
          </Typography>
          {data?.length > 0 && (
            <IconButton
              onClick={openModal}
              className='tooltip-trade'
            >
              <img src={redirect} alt='' />
              <span
                className='tooltiptext'
                style={{ marginLeft: '-20px' }}
              >
                All Overweighted Securities
              </span>
            </IconButton>
          )}
        </Box>
        <TableContainer mt={5}>
          <Table className='risk-page-table'>
            <TableHead>
              <TableRow>
                <TableCell>Security</TableCell>
                <TableCell align='right'>Drift (%)</TableCell>
                <TableCell align='right'>Weight (%)</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0
                ? <>
                  {(data.slice(0, 5) || []).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <StyledTooltip title={item?.name || 'N/A'}>
                            <span>
                              {item.localSymbol || item.instrId || ''}
                            </span>
                          </StyledTooltip>
                        </TableCell>
                        <TableCell align='right'>
                          {item.weightDrift
                            ? (Number(item.weightDrift) * 100).toFixed(2).toString()
                            : '0.00'}
                        </TableCell>
                        <TableCell align='right'>
                          {item.weightDrift
                            ? (Number(item.weight) * 100).toFixed(2).toString()
                            : '0.00'}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    )
                  })}
                </>
                : <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    No Rows
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          maxWidth='md'
          open={showModal}
          onClose={() => closeModal()}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
            <Typography
              variant='h6'
              textAlign='center'
              color='#34475A'
              fontWeight={400}
            >
              All Overweighted Securities
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              {data?.length > 0 && (
                <Button
                  variant='text'
                  color='primary'
                  onClick={downloadCsv}
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Export as CSV
                </Button>
              )}
              <IconButton
                aria-label='close'
                onClick={closeModal}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box style={{
              maxHeight: 650,
              width: '100%',
              minWidth: '600px'
            }}
            >
              <DataGridPro
                columns={columns}
                rows={OverWeightedData}
                pagination
                apiRef={apiRef}
                disableRowSelectionOnClick
                getRowId={getRowId}
                // Hide column 'name'
                columnVisibilityModel={{
                  name: false
                }}
                pageSizeOptions={[10]}
                initialState={{
                  ...OverWeightedData?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } }
                }}
                autoHeight
                sx={(theme) => ({
                  [`.${gridClasses.main}`]: {
                    overflow: 'unset'
                  },
                  [`.${gridClasses.columnHeaders}`]: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.footerContainer}`]: {
                    position: 'sticky',
                    bottom: -20,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  },
                  [`.${gridClasses.toolbarContainer}`]: {
                    position: 'relative',
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 1
                  }
                })}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default OverWeightedSecurity
