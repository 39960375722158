import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import downloadIcon from '../../../assets/images/icon-download.svg'
import { Box, Button, Fade, Modal, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { API } from 'aws-amplify'
import SaveIcon from '@mui/icons-material/Save'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: 700,
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
}

const DownloadTransButton = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [openFileSelection, setOpenFileSelection] = useState(false)
  const [showProcessing, setShowProcessing] = useState(false)
  const [transDetails, setTransDetails] = useState([])

  /**
 *
 * @param {*} fileType transaction file type
 * Fetch transaction details for selected file type
 */

  // function to shuffle account on each download
  function shuffleAccount (array) {
    const shuffledArray = [...array]
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
    }
    return shuffledArray
  }

  const getApprovedTransDetails = async (fileType) => {
    try {
      // show processing csv while fetching transactions
      setShowProcessing(true)
      const response = await API.get('baseUriTrade', `trade/v1/${user?.userGroup}/approved-transaction`, {
        queryStringParameters: {
          date: new Date().toISOString().slice(0, 10),
          minTaxAccount: fileType !== 'simple'
        }
      })
      if (!response?.data?.length && response?.message) {
        showError(response.message, false, {}, 'Failed to load approved transaction details.')
        handleCloseModal()
      }

      if (response?.data) {
        if (response.data[0]?.error) {
          setTransDetails(response?.data)
          setShowProcessing(false)
        } else {
          setTransDetails(response)
          setShowProcessing(false)
        }
      }
    } catch (error) {
      showError('Error occurred while fetching approved transaction details.')
      setShowProcessing(false)
    }
  }

  function sortRandomAccount (data) {
    const groupedData = {}
    let currentGroup = []
    for (let j = 0; j < data.length; j++) {
      const item = data[j]
      const nextIndex = j + 1

      if (item.col2 === 'SL') {
        currentGroup.push(item)

        for (let i = nextIndex; i < data.length; i++) {
          const nextItem = data[i]

          if (Object.keys(nextItem).length === 4) {
            currentGroup.push(nextItem)
          } else {
            break // Exit the loop when a non-detailed row item is encountered
          }
        }
      } else {
        // skip entry for detail row
        if (Object.keys(item).length > 4) {
          currentGroup.push(item)
        }
      }

      if (currentGroup.length > 0) {
        // maintain key-value pair for account ID
        if (groupedData[item.col1]) {
          groupedData[item.col1].push(currentGroup)
        } else {
          // skip entry for detail row
          if (Object.keys(item).length > 4) {
            groupedData[item.col1] = [currentGroup]
          }
        }
        // Clear the current group
        currentGroup = []
      }
    }

    // Randomize account in object
    const accountIds = Object.keys(groupedData)
    const shuffledAccountIds = shuffleAccount(accountIds)

    const randomizedGroupedData = {}
    for (let i = 0; i < accountIds.length; i++) {
      randomizedGroupedData[shuffledAccountIds[i]] = groupedData[accountIds[i]]
    }

    for (const account in randomizedGroupedData) {
      // Sort entries for each account based on Sell and Buy
      randomizedGroupedData[account].sort((groupA, groupB) => {
        const col2A = groupA[0]?.col2
        const col2B = groupB[0]?.col2

        if (col2A === 'SL' && col2B !== 'SL') return -1 // Move 'SL' groups to the top
        if (col2A !== 'SL' && col2B === 'SL') return 1

        // Sort by col2 in descending order
        return col2B.localeCompare(col2A)
      })

      // After sort, flatten array
      const sortedData = randomizedGroupedData[account].flat()
      randomizedGroupedData[account] = sortedData
    }
    return randomizedGroupedData // Add this return statement
  }

  const handleCloseModal = () => {
    setOpenFileSelection(false)
    setTransDetails([])
  }

  const handleCancelTransactionSelect = () => {
    setTransDetails([])
    setShowProcessing(false)
  }

  const selectFileType = (fileType) => {
    getApprovedTransDetails(fileType)
  }

  function generateCSV (data) {
    let csv = ''
    // Check if data is an object and exists
    if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
      csv = data
      return csv
    }
    const arrays = Object.values(data)
    // Helper function to escape CSV cell values
    const escapeCellValue = (value) => {
      if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
        return `"${value.replace(/"/g, '""')}"`
      }
      return value
    }
    arrays?.forEach((dataArray) => {
      dataArray?.forEach((rowObject) => {
        const headers = Object.keys(rowObject)
        const row = headers?.map((header) => {
          const value = rowObject[header]
          return escapeCellValue(value !== undefined ? value : '')
        })
        csv += row.join(',') + '\n'
      })
    })

    return csv
  }

  const downloadCSV = () => {
    if (!transDetails?.data) {
      // Handle the case when 'transDetails.data' is not available
      const errorMessage = transDetails[0]?.error || 'No data available'
      const fileName = 'Approved_transactions.csv'
      downloadCsvData(errorMessage, fileName)
    } else {
      // Handle the case when 'transDetails.data' is available
      transDetails?.data?.forEach((item) => {
        let csvString
        const masterAccountId = item?.masterAccountId
        if (item.fileData[0]?.error) {
          csvString = generateCSV(item.fileData[0]?.error)
        } else {
          const sortedData = sortRandomAccount(item?.fileData)
          csvString = generateCSV(sortedData)
        }
        const fileName = `Approved_Transactions_${masterAccountId}.csv`
        downloadCsvData(csvString, fileName)
      })
    }

    handleCloseModal()
  }

  const downloadCsvData = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = fileName

    downloadLink.click()
    URL.revokeObjectURL(url)
  }

  return (
    <Box>
      <Box className='tradeDownload pe-2'>
        <Button
          startIcon={<img src={downloadIcon} alt='' />} onClick={() => setOpenFileSelection(true)}
        >
          Transactions
        </Button>
      </Box>
      <Modal
        open={openFileSelection}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={openFileSelection}>
          <Box sx={style}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '20px',
              color: '#34475A',
              fontFamily: 'Open Sans !important',
              textAlign: 'center'
            }}
            >Select Transaction Type
            </Typography>

            <Box display='flex' justifyContent='center' my={2}>
              <Button variant='outlined' disabled={transDetails?.data?.length || showProcessing} onClick={() => { selectFileType('simple') }} sx={{ mr: 2, textTransform: 'capitalize' }}>Generate Simple transactions</Button>
              <Button variant='outlined' disabled={transDetails?.data?.length || showProcessing} onClick={() => { selectFileType('detailed') }} sx={{ textTransform: 'capitalize' }}>Generate Detailed transactions</Button>
            </Box>
            <Box textAlign='center'>
              {
                showProcessing
                  ? (
                    <LoadingButton
                      loading
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      variant='outlined'
                    >
                      Processing CSV
                    </LoadingButton>
                    )
                  : ((transDetails && transDetails?.data?.length) || (transDetails && transDetails[0]?.error))
                      ? (
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                        >
                          <Button variant='text' onClick={() => handleCancelTransactionSelect()} sx={{ mr: 2 }}>Cancel</Button>
                          <Button variant='contained' size='small' onClick={() => downloadCSV()}>Download CSV</Button>
                        </Box>
                        )
                      : ''
              }
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default DownloadTransButton
