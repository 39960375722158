import * as React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'

export default function TransferValidationTable ({ data, activeColumns, passiveColumns, commonColumns, DILinkType }) {
  const rows = data?.data?.map((item) => ({ id: randomId(), status: item.status, ...item }))
  const emptyColumn = {
    field: 'empty',
    headerName: '',
    width: 50,
    sortable: false,
    filterable: false,
    disableColumnMenu: true
  }

  const columns = DILinkType === 'Passive DI'
    ? [...commonColumns, ...passiveColumns, emptyColumn, ...activeColumns]
    : [...commonColumns, ...activeColumns, emptyColumn, ...passiveColumns]

  const columnGroupingModel = [
    ...(DILinkType === 'Passive DI'
      ? passiveColumns?.length
        ? [{
            groupId: data?.passiveAccountName || 'Passive Account',
            children: passiveColumns?.map(col => ({ field: col.field }))
          }]
        : []
      : activeColumns?.length
        ? [{
            groupId: data?.activeAccountName || 'Active Account',
            children: activeColumns?.map(col => ({ field: col.field }))
          }]
        : []),

    ...(DILinkType === 'Passive DI'
      ? activeColumns?.length
        ? [{
            groupId: data?.activeAccountName || 'Active Account',
            children: activeColumns?.map(col => ({ field: col.field }))
          }]
        : []
      : passiveColumns?.length
        ? [{
            groupId: data?.passiveAccountName || 'Passive Account',
            children: passiveColumns?.map(col => ({ field: col.field }))
          }]
        : [])
  ]

  return (
    <Box sx={{ height: 450, width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        density='compact'
        pagination
        pageSizeOptions={[15, 25, 50, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } }
        }}
        disableRowSelectionOnClick
        disableSelectionOnClick
        getRowClassName={(params) => (params.row.status === 'MISSING' || params.row.status === 'EXTRA' ? 'highlight-row' : '')}
        sx={{
          '& .highlight-row': {
            backgroundColor: '#F46A6A26',
            '&:hover': { backgroundColor: '#F46A6A26' }
          },
          '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
          },
            '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle': {
              color: '#34475a !important',
            },
          '& .MuiDataGrid-columnHeader': {
            ...(data?.activeAccountName
              ? {
                  [`&[aria-label="${data.activeAccountName}"]`]: {
                    backgroundColor: '#D6EDE8'
                  }
                }
              : ''),
            ...(data?.passiveAccountName
              ? {
                  [`&[aria-label="${data.passiveAccountName}"]`]: {
                    backgroundColor: '#E6F4FF'
                  }
                }
              : ''
            )
          }
        }}
      />
    </Box>
  )
}
