import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button ,Card, CardContent, Grid, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { storeAccountData } from '../../store/dashboard-reducer/dashboard-reducer'
import { useAuth } from '../../contexts/AuthContext'
import CustomKeyFieldSearch from '../../components/CustomKeyFieldSearch'
import { useAccountService } from '../../hooks/useAccountService'
import { useErrorToast } from '../../hooks/useErrorToast'
import { SelectListOfAccountLoader } from './SelectListAccountLoader'
import './selectelistofAccounts.css'
import { RefreshOutlined } from '@mui/icons-material'

const ListOfaAllAccount = ({ title }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const { accountData } = useSelector((state) => state.dashboard)
  const { showError } = useErrorToast()
  const { getBoBAccountListUtil } = useAccountService()
  const [Loading, setLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const columns = useMemo(() => ([
    {
      field: 'accountName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Link
          className='linkId'
          to={`/account-review/${title}/${params.row.accountId}`}
          state={{
            // strategyId: params.row.strategyId,
            // accountName: params.row.accountName,
            // strategyName: params.row.strategyName,
            // accountType: params.row.accountType
          }}
        >
          {params?.value || 'N/A'}
        </Link>
      )
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      flex: 1,
      valueGetter: (params) => params.value?.toLowerCase()?.charAt(0)?.toUpperCase() + params.value?.slice(1)?.toLowerCase()
    },
    {
      field: 'isTaxable',
      headerName: 'Taxable',
      flex: 1,
      valueGetter: (params) => (params.value === 0 ? 'No' : 'Yes')
    },
    {
      field: 'accountCode',
      headerName: 'Account Code',
      flex: 1
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Custodian Account Number',
      flex: 1,
      valueFormatter: (params) => params.value || 'N/A'
    },
    {
      field: 'billStartDate',
      headerName: 'Billing Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.billStartDate ? params?.row?.billStartDate?.split('T')[0] : ''
    },
    {
      field: 'masterAccountId',
      headerName: 'Master Account Number',
      flex: 1,
      valueFormatter: (params) => params.value || 'N/A'
    },
    {
      field: 'portSpecName',
      headerName: 'Model',
      flex: 1
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      flex: 1,
      valueFormatter: (params) => `$${formatCurrency(params.value)}`,
      align: 'right'
    },
    {
      field: 'unrealizedGainLoss',
      headerName: 'Unrealized Gain/Loss',
      flex: 1,
      renderCell: (params) => params.row.unrealizedGainLoss < 0
        ? `-$${formatCurrency(params.row.unrealizedGainLoss || 0)} 
      (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)`
        : `${formatCurrency(params.row.unrealizedGainLoss || 0)} 
      (${(Number(params.row.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)`,
      align: 'right'
    },
    {
      field: 'assetAllocationDrift',
      headerName: 'Asset Allocation Drift',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Grid container justifyContent='center' alignItems='center' spacing={0.5} flexWrap='nowrap'>
          <Grid item><Box className={Number(params.value) < 0.6666 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={(Number(params.value) > 0.6666 && Number(params.value) < 1.3333) ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
          <Grid item><Box className={Number(params.value) > 1.3333 ? 'progressbar bg-blue' : 'progressbar'} /></Grid>
        </Grid>
      )
    }
  ]), [title])

  useEffect(() => {
    if (user) {
      // check if data is present in redux then avoid API call
      if (accountData?.length) {
        setAccounts(accountData)
        const storeArray = []
        accountData.forEach((element) => {
          storeArray.push({
            accountName: element.accountName,
            accountType: element.accountType,
            accountCode: element.accountCode,
            strategyId: element.strategyId,
            custodianAccountNumber: element.custodianAccountNumber,
            portSpecName: element.portSpecName,
            accountId: element.accountId,
            totalMarketValue: element.totalMarketValue,
            isTaxable: element.isTaxable,
            strTypeCode: element.strTypeCode
          })
        })
        localStorage.setItem('object', JSON.stringify(storeArray))
        setLoading(false)
      }
      // else if (reviewPageAccounts && reviewPageAccounts[title] && reviewPageAccounts[title].length) {
      //   // check if data is present in redux for that module then avoid API call
      //   setAccounts(reviewPageAccounts[title])
      //   const storeArray = []
      //   reviewPageAccounts[title].forEach((element) => {
      //     storeArray.push({
      //       accountName: element.accountName,
      //       accountType: element.accountType,
      //       accountCode: element.accountCode,
      //       strategyId: element.strategyId,
      //       custodianAccountNumber: element?.custodianAccountNumber,
      //       portSpecName: element.portSpecName,
      //       accountId: element.accountId,
      //       totalMarketValue: element.totalMarketValue
      //     })
      //   })
      //   localStorage.setItem('object', JSON.stringify(storeArray))
      //   setLoading(false)
      // }
      else {
        getAccountsList()
      }
    }
  }, [user, title])

  const getAccountsList = async (clearCacheFlag = false) => {
    setLoading(true)
    getBoBAccountListUtil({ serviceId: 'book-of-business', resourceId: 'book-of-business' }, (clearCacheFlag ? { 'cache-override': true } : {}))
      .then((response) => {
        if (response.data.accountsSummaryDetails) {
          setAccounts([...response.data.accountsSummaryDetails])
          // if (user?.userGroup === 'pm') {
          dispatch(storeAccountData(response.data.accountsSummaryDetails))
          // }
          // else {
          //   dispatch(storeReviewPageAccounts({ moduleName: title, accounts: response.data.accountsSummaryDetails }))
          // }
          const storeArray = []
          response.data.accountsSummaryDetails.forEach((element) => {
            storeArray.push({
              accountName: element.accountName,
              accountType: element.accountType,
              accountCode: element.accountCode,
              strategyId: element.strategyId,
              custodianAccountNumber: element.custodianAccountNumber,
              portSpecName: element.portSpecName,
              accountId: element.accountId,
              totalMarketValue: element.totalMarketValue,
              isTaxable: element.isTaxable,
              strTypeCode: element.strTypeCode
            })
          })
          localStorage.setItem('object', JSON.stringify(storeArray))
        }
        setLoading(false)
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load account details.')
        setLoading(false)
      })
  }

  const formatCurrency = (amount) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(amount)) >= 1.0e9
      ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
        ? (Math.abs(Number(amount)) / 1.0e6).toFixed(2) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(amount)) >= 1.0e3
          ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + 'K'
          : Math.abs(Number(amount).toFixed(2))
  }

  const refreshData = () => {
    getAccountsList(true)
  }

  return (
    <Box className='selectListofAccount'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography className='page-title' mb={2}>Select Account to Access Dashboard</Typography>
        <Box sx={{ display: 'flex', alignItems: 'start', columnGap: 1 }}>
          <CustomKeyFieldSearch
            fieldName='accountName'
            tableData={accountData}
            fieldLabel='Account Name'
            filterData={(filteredData) => {
              setAccounts(filteredData)
              setCurrentPage(0)
            }}
          />
          <Button
            variant='outlined'
            onClick={refreshData}
            sx={{
              border: '2px solid #dee2e6',
              padding: 1,
              minWidth: 'auto',
              ':hover': {
                background: 'transparent',
                border: '2px solid #dee2e6'
              },
              marginBottom: '16px',
              // marginTop: '8px',
              // marginRight: '8px'
              marginRight: '0px'
            }}
            className='tooltip-trade'
          >
            <RefreshOutlined sx={{ height: 20, width: 20, color: '#74788D' }} />
            <span
              className='tooltiptext'
              style={{ marginLeft: '-15px' }}
            >
              Refresh
            </span>
          </Button>
        </Box>
      </Box>
      <Card className='card-layout'>
        <CardContent>
          {Loading
            ? <Box className='table-responsive'>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Account Type</th>
                    <th>Taxable</th>
                    <th>Account Code</th>
                    <th>Model</th>
                    <th>Total Market Value</th>
                    <th>Unrealized Gain/Loss</th>
                    <th>Asset Allocation Drift</th>
                  </tr>
                </thead>
                <SelectListOfAccountLoader colNum={12} />
              </table>
              </Box>
            : <DataGridPro
                rows={accounts}
                columns={columns}
                loading={Loading}
                getRowId={(row) => row.accountId}
                autoHeight
                pageSizeOptions={[10]}
                onPaginationModelChange={(event) => setCurrentPage(event.page)}
                paginationModel={{ pageSize: 10, page: currentPage }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10, page: currentPage } },
                  columns: {
                    columnVisibilityModel: {
                      masterAccountId: false,
                      billStartDate: false,
                      custodianAccountNumber: false
                    }
                  }
                }}
                pagination
                disableRowSelectionOnClick
                sx={{
                  border: 'none',
                  fontFamily: 'Open Sans'
                }}
              />}
        </CardContent>
      </Card>
    </Box>
  )
}
export default ListOfaAllAccount
