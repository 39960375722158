import { useState } from 'react'
import { Box, Button, Checkbox, Divider, Drawer, FormControlLabel, Grid, IconButton, Typography, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Slider from '@mui/material/Slider'

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: 14,
  color: '#74788D',
  lineHeight: '21px',
  marginLeft: '3px'
}))

const FilterMenu = ({ showFilters, closeFilters, removeAll, strategies, selectRangeFilter, selectStrategyFilter, selectedStrategyFilters, applyFilters }) => {
  const [cashDriftRange, setCashDriftRange] = useState([0, 0])

  const [gainLossRange, setGainLossRange] = useState([-1, -1])

  const [accountAgeRange, setAccountAgeRange] = useState([0, 0])

  const removeAllFilters = () => {
    setCashDriftRange([0, 0])
    setAccountAgeRange([0, 0])
    setGainLossRange([-1, -1])
    removeAll()
  }

  return (
    <Drawer
      anchor='right'
      open={showFilters}
      onClose={closeFilters}
      sx={{
        width: 400,
        '& .MuiDrawer-paper': {
          width: 400
        }
      }}
    >
      <Box sx={{ p: 3, pt: 2 }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Typography
            component='h1' sx={{
              color: '#34475A',
              fontSize: '20px',
              fontWeight: '600'
            }}
          >Filter By
          </Typography>
          <div>
            <Button variant='link' className='reset-btn' onClick={removeAllFilters}>Reset all</Button>
            <IconButton onClick={closeFilters} sx={{ ml: 1 }}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Grid container alignItems='center'>
            <Grid item>
              <Typography component='h5' className='text-title'>Cash Drift</Typography>
            </Grid>
            <Grid item>
              <CustomTypography>
                {(cashDriftRange[0] > 0 || cashDriftRange[1] > 0) ? `${cashDriftRange[0]} - ${cashDriftRange[1]}` : ''}
              </CustomTypography>
            </Grid>
          </Grid>
          <Box sx={{
            px: 1,
            py: 2
          }}
          >
            <Slider
              value={cashDriftRange}
              min={0}
              max={1}
              step={0.1}
              marks
              onChange={(event, newValue) => selectRangeFilter('Cash Drift', newValue, setCashDriftRange)}
              valueLabelDisplay='auto'
            />
          </Box>
          <Grid container alignItems='center'>
            <Grid item>
              <Typography component='h5' className='text-title'>Unrealized Gain/Loss</Typography>
            </Grid>
            <Grid item>
              <CustomTypography>
                {(gainLossRange[0] > -1 || gainLossRange[1] > -1) ? `${gainLossRange[0]} - ${gainLossRange[1]}` : ''}
              </CustomTypography>
            </Grid>
          </Grid>
          <Box sx={{
            px: 1,
            py: 2
          }}
          >
            <Slider
              value={gainLossRange}
              min={-1}
              max={1}
              step={0.01}
              marks
              onChange={(event, newValue) => selectRangeFilter('Unrealized Gain/Loss', newValue, setGainLossRange)}
              valueLabelDisplay='auto'
            />
          </Box>
          <Grid container alignItems='center'>
            <Grid item>
              <Typography component='h5' className='text-title'>Account Age</Typography>
            </Grid>
            <Grid item>
              <CustomTypography>
                {(accountAgeRange[0] > 0 || accountAgeRange[1] > 0) ? `${accountAgeRange[0]} - ${accountAgeRange[1]}` : ''}
              </CustomTypography>
            </Grid>
          </Grid>
          <Box sx={{
            px: 1,
            py: 2
          }}
          >
            <Slider
              value={accountAgeRange}
              min={0}
              max={24}
              step={1}
              marks
              onChange={(event, newValue) => selectRangeFilter('Account Age', newValue, setAccountAgeRange)}
              valueLabelDisplay='auto'
            />
          </Box>
          <Grid container alignItems='center' sx={{ mt: 3 }}>
            <Grid item>
              <Typography component='h5' className='text-title'>Models</Typography>
            </Grid>
          </Grid>
          <Box sx={{
            px: 1,
            py: 2
          }}
          >
            {
              (strategies || []).map((strategy, index) => {
                return (
                  <FormControlLabel
                    sx={{
                      display: 'block',
                      '.MuiFormControlLabel-label': {
                        color: '#34475A',
                        fontWeight: 500,
                        fontSize: '14px'
                      }
                    }}
                    key={index}
                    control={<Checkbox
                      value={strategy.strategyId}
                      id={strategy.strategyId}
                      className='form-check-input'
                      onChange={(event) => selectStrategyFilter(event, strategy)}
                      checked={selectedStrategyFilters.includes(strategy)}
                    />}
                    label={strategy.strategyName}
                  />
                )
              })
            }
          </Box>
          <Button variant='contained' onClick={applyFilters} sx={{ float: 'right' }}>Apply</Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default FilterMenu
