import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridToolbarContainer, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro'
import redirect from '../../../assets/images/pop-out-line.svg'
import { StyledTooltip } from '../../../components/CustomTooltip'

const UnderWeightedSecurities = ({ underWeight }) => {
  const apiRef = useGridApiRef()
  const [showModal, setShowModal] = useState(false)
  const [underRatedSec, setUnderRatedSec] = useState([])

  const downloadCsv = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: 'All-Underweighted-Securities',
        allColumns: true
      })
    }
  }

  useEffect(() => {
    setUnderRatedSec(underWeight.map((item) => ({ id: randomId(), ...item })))
  }, [underWeight])

  // this openModal to show popup modal
  const openModal = () => {
    document.body.classList.add('modal-open', 'pe-0')
    setShowModal(true)
  }

  // this CloseModal to close popup modal
  const closeModal = () => {
    setShowModal(false)
    setTimeout(() => {
      document.body.classList.remove('modal-open', 'pe-0')
    }, 200)
  }

  const columns = [
    { field: 'security', headerName: 'Security', flex: 1, valueGetter: (props) => props?.row?.localSymbol || props?.row?.instrId || '' },
    {
      field: 'name',
      headerName: 'Security Name',
      width: 0,
      hideable: true,
      valueGetter: (params) => (params?.row?.name || '')
    },
    {
      field: 'weightDrift',
      headerName: 'Drift (%)',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        return params.value !== undefined
          ? `${(Number(params.value) * 100).toFixed(2)}`
          : '0.00'
      }
    }
  ]

  const getRowId = (row) => row?.id
  // we have to show only five data in table.
  // popup will show the all the data
  // this over under securities is sorted according to the weight in ascending order
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            component='p'
            className='text-title header-text'
          >
            Top Underweighted Securities
          </Typography>
          {underWeight?.length > 0 && (
            <IconButton
              onClick={openModal}
              className='tooltip-trade'
            >
              <img src={redirect} alt='' />
              <span
                className='tooltiptext'
                style={{ marginLeft: '-20px' }}
              >
                All Underweighted Securities
              </span>
            </IconButton>
          )}
        </Box>
        <TableContainer mt={5}>
          <Table className='risk-page-table'>
            <TableHead>
              <TableRow>
                <TableCell>Security</TableCell>
                <TableCell align='right'>Drift (%)</TableCell>
                <TableCell align='right'>Weight (%)</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {underWeight?.length > 0
                ? <>
                  {(
                    (underWeight.slice(0, 5)) || []
                  ).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <StyledTooltip title={item?.name || 'N/A'}>
                            <span>
                              {item.localSymbol || item.instrId || ''}
                            </span>
                          </StyledTooltip>
                        </TableCell>
                        <TableCell align='right'>
                          {item.weightDrift
                            ? (Number(item.weightDrift) * 100).toFixed(2).toString()
                            : '0.00'}
                        </TableCell>
                        <TableCell align='right'>
                          {item.weightDrift
                            ? (Number(item.weight) * 100).toFixed(2).toString()
                            : '0.00'}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    )
                  })}
                </>
                : <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    No Rows
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          maxWidth='md'
          open={showModal}
          onClose={() => closeModal()}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 0 }}>
            <Typography
              variant='h6'
              textAlign='center'
              color='#34475A'
              fontWeight={400}
            >
              All Underweighted Securities
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              {underWeight?.length > 0 && (
                <Button
                  variant='text'
                  color='primary'
                  onClick={downloadCsv}
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Export as CSV
                </Button>
              )}
              <IconButton
                aria-label='close'
                onClick={closeModal}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {underWeight?.length > 0
              ? (
                <Box style={{
                  maxHeight: 650,
                  width: '100%',
                  minWidth: '600px'
                }}
                >
                  <DataGridPro
                    columns={columns}
                    rows={underRatedSec}
                    apiRef={apiRef}
                    pagination
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                    getRowId={getRowId}
                    // Hide column 'name'
                    columnVisibilityModel={{
                      name: false
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } }
                    }}
                    autoHeight
                    sx={(theme) => ({
                      [`.${gridClasses.main}`]: {
                        overflow: 'unset'
                      },
                      [`.${gridClasses.columnHeaders}`]: {
                        position: 'sticky',
                        top: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      },
                      [`.${gridClasses.footerContainer}`]: {
                        position: 'sticky',
                        bottom: -20,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      },
                      [`.${gridClasses.toolbarContainer}`]: {
                        position: 'relative',
                        top: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 1
                      }
                    })}
                  />
                </Box>
                )
              : (
                <Typography variant='body1' textAlign='center'>
                  No Rows
                </Typography>
                )}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}

export default UnderWeightedSecurities
