import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import CancelIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveIcon from '@mui/icons-material/Save'
import TryOutlinedIcon from '@mui/icons-material/TryOutlined'
import { Box, Button, Divider, IconButton, Modal, Skeleton, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DataGridPro, GridActionsCellItem, gridClasses, GridRowEditStopReasons, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro'
import { HtmlTooltip } from '../../../../components/CustomTooltip'
import { useAuth } from '../../../../contexts/AuthContext'
import { moduleConfig } from '../../../../contexts/data'
import { ACCESS_LEVEL } from '../../../../contstants/constants'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import { formatCurrency } from '../../../../utils/FormateCurrenyInMilion'
import { fixConnectivityStatusColor } from '../../../../utils/getFixConnectivityStatusColor'
import { isNumberOnly, numberOnlyPasteHandler } from '../../../../utils/NumberUtils'
import Loader from '../../../Loader'
import StyledTradePopupBox from '../../components/StyledTradePopupBox'
import DownloadTradeLogsCell from '../DownloadTradeLogsCell'
import FixFlyerStatusCell from './FixFlyerStatusCell'
import NumberFieldEditCell from './NumberFieldEditCell'
import FixConnectivityStatusChip from './FixConnectivityStatusChip'

const detailsPopupStyle = {
  position: 'absolute',
  inset: 0,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '4px',
  outline: 'none'
}

const HeaderLabel = styled(Typography)({
  fontSize: '14px',
  color: '#74788d'
})

const HeaderValue = styled(Typography)({
  fontSize: '14px'
})

const TradeAdhocPopup = ({ showTradeAdhocPopup, closeTradeAdhocPopup, tradeRow, updateCurrentRow, updateOptmRunStatus }) => {
  const [cashValue, setCashValue] = useState(0)
  const [sellValue, setSellValue] = useState(0)
  const [buyValue, setBuyValue] = useState(0)
  const { user, checkAccess } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()
  const [tradesList, setTradesList] = useState([])
  const [tradesLoading, setTradesLoading] = useState(true)
  const [tradeSummaryLoading, setTradeSummaryLoading] = useState(false)
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [selectedTrades, setSelectedTrades] = useState([])
  const [adhocTradeGridState, setAdhocTradeGridState] = useState(null)
  const [fixFlyerStatusList, setFixFlyerStatusList] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const apiRef = useGridApiRef()

  const updateTradeList = (payload, multipleFlag, id) => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setIsAPILoading(true)
    API.patch(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-details/${accOptMapId}`,
      {
        body: payload
      }
    )
      .then((response) => {
        if (response?.success) {
          // find the matching row in response and if found then replace the field from response
          const updatedTradeList = tradesList.map(trade => {
            const index = response?.data?.findIndex(res => trade.tradeId === res.tradeId)
            if (index > -1) {
              return { ...trade, ...response?.data[index], fixFlyerStatus: response?.data[index]?.fixTradeStatus }
            } else {
              return trade
            }
          })
          setTradesList(updatedTradeList)
          calculateSellBuyValue(selectedTrades, updatedTradeList)
          // change row mode to view mode
          if (multipleFlag) {
            const obj = {}
            updatedTradeList.forEach((trade) => {
              if (selectedTrades.includes(trade.tradeId) || trade.tradeId === id) {
                obj[trade.tradeId] = { mode: GridRowModes.View }
              }
            })
            setRowModesModel({ ...rowModesModel, ...obj })
          } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
          }
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update selected trade details.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.escapeKeyDown || params.reason === GridRowEditStopReasons.enterKeyDown || params.reason === GridRowEditStopReasons.tabKeyDown) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id) => () => {
    // change all rows mode to edit if selected row is changed to edit
    if (selectedTrades.includes(id)) {
      const obj = {}
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId)) {
          obj[trade.tradeId] = { mode: GridRowModes.Edit }
        }
      })
      setRowModesModel({ ...rowModesModel, ...obj })
    } else {
      // if row is not selected then change to edit mode for that row only
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }
  }

  const handleSaveClick = (id, row) => () => {
    const payload = []
    const multipleSelectFlag = selectedTrades.includes(id)
    if (multipleSelectFlag) {
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId)) {
          const updatedRow = apiRef.current.getRowWithUpdatedValues(trade.tradeId)
          // create object with changed properties
          const tempObj = {}
          if (updatedRow?.trdQty !== trade.trdQty) {
            tempObj.trdQty = updatedRow?.trdQty
          }
          if (updatedRow?.tradedShares !== trade.tradedShares) {
            tempObj.tradedShares = updatedRow?.tradedShares
          }
          if (updatedRow?.tradedPrice !== trade.tradedPrice) {
            tempObj.tradedPrice = updatedRow?.tradedPrice
          }
          if (updatedRow?.fixFlyerStatus !== trade.fixFlyerStatus) {
            tempObj.fixTradeStatus = updatedRow?.fixFlyerStatus
          }
          // insert object into payload only if anything is changed
          if (Object.keys(tempObj)?.length) {
            tempObj.tradeId = updatedRow?.tradeId
          } else {
            return
          }
          payload.push(tempObj)
        }
      })
    } else {
      const updatedRow = apiRef.current.getRowWithUpdatedValues(id)
      const tempObj = {}
      // create object with changed properties
      if (updatedRow?.trdQty !== row.trdQty) {
        tempObj.trdQty = updatedRow?.trdQty
      }
      if (updatedRow?.tradedShares !== row.tradedShares) {
        tempObj.tradedShares = updatedRow?.tradedShares
      }
      if (updatedRow?.tradedPrice !== row.tradedPrice) {
        tempObj.tradedPrice = updatedRow?.tradedPrice
      }
      if (updatedRow?.fixFlyerStatus !== row.fixFlyerStatus) {
        tempObj.fixTradeStatus = updatedRow?.fixFlyerStatus
      }
      // insert object into payload only if anything is changed
      if (Object.keys(tempObj)?.length) {
        tempObj.tradeId = updatedRow?.tradeId
      } else {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        return
      }
      payload.push(tempObj)
    }
    if (payload.length) {
      updateTradeList(payload, multipleSelectFlag, id)
    } else {
      // for multiple entries with no changes change mode to view
      const obj = {}
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId) || trade.tradeId === id) {
          obj[trade.tradeId] = { mode: GridRowModes.View }
        }
      })
      setRowModesModel({ ...rowModesModel, ...obj })
    }
  }

  const handleCancelClick = (id) => () => {
    if (selectedTrades.includes(id)) {
      const obj = {}
      // if clicked on selected entries, update them to view and ignore modifications
      tradesList.forEach((trade) => {
        if (selectedTrades.includes(trade.tradeId) || trade.tradeId === id) {
          obj[trade.tradeId] = { mode: GridRowModes.View, ignoreModifications: true }
        }
      })
      setRowModesModel({ ...rowModesModel, ...obj })
    } else {
      // clicked on non selected entries, update that to single row to view
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      })
    }
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const tradeListHeaders = [
    ...(checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, {
      subModuleName: moduleConfig.TRADE_HISTORY,
      component_name: moduleConfig.VIEW_TRADE_EXECUTION_LOGS
    })
      ? [{
        field: 'download-trade',
        headerName: '',
        width: 50,
        renderCell: (params) => <DownloadTradeLogsCell {...params} />
      }]
      : []
    ),
    { field: 'instrId', headerName: 'Instrument Id' },
    { field: 'localSymbol', headerName: 'Local Symbol' },
    { field: 'tradeStatusCode', headerName: 'Trade Status' },
    {
      field: 'fixFlyerStatus',
      headerName: 'FIX Connectivity Status',
      width: 200,
      editable: true,
      renderCell: (params) => {
        const data = params?.value
        return (
          <Box sx={{ color: fixConnectivityStatusColor(data), textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={data}>
            {data || ''}
          </Box>
        )
      },
      renderEditCell: (params) => <FixFlyerStatusCell {...params} fixFlyerStatusList={fixFlyerStatusList} selectedTrades={selectedTrades} />
    },
    ...(tradeRow?.optDetails?.length && tradeRow?.optDetails[0]?.isBlockTrade)
      ? [
        {
          field: 'blockTradeStatus',
          headerName: 'Block Trade Status',
          width: 200,
          renderCell: (params) => {
            const data = params?.value
            return (
              <Box sx={{ color: fixConnectivityStatusColor(data), textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={data}>
                {data || ''}
              </Box>
            )
          },
        },
          {
          field: 'blockTradedShares',
          headerName: 'Block Trade Quantity',
          type: 'number',
          align: 'right',
          headerAlign: 'right',
          valueGetter: (params) => {
            return params?.row?.blockTradedShares !== null && params?.row?.blockTradedShares !== undefined ? params?.row?.blockTradedShares : 0
          },
          renderCell: (params) => {
            return <>
              {params?.row?.blockTradedShares !== null && params?.row?.blockTradedShares !== undefined ? params?.row?.blockTradedShares : ''}
              {params?.row?.blockTradeQty !== null && params?.row?.blockTradeQty !== undefined ? `/${params?.row?.blockTradeQty}` : ''}
            </>
          }
          }
        ]
      : [],
    {
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
    },
    {
      field: 'trdCode',
      headerName: 'Trade Code',
      renderCell: (params) => <span style={{ color: params.value === 'SELL' ? '#FF6161' : params.value === 'BUY' ? '#3BBFA3' : '#34475A' }}>{params.value}</span>
    },
    {
      field: 'orgPurchasePrice',
      headerName: 'Original Purchase Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => params?.row?.orgPurchasePrice?.toFixed(2),
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'initWeight',
      headerName: 'Initial Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => parseFloat((params?.value * 100).toFixed(2)),
      renderCell: (params) => (params?.row?.initWeight * 100).toFixed(2)
    },
    {
      field: 'propWeight',
      headerName: 'Proposed Weight (%)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => (params.value * 100).toFixed(2),
      renderCell: (params) => (params?.row?.propWeight * 100).toFixed(2)
    },
    {
      field: 'initShares',
      headerName: 'Initial Shares',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params?.row?.initShares,
    },
    { field: 'propShares', headerName: 'Proposed Shares', type: 'number', align: 'right', headerAlign: 'right', renderCell: (params) => params?.row?.propShares },
    {
      field: 'tradedShares',
      headerName: 'Traded Shares',
      type: 'number',
      align: 'right',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : null,
      renderCell: (params) => {
        const data = params?.row?.fixFlyerStatus
        return params?.value !== null
          ? <Box sx={{ color: data === 'TRADE_COMPLETED' ? '#3BBFA3' : data === 'TRADE_SENT' ? '#d29922' : data === 'NA' ? '#34475A' : '#ff6161', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{params?.value || 0}</Box>
          : ''
      },
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    {
      field: 'tradedPrice',
      headerName: 'Traded Price',
      type: 'number',
      align: 'right',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : null,
      renderCell: (params) => !isNaN(params?.row?.tradedPrice) && params?.row?.tradedPrice !== null
        ? params?.row?.tradedPrice < 0
          ? `-$${formatCurrency(params?.row?.tradedPrice)}`
          : `$${formatCurrency(params?.row?.tradedPrice)}`
        : '',
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    { field: 'age', headerName: 'Age', type: 'number', align: 'right', headerAlign: 'right', renderCell: (params) => params?.row?.age },
    {
      field: 'costBasis',
      headerName: 'Cost Basis',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => params?.row?.costBasis
        ? params?.row?.costBasis.toFixed(2)
        : '-'
    },
    {
      field: 'lastClosePrice',
      headerName: 'Last Closed Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.lastClosePrice)
        ? params?.row?.lastClosePrice < 0
          ? `-$${formatCurrency(params?.row?.lastClosePrice)}`
          : `$${formatCurrency(params?.row?.lastClosePrice)}`
        : '',
      align: 'right'
    },
    {
      field: 'liveMarketPrice',
      headerName: 'Live Market Price',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => {
        const isLivePriceAvailable = params?.row?.isLivePriceAvailable === 1

        const cellStyle = {
          color: !isLivePriceAvailable ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
          textAlign: 'right'
        }
        return (
          <div style={cellStyle}>
            {
              !isNaN(params?.row?.liveMarketPrice)
                ? params?.row?.liveMarketPrice < 0
                  ? `-$${formatCurrency(params?.row?.liveMarketPrice)}`
                  : `$${formatCurrency(params?.row?.liveMarketPrice)}`
                : ''
            }
          </div>
        )
      },
      align: 'right'
    },
    {
      field: 'initMarketValue',
      headerName: 'Initial Market Value',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.initMarketValue)
        ? params?.row?.initMarketValue < 0
          ? `-$${formatCurrency(params?.row?.initMarketValue)}`
          : `$${formatCurrency(params?.row?.initMarketValue)}`
        : '',
      align: 'right'
    },
    {
      field: 'propMarketValue',
      headerName: 'Proposed Market Value',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.propMarketValue)
        ? params?.row?.propMarketValue < 0
          ? `-$${formatCurrency(params?.row?.propMarketValue)}`
          : `$${formatCurrency(params?.row?.propMarketValue)}`
        : '',
      align: 'right'
    },
    {
      field: 'initUrgl',
      headerName: 'Initial Unrealized gain-loss',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.initUrgl)
        ? params?.row?.initUrgl < 0
          ? `-$${formatCurrency(params?.row?.initUrgl)}`
          : `$${formatCurrency(params?.row?.initUrgl)}`
        : '',
      align: 'right'
    },
    {
      field: 'rgl',
      headerName: 'Realized gain-loss',
      type: 'number',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.rgl)
        ? params?.row?.rgl < 0
          ? `-$${formatCurrency(params?.row?.rgl)}`
          : `$${formatCurrency(params?.row?.rgl)}`
        : '',
      align: 'right'
    },
    {
      field: 'trdDate',
      headerName: 'Trade Date',
      type: 'date',
      valueGetter: (params) => {
        if (!params?.value) return params?.value
        const date = new Date(params?.value)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.trdDate ? params?.row?.trdDate?.split('T')[0] : ''
    },
    { field: 'tripNum', headerName: 'Trip Number', valueGetter: (params) => (params?.value) || '' },
    {
      field: 'cashBalance',
      headerName: 'Cash Balance',
      type: 'number',
      align: 'right',
      valueGetter: (params) => parseFloat(params?.value?.toFixed(2)),
      renderCell: (params) => !isNaN(params?.row?.cashBalance) && params?.row?.cashBalance !== null
        ? params?.row?.cashBalance < 0
          ? `-$${formatCurrency(params?.row?.cashBalance)}`
          : `$${formatCurrency(params?.row?.cashBalance)}`
        : ''
    },
    {
      field: 'trdQty',
      headerName: 'Trade Quantity',
      type: 'number',
      width: 200,
      valueGetter: (params) => params?.value !== null && params?.value !== undefined ? parseFloat(params?.value) : '',
      align: 'right',
      editable: true,
      renderEditCell: (params) => <NumberFieldEditCell {...params} />
    },
    {
      field: 'fixComments',
      headerName: 'Fix Comments',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit' fontFamily='Open Sans' fontWeight={600}>Fix Comments</Typography>
                <Divider sx={{ backgroundColor: '#000000' }} />
                <Typography sx={{ fontSize: '14px' }} my={1}>{!params?.row?.fixComments ? '-' : params?.row?.fixComments}</Typography>
              </>
            }
          >
            <TryOutlinedIcon />
          </HtmlTooltip>
        )
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          const actions = [
            <GridActionsCellItem
              key='save'
              disabled={row.error}
              icon={<SaveIcon />}
              label='Save'
              onClick={handleSaveClick(id, row)}
              color='inherit'
            />,
            <GridActionsCellItem
              key='cancel'
              icon={<CancelIcon />}
              label='Cancel'
              onClick={handleCancelClick(id, row)}
              color='inherit'
            />
          ]
          return actions
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key='edit'
            label='Edit'
            disabled={row.fixFlyerStatus === 'TRADE_COMPLETED'}
            onClick={handleEditClick(id, row)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const tableHeaderHTML = tradeListHeaders.map(({ headerName }, index) => <TableCell key={index}>{headerName}</TableCell>)
  const rowSkeleton = tradeListHeaders.map(({ headerName }, index) => <TableCell key={index}><Skeleton variant='text' sx={{ fontSize: '1rem' }} width={100} /></TableCell>)

  const getCashValue = (trades) => {
    // filter cash trade
    let initialCashBalance = parseFloat(trades.find(trd => trd.instrId === '__CASH')?.initShares) ?? 0

    // filter completed trades and broken trades
    const completedTrades = trades.filter(trd => trd.fixFlyerStatus === 'TRADE_COMPLETED' || trd.fixFlyerStatus === 'TRADE_BREAK')
    // store sell and buy trades with valid traded shares and price
    const sellTrades = completedTrades.filter(trade => trade.trdCode === 'SELL' && trade.tradedShares && trade.tradedPrice)
    const buyTrades = completedTrades.filter(trade => trade.trdCode === 'BUY' && trade.tradedShares && trade.tradedPrice)
    if (isNaN(initialCashBalance)) {
      initialCashBalance = 0
    }
    let currentCashBalance = initialCashBalance
    // add all sell trade amount into cash
    sellTrades.forEach(trade => {
      const shares = trade.tradedShares ?? 0
      const price = trade.tradedPrice ?? 0
      currentCashBalance += shares * price
    })
    // reduce all buy trade amount from cash
    buyTrades.forEach(trade => {
      const shares = trade.tradedShares ?? 0
      const price = trade.tradedPrice ?? 0
      currentCashBalance -= shares * price
    })
    setCashValue(parseFloat(currentCashBalance?.toFixed(2)))
  }

  const getOptimPropertyDetails = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setTradesLoading(true)
    API.post(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-details`,
      { body: { accOptMapId: [accOptMapId] } }
    )
      .then((response) => {
        if (response?.data?.data?.length) {
          setTradesList(response?.data?.data?.filter(row => row.trdCode !== 'NOP')?.map((row) => ({ ...row, id: row.tradeId })))
          getCashValue(response?.data?.data)
          setSellValue(0)
          setBuyValue(0)
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load trade history.')
      })
      .finally(() => {
        setTradesLoading(false)
      })
  }

  const sendTrades = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setIsAPILoading(true)
    API.post(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/manual-trading-order/${accOptMapId}`,
      { body: { tradeIds: selectedTrades } }
    )
      .then((response) => {
        if (response?.success) {
          showSuccess(response.message)
          closeTradeAdhocPopup()
          setSelectedTrades([])
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to send manual trades.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  // Effect to restore the grid state whenever gridState changes
  useEffect(() => {
    if (apiRef?.current?.restoreState && adhocTradeGridState) {
      const resetGridStateObj = {
        columns: adhocTradeGridState?.columns || {},
        pinnedColumns: adhocTradeGridState?.pinnedColumns || {}
      }
      apiRef?.current?.restoreState(resetGridStateObj)
    }
  }, [adhocTradeGridState, selectedTrades, isAPILoading, tradesLoading, tradesList, cashValue, fixFlyerStatusList, sellValue, buyValue, rowModesModel])

  const exportTableState = () => {
    if (apiRef?.current?.exportState) {
      const state = apiRef?.current?.exportState()
      setAdhocTradeGridState(state)
    }
  }

  const calculateSellBuyValue = (selectedList, updatedTradeList) => {
    const selectedRows = updatedTradeList?.filter(row => selectedList.includes(row.tradeId))
    let sellValue = 0
    let buyValue = 0
    if (selectedRows?.length) {
      // store sell and buy trades with valid traded shares and price
      const sellTrades = selectedRows.filter(trade => trade.trdCode === 'SELL' && trade.trdQty)
      const buyTrades = selectedRows.filter(trade => trade.trdCode === 'BUY' && trade.trdQty)
      // add all sell trade amount into cash
      sellTrades.forEach(trade => {
        const shares = Math.abs(parseFloat(trade.trdQty ?? 0))
        // consider last close price if live price is not available
        const price = parseFloat((trade.isLivePriceAvailable === 1 ? trade.liveMarketPrice : trade.lastClosePrice) || 0)
        sellValue += shares * price
      })
      // reduce all buy trade amount from cash
      buyTrades.forEach(trade => {
        const shares = parseFloat(trade.trdQty ?? 0)
        // consider last close price if live price is not available
        const price = parseFloat((trade.isLivePriceAvailable === 1 ? trade.liveMarketPrice : trade.lastClosePrice) || 0)
        buyValue += shares * price
      })
    }
    setSellValue(sellValue)
    setBuyValue(buyValue)
  }

  const handleRowSelection = (selectedList) => {
    setSelectedTrades(selectedList)
    calculateSellBuyValue(selectedList, tradesList)
  }

  const fetchFixFlyerStatus = () => {
    API.get('tradeMaintainURL', `data-maintenance/v1/${user?.userGroup}/trading/status-master`)
      .then((response) => {
        if (response?.data?.data?.length) {
          setFixFlyerStatusList(response?.data?.data?.map(statusObj => statusObj.tradeStatusCode))
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load flyer status list.')
      })
  }

  const updateFixConnectivityStatus = (fixTradeStatus) => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setIsAPILoading(true)
    API.patch(
      'baseUriTrade',
      `trade/v1/${user?.userGroup}/trade-prop-summary/${accOptMapId}`,
      { body: { fixTradeStatus } }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          updateOptmRunStatus({ fixTradeStatus: response.data[0]?.fixTradeStatus }, accOptMapId)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to update fix flyer status.')
      })
      .finally(() => {
        setIsAPILoading(false)
      })
  }

  const getUpdatedTradeSummaryDetails = () => {
    const accOptMapId = tradeRow?.optDetails.length > 0 && tradeRow?.optDetails[0]?.accOptMapId
    if (!accOptMapId) {
      return
    }
    setTradeSummaryLoading(true)
    API.get(
      'baseUriTrade',
      `trade/v1/${user.userGroup}/fix-trade-status/${accOptMapId}`
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          updateCurrentRow(response.data[0], accOptMapId)
        }
      })
      .catch((error) => {
        showError(error, false, {}, 'Failed to load updated flyer status.')
      })
      .finally(() => {
        setTradeSummaryLoading(false)
      })
  }

  useEffect(() => {
    if (user) {
      getOptimPropertyDetails()
      fetchFixFlyerStatus()
    }
  }, [user])

  const refreshTradeAdhoc = () => {
    getOptimPropertyDetails()
    getUpdatedTradeSummaryDetails()
  }

  return (
    <>
      {
        isAPILoading ? <Loader /> : ''
      }
      <Modal
        open={showTradeAdhocPopup}
        onClose={() => closeTradeAdhocPopup(false)}
      >
        <Box sx={detailsPopupStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '5px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            <Typography variant='h6' sx={{ ml: '5px', mr: '5px', fontWeight: 400, color: '#002A59' }}>Trade Details</Typography>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {
                tradesLoading
                  ? ''
                  : (
                    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                      {tradeRow?.optDetails?.length && !tradeSummaryLoading
                        ? <FixConnectivityStatusChip fixTradeStatus={tradeRow?.optDetails[0]?.summaryDetails?.fixTradeStatus} fixFlyerStatusList={fixFlyerStatusList} updateFixConnectivityStatus={updateFixConnectivityStatus}/>
                        : ''}
                      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                        <HeaderLabel>Cash Available To Buy:</HeaderLabel>
                        <TextField
                          type='text'
                          size='small'
                          sx={{
                            width: '150px',
                            '& .MuiInputBase-input': {
                              padding: '3px 10px'
                            }
                          }}
                          value={cashValue}
                          onChange={(e) => setCashValue(e.target.value)}
                          onKeyDown={isNumberOnly}
                          onPaste={numberOnlyPasteHandler}
                        />
                      </Box>
                      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                        <HeaderLabel>Total Sell Value:</HeaderLabel>
                        <HeaderValue>${sellValue?.toFixed(2) || 0}</HeaderValue>
                      </Box>
                      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                        <HeaderLabel>Total Buy Value:</HeaderLabel>
                        <HeaderValue>${buyValue?.toFixed(2) || 0}</HeaderValue>
                      </Box>
                      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                        <HeaderLabel>Net Value:</HeaderLabel>
                        <HeaderValue>${((parseFloat(cashValue || 0)) + (sellValue || 0) - (buyValue || 0))?.toFixed(2) || 0}</HeaderValue>
                      </Box>
                      <Button variant='contained' size='small' disabled={!selectedTrades?.length || (tradeRow?.optDetails?.length && tradeRow?.optDetails[0]?.summaryDetails?.fixTradeStatus === 'TRADE_COMPLETED') || (Object.keys(rowModesModel).find(key => rowModesModel[key].mode === GridRowModes.Edit))} onClick={sendTrades}>Resume Trade</Button>
                      <IconButton
                        onClick={refreshTradeAdhoc}
                        sx={{
                          padding: 1,
                          borderRadius: '10px',
                          border: '2px solid #dee2e6',
                          marginLeft: '8px'
                        }}
                      >
                        <RefreshIcon sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Box>
                    )
              }
              <IconButton onClick={() => closeTradeAdhocPopup(false)} size='small'>
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>
          {
            tradesLoading
              ? (
                <StyledTradePopupBox className='table-responsive' sx={{ height: 'calc(100vh - 16px - 16px - 45px - 39px - 53px)' }}>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{ position: 'sticky', top: '0', background: 'white' }}
                      >
                        {tableHeaderHTML}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>{rowSkeleton}</TableRow>
                      <TableRow>{rowSkeleton}</TableRow>
                    </TableBody>
                  </Table>
                </StyledTradePopupBox>
                )
              : (
                <DataGridPro
                  apiRef={apiRef}
                  autoHeight
                  density='compact'
                  rows={tradesList || []}
                  columns={tradeListHeaders}
                  getRowId={(row) => row?.tradeId}
                  pagination
                  checkboxSelection
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  disableRowSelectionOnClick
                  disableMultipleRowSelection
                  editMode='row'
                  rowSelectionModel={selectedTrades}
                  onRowSelectionModelChange={handleRowSelection}
                  onColumnWidthChange={() => exportTableState()}
                  onColumnOrderChange={() => exportTableState()}
                  isCellEditable={(params) => params.row.fixFlyerStatus !== 'TRADE_COMPLETED'}
                  pageSizeOptions={[15, 25, 50, 75, 100]}
                  isRowSelectable={(params) => params.row.fixFlyerStatus !== 'TRADE_IN_PROGRESS' && params.row.fixFlyerStatus !== 'TRADE_COMPLETED' && params.row.fixFlyerStatus !== 'TRADE_SENT' && params.row.fixFlyerStatus !== 'TRADE_PARTIALLY_COMPLETED'}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    pinnedColumns: {
                      left: ['__check__', 'download-trade', 'instrId'],
                      right: ['actions']
                    }
                  }}
                  sx={(theme) => ({
                    [`& .${gridClasses.cell}:focus-within`]: {
                      outline: 'none !important'
                    },
                    [`& .${gridClasses['cell--editing']}:focus-within`]: {
                      outline: 'none !important'
                    },
                    [`& .${gridClasses['cell--editing']}`]: {
                      background: 'rgba(128,128,128,0.2)'
                    },
                    [`& .${gridClasses.cell} .MuiFormControl-root`]: {
                      margin: 0
                    },
                    [`& .${gridClasses.cell} .MuiInputBase-input`]: {
                      height: '1em'
                    },
                    [`.${gridClasses.main}`]: {
                      height: 'calc(100vh - 16px - 16px - 40px - 53px)'
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      backgroundColor: theme.palette.background.paper,
                      top: 0,
                      zIndex: 1
                    },
                    [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                      color: '#74788d',
                      fontWeight: 600
                    },
                    [`.${gridClasses.virtualScroller}`]: {
                      overflowY: 'auto !important'
                    },
                    [`.${gridClasses.virtualScroller}::-webkit-scrollbar`]: {
                      width: '0px'
                    }
                  })}
                />
                )
          }
        </Box>
      </Modal>
    </>
  )
}

export default TradeAdhocPopup
