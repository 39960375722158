import React from 'react'
import { Box, Skeleton } from '@mui/material'
import starIcon from '../../../assets/images/starIcon.svg'
import { useAuth } from '../../../contexts/AuthContext'
import { moduleConfig } from '../../../contexts/data'
import { ACCESS_LEVEL } from '../../../contstants/constants'

const TradeApprovalsHeaderMain = ({ DIheader=false }) => {
  const { checkAccess } = useAuth()
  return (
    <>
      <Box className='tradetable'>
        <Box className='responsive-trade-header'>
          <Box className='table-row table-trade-header'>
            <Box className='col col-1' />
            {
              DIheader ? (
                <Box sx={{ ml: '16px' }} className='col col-4' />
              )
                : ''
            }    
            <Box sx={{ mr: '16px' }} className='col col-1' />
            <Box sx={{ mr: '16px' }} className='col col-1' />
            <Box className='col col-1' />
            {
              !DIheader ? (
                <Box sx={{ ml: '16px' }} className='col col-4'>Account Name</Box>
              )
                : ''
            }
            <Box sx={{ ml: '16px' }} className='col col-3'>Account Code</Box>
            <Box sx={{ ml: '16px' }} className='col col-3'>Custodian No</Box>
            <Box className='col col-3 '>
              <img
                src={starIcon}
                alt=''
                style={{
                  marginBottom: 1,
                  visibility: 'hidden',
                  marginRight: 4
                }}
              />
              Scenario
            </Box>
            {
              checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL1 })
                ? <Box className='col col-1'>AP 1</Box>
                : null
            }{
              checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.APPROVAL2 })
                ? <Box className='col col-1'>AP 2</Box>
                : null
            }
            <Box className='col col-11' sx={{ textAlign: 'right' }}>initTe</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>propTe</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>isTaxable</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>taxCost</Box>
            <Box className='col col-8' sx={{ textAlign: 'right' }}>totalTurnover</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>tradeAge</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>modelChange</Box>
            <Box className='col col-8' sx={{ textAlign: 'right' }}>initNumHoldings</Box>
            <Box className='col col-8' sx={{ textAlign: 'right', ml: '8px' }}>propNumHoldings</Box>
            <Box className='col col-11' sx={{ textAlign: 'right', ml: '8px' }}>Buy</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>Sell</Box>
            <Box className='col col-8' sx={{ textAlign: 'right' }}>initRisk</Box>
            <Box className='col col-8' sx={{ textAlign: 'right' }}>portMv</Box>
            <Box className='col col-8' sx={{ textAlign: 'right' }}>propLongRgl</Box>
            <Box className='col col-9' sx={{ textAlign: 'right' }}>propShortRgl</Box>
            <Box className='col col-10' sx={{ textAlign: 'right' }}>initLtUrgl</Box>
            <Box className='col col-11' sx={{ textAlign: 'right' }}>initStUrgl</Box>
            <Box className='col col-1' sx={{ textAlign: 'right' }}>propLtUrgl</Box>
            <Box className='col col-1' sx={{ textAlign: 'right' }}>propStUrgl</Box>
            {
              checkAccess(moduleConfig.TRADE, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.TRADE_APPROVAL, id: null, component_name: moduleConfig.RUN_ADHOC })
                ? <Box className='col col-11' sx={{ textAlign: 'center' }}>Action</Box>
                : null
            }
          </Box>
        </Box>
        <ul>
          {[...Array(4)]?.map((e, i) => (
            <Skeleton key={i} height={90} width={2700} />
          ))}
        </ul>
      </Box>
      
    </>
  )
}

export default TradeApprovalsHeaderMain
