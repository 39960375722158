export const fixConnectivityStatusColor = (data) => {
  switch (data) {
    case 'TRADE_COMPLETED':
      return '#3BBFA3' // green
    case 'TRADE_PENDING':
    case 'AWAITING_ALLOCATION':
    case 'TRADE_IN_PROGRESS':
    case 'ALLOCATION_IN_PROGRESS':
    case 'TRADE_IN_QUEUE':
    case 'ALLOCATION_IN_QUEUE':
    case 'TRADE_PARTIALLY_FILLED':
    case 'TRADE_PARTIALLY_COMPLETED':
    case 'TRADE_SENT':
    case 'ALLOCATION_SENT':
      return '#d29922' // yellow
    case 'TRADE_STOPPED':
    case 'TRADE_STOPPED_LOW_CASH':
    case 'ALLOCATION_STOPPED':
      return '#F89406' // orange
    case 'TRADE_BREAK':
    case 'TRADE_FAILED':
      return '#ff6161' // red
    default:
      return '#34475A' // gray
  }
}
