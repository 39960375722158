import { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { Box, Button, IconButton, Menu, MenuItem, LinearProgress, linearProgressClasses, Skeleton, Stack, Step, StepLabel, Stepper, styled, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { StepDetails } from './StepDetailsPopup'
import { useAuth } from '../../../../contexts/AuthContext'
import { useErrorToast } from '../../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../../hooks/useSuccessToast'
import Loader from '../../../Loader'
import ConfirmationPopup from './ConfirmationPopup'
import TradeAdhocPopup from './TradeAdhocPopup'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#C0C0C099'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: '#1976d2'
  }
}))

// const cashTransferColumns = [
//   { field: 'srcAccount', headerName: 'Source Account', flex: 1 },
//   { field: 'destAccount', headerName: 'Destination Account', flex: 1 },
//   {
//     field: 'minCashTransfer',
//     headerName: 'Min Cash Transfer',
//     type: 'number',
//     align: 'right',
//     flex: 1,
//     valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
//     renderCell: (params) => (
//       params?.row?.minCashTransfer !== undefined
//         ? params?.row?.minCashTransfer !== null
//           ? params?.row?.minCashTransfer < 0
//             ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
//             : '$' + Number(params?.value)?.toLocaleString()
//           : 'NA'
//         : ''
//     )
//   },
//   {
//     field: 'approxCashTransfer',
//     headerName: 'Approx Cash Transfer',
//     type: 'number',
//     align: 'right',
//     flex: 1,
//     valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
//     renderCell: (params) => (
//       params?.row?.approxCashTransfer !== undefined
//         ? params?.row?.approxCashTransfer !== null
//           ? params?.row?.approxCashTransfer < 0
//             ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
//             : '$' + Number(params?.value)?.toLocaleString()
//           : 'NA'
//         : ''
//     )
//   }

// ]

// const securityTransferColumns = [
//   { field: 'localSymbol', headerName: 'Local Symbol', flex: 1 },
//   { field: 'srcAccount', headerName: 'Source Account', flex: 1 },
//   { field: 'destAccount', headerName: 'Destination Account', flex: 1 },
//   { field: 'quantity', headerName: 'Quantities', type: 'number', flex: 1, align: 'right', headerAlign: 'right' },
//   {
//     field: 'purchaseDate',
//     headerName: 'Purchase Date',
//     type: 'date',
//     valueGetter: (params) => {
//       if (!params?.value) return params?.value
//       const date = new Date(params?.value)
//       return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
//     },
//     renderCell: (params) => params?.row?.purchaseDate ? params?.row?.purchaseDate?.split('T')[0] : ''
//   },
//   {
//     field: 'orgPurchasePrice',
//     headerName: 'Org Purchase Price',
//     type: 'number',
//     align: 'right',
//     flex: 1,
//     valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
//     renderCell: (params) => (
//       params?.row?.orgPurchasePrice !== undefined
//         ? params?.row?.orgPurchasePrice !== null
//           ? params?.row?.orgPurchasePrice < 0
//             ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
//             : '$' + Number(params?.value)?.toLocaleString()
//           : 'NA'
//         : ''
//     )
//   },
//   {
//     field: 'marketValue',
//     headerName: 'Market Value',
//     type: 'number',
//     align: 'right',
//     flex: 1,
//     valueGetter: (params) => params?.value ? params?.value?.toFixed(0) : params?.value,
//     renderCell: (params) => (
//       params?.row?.marketValue !== undefined
//         ? params?.row?.marketValue !== null
//           ? params?.row?.marketValue < 0
//             ? '-$' + Math.abs(Number(params?.value))?.toLocaleString()
//             : '$' + Number(params?.value)?.toLocaleString()
//           : 'NA'
//         : ''
//     )
//   }
// ]

const stepperLoader = Array.from({ length: 5 }).map((_, i) => ({ id: i, status: 'pending' }))
const initialProgressValue = 0
const refreshAPITime = 20
const maxProgressValue = 100
// Calculate the incremental size for progress difference updates.
// The size is half of the actual increment size needed to complete the progress within the refresh API time interval.
const progressDifferenceSize = maxProgressValue / refreshAPITime / 2

const TradeTimeline = ({ row, refreshAccess, resumeTradeAccess, updateOptmRunStatus, viewTradeAdhocAccess, updateCurrentRow }) => {
  const apiRef = useGridApiContext()
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const { showSuccess } = useSuccessToast()

  const [width, setWidth] = useState(() => {
    const dimensions = apiRef.current.getRootDimensions()
    return dimensions?.viewportInnerSize.width
  })
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(null)
  const [showDetailsPopup, setShowDetailsPopup] = useState(false)
  const [showTradeAdhocPopup, setShowTradeAdhocPopup] = useState(false)
  const [currentClickedStep, setCurrentClickedStep] = useState(null)
  const [stepperLoading, setStepperLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(-1)
  const [steps, setSteps] = useState([])
  const [actionAPILoading, setActionAPILoading] = useState(false)
  const [intervalId, setIntervalId] = useState()
  const [progressValue, setProgressValue] = useState(initialProgressValue)
  const [stepsChanged, setStepsChanged] = useState(false)
  const [tradingActionAnchorEl, setTradingActionAnchorEl] = useState(null)

  const handleViewportInnerSizeChange = useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions()
    setWidth(dimensions?.viewportInnerSize.width)
  }, [apiRef])

  const fetchTradeTimelineData = (refreshStatus = false) => {
    if (row?.optDetails?.length) {
      setActiveStep(-1)
      setStepperLoading(true)
      if (intervalId) {
        clearInterval(intervalId)
      }
      setIntervalId(null)
      setProgressValue(initialProgressValue)
      API.get('baseUriTrade', `trade/v1/${user?.userGroup}/trade-flow/${row?.optDetails[0]?.accOptMapId}`)
        .then(response => {
          if (response?.data && response?.data?.length) {
            const tradeSteps = response?.data?.map((stepObj, index) => {
              if (stepObj.flyerftDetails?.length) {
                return {
                  ...stepObj,
                  flyerftDetails: stepObj.flyerftDetails?.map((detailsObj) => ({ ...detailsObj, id: randomId() })),
                  index
                }
              }
              return { ...stepObj, index }
            })
            setSteps(tradeSteps)
            if (tradeSteps[tradeSteps?.length - 1].status === 'completed') {
              setActiveStep(response?.data?.length)
            } else {
              setActiveStep(response?.data?.length - 1)
            }
            const updatedFixTradeStatus = response?.data?.slice(-1)[0].tradeSummaryFixStatus
            if (refreshStatus && showDetailsPopup) {
              setStepsChanged(true)
            }
            if (refreshStatus && updatedFixTradeStatus && updatedFixTradeStatus !== row?.optDetails[0]?.summaryDetails?.fixTradeStatus) {
              updateOptmRunStatus({ fixTradeStatus: updatedFixTradeStatus }, row?.optDetails[0]?.accOptMapId)
            }
            if (tradeSteps[tradeSteps?.length - 1].tradeSummaryFixStatus !== 'TRADE_COMPLETED') {
              // To ensure smoother updates, incrementing the progress every 500ms.
              setIntervalId(
                setInterval(() => {
                  setProgressValue(prevState => prevState + progressDifferenceSize)
                }, 500)
              )
            }
            if (tradeSteps[tradeSteps?.length - 1].tradeSummaryFixStatus === 'TRADE_COMPLETED') {
              setProgressValue(initialProgressValue)
            }
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to load trade timeline data.')
        })
        .finally(() => {
          setStepperLoading(false)
        })
    } else {
      setStepperLoading(false)
    }
  }

  const resumeTrade = () => {
    if (row?.optDetails?.length) {
      setActionAPILoading(true)
      API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-flow/redrive/${row?.optDetails[0]?.accOptMapId}`)
        .then(response => {
          if (response?.success) {
            setShowConfirmationPopup(null)
            fetchTradeTimelineData(true)
            setTradingActionAnchorEl(null)
            showSuccess(response?.message || 'Trade resumed successfully.')
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to post resume trade.')
        })
        .finally(() => {
          setActionAPILoading(false)
        })
    }
  }

  const stopTrade = () => {
    if (row?.optDetails?.length) {
      setActionAPILoading(true)
      API.post('baseUriTrade', `trade/v1/${user?.userGroup}/trade-flow/stop/${row?.optDetails[0]?.accOptMapId}`)
        .then(response => {
          if (response?.success) {
            setShowConfirmationPopup(null)
            fetchTradeTimelineData(true)
            setTradingActionAnchorEl(null)
            showSuccess(response?.message || 'Trade execution stopped successfully.')
            updateOptmRunStatus({
              stopTrdExec: 1
            },
              row?.optDetails[0]?.accOptMapId
            )
          }
        })
        .catch(error => {
          showError(error, false, {}, 'Failed to stop trade execution.')
        })
        .finally(() => {
          setActionAPILoading(false)
        })
    }
  }

  const detailsPopupCloseHandler = () => {
    setCurrentClickedStep(null)
    setShowDetailsPopup(false)
    setStepsChanged(false)
  }

  const onConfirmClick = () => {
    if (showConfirmationPopup === 'stop-trade') {
      stopTrade()
    } else if (showConfirmationPopup === 'resume-trade') {
      resumeTrade()
    }
  }

  const openConfirmationPopup = (popupName) => {
    setShowConfirmationPopup(popupName)
    setTradingActionAnchorEl(null)
  }

  const openManualTradingPopup = () => {
    setShowTradeAdhocPopup(true)
    setTradingActionAnchorEl(null)
  }

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange
    )
  }, [apiRef, handleViewportInnerSizeChange])

  useEffect(() => {
    fetchTradeTimelineData(false)
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
        setIntervalId(null)
      }
    }
  }, [row])

  useEffect(() => {
    if (progressValue === (maxProgressValue + progressDifferenceSize)) {
      if (intervalId) {
        fetchTradeTimelineData(true)
      }
    }
  }, [progressValue])

  const refreshTrades = () => {
    setCurrentClickedStep(steps[currentClickedStep.index])
  }

  return (
    <>
      {
        actionAPILoading ? <Loader /> : ''
      }
      <Box sx={{
        py: 2,
        height: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        overflow: 'auto',
        left: 0,
        width
      }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'sticky',
          left: 0,
          right: 0,
          mb: 2
        }}
        >
          {
            refreshAccess
              ? (
                <Box sx={{ position: 'relative' }}>
                  <Button
                    variant='text'
                    disabled={stepperLoading}
                    onClick={() => fetchTradeTimelineData(true)}
                  >
                    Refresh
                  </Button>
                  {
                    steps?.length && steps[steps?.length - 1].tradeSummaryFixStatus !== 'TRADE_COMPLETED' && !stepperLoading
                      ? <Box sx={{ position: 'absolute', left: '8px', right: '8px', bottom: 0 }}>
                        <BorderLinearProgress variant='determinate' value={progressValue} />
                        </Box>
                      : ''
                  }
                </Box>
                )
              : ''
          }
          {
            (resumeTradeAccess || viewTradeAdhocAccess)
              ? (
                <>
                  <IconButton
                    aria-label='more'
                    aria-haspopup='true'
                    size='small'
                    onClick={(event) => {
                      setTradingActionAnchorEl(event.currentTarget)
                    }}
                  >
                    <MoreVertIcon sx={{
                      height: '16px',
                      width: '16px'
                    }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={tradingActionAnchorEl}
                    open={Boolean(tradingActionAnchorEl)}
                    onClose={() => {
                      setTradingActionAnchorEl(null)
                    }}
                    elevation={4}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    sx={{
                      '& .MuiMenuItem-root': {
                        color: '#34475A'
                      }
                    }}
                  >
                    {
                      resumeTradeAccess
                        ? (
                          <MenuItem
                            onClick={() => openConfirmationPopup('resume-trade')}
                            // if last step is failed or overall fix connectivity status is trade stopped, then enable resume trade
                            disabled={stepperLoading || !steps?.length || (steps?.length && steps[steps.length - 1].status !== 'failed' && row?.optDetails?.length && row?.optDetails[0]?.summaryDetails?.fixTradeStatus !== 'TRADE_STOPPED')}
                          >
                            Resume Trade
                          </MenuItem>
                          )
                        : ''
                    }
                    {
                      resumeTradeAccess
                        ? (
                          <MenuItem
                            onClick={() => openConfirmationPopup('stop-trade')}
                            disabled={stepperLoading || !steps?.length || (row?.optDetails?.length && (row?.optDetails[0]?.summaryDetails?.fixTradeStatus === 'TRADE_COMPLETED' || row?.optDetails[0]?.summaryDetails?.stopTrdExec === 1))}
                          >
                            Stop Trade
                          </MenuItem>
                          )
                        : ''
                    }
                    {
                      viewTradeAdhocAccess
                        ? (
                          <MenuItem
                            onClick={openManualTradingPopup}
                            disabled={stepperLoading}
                          >
                            Manual Trade
                          </MenuItem>
                          )
                        : ''
                    }
                  </Menu>
                </>)
              : ''
          }
        </Box>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {
            (
              stepperLoading
                ? stepperLoader
                : steps.length && steps?.[steps.length - 1]?.tradeSummaryFixStatus && steps?.[steps.length - 1]?.tradeSummaryFixStatus !== 'TRADE_COMPLETED'
                  ? [
                      ...steps,
                      {
                        progressStep: true,
                        status: 'pending'
                      }]
                  : steps
            ).map((step, index) => (
              <Step key={index}>
                <StepLabel
                  sx={{
                    '.MuiStepLabel-label': {
                      fontSize: '14px',
                      marginTop: '8px',
                      fontWeight: 600,
                      fontFamily: 'Open Sans',
                      color: '#33A68D !important'
                    },
                    '.Mui-completed': {
                      color: '#33A68D'
                    },
                    '.Mui-active': {
                      color: '#d29922 !important'
                    },
                    '.Mui-disabled': {
                      color: '#7B7B7B !important'
                    },
                    '.Mui-error': {
                      color: '#d32f2f !important'
                    },
                    '.MuiSvgIcon-root': {
                      height: 20,
                      width: 20
                    }
                  }}
                  error={step?.status === 'failed'}
                  StepIconComponent={(props) => (
                    // show cancel icon for current active step if statement is inactive
                    (step.status === 'failed')
                      ? <CancelIcon {...props} />
                      : step.status === 'inprogress'
                        ? <CircleIcon {...props} />
                        : step.status === 'pending'
                          ? <RadioButtonUncheckedOutlinedIcon {...props} />
                          : <CheckCircleIcon {...props} />
                  )}
                >
                  <Stack
                    display='inline-flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!stepperLoading && step.status !== 'pending' && !step.progressStep) {
                        setCurrentClickedStep(step)
                        setShowDetailsPopup(true)
                      }
                    }}
                  >
                    {
                      stepperLoading
                        ? (
                          <>
                            <Skeleton width={100} />
                            <small><Skeleton width={50} /></small>
                          </>
                          )
                        : (
                          <>
                            {step.stepLabel || ''}
                            {!step.stepLabel || step.stepLabel === 'Cash Transfer' || step.stepLabel === 'Start' ? '' : <small>{step.totalTradeCompletedCount}/{step.totalTradeCount}</small>}
                          </>
                          )
                    }
                  </Stack>
                </StepLabel>
              </Step>
            ))
        }
        </Stepper>
        {
          !stepperLoading && !steps?.length
            ? <Typography sx={{ textAlign: 'center', marginBottom: '30px' }}>No trade details available</Typography>
            : ''
        }
      </Box>
      {
        showTradeAdhocPopup ? <TradeAdhocPopup showTradeAdhocPopup={showTradeAdhocPopup} closeTradeAdhocPopup={() => setShowTradeAdhocPopup(false)} tradeRow={row} updateCurrentRow={updateCurrentRow} updateOptmRunStatus={updateOptmRunStatus} /> : ''
      }
      {
        showConfirmationPopup ? <ConfirmationPopup showConfirmationPopup={showConfirmationPopup} closeConfirmationPopup={() => setShowConfirmationPopup(null)} tradeRow={row} steps={steps} onConfirmClick={onConfirmClick} /> : ''
      }
      {
        showDetailsPopup && !['Cash Transfer', 'Security Transfer', 'Start', 'End'].includes(currentClickedStep?.stepLabel)
          ? <StepDetails
              showDetailsPopup={showDetailsPopup}
              closeDetailsPopup={detailsPopupCloseHandler}
              title={currentClickedStep.stepLabel}
              stats={`${currentClickedStep.totalTradeCompletedCount}/${currentClickedStep.totalTradeCount}`}
              rows={currentClickedStep?.flyerftDetails}
              activeStepDetails={currentClickedStep}
              stepsChanged={stepsChanged}
              refreshTrades={refreshTrades}
              isBlockTrade={row?.optDetails?.length ? row?.optDetails[0]?.isBlockTrade : false}
            /> : ''
      }
    </>
  )
}

export default TradeTimeline
