import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { HtmlTooltip } from './CustomTooltip'
import { IconButton, Typography } from '@mui/material'

dayjs.extend(utc)

const CustomDateComponent = (params) => {
  const { api } = params
  const isDateInFuture = dayjs(params.value).isAfter(dayjs().endOf('day'))
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(params?.row?.purchaseDate)}
        onChange={(date) => {
          const isInvalidDate = !dayjs(date).isValid()
          api.setEditCellValue({ id: params.id, field: 'selectedDate', value: isInvalidDate ? null : dayjs(date) })
          api.setEditCellValue({ id: params.id, field: 'purchaseDate', value: isInvalidDate ? null : dayjs(date) })
          api.updateRows([{
            id: params.id,
            hasDateError: !dayjs(date).isValid() || dayjs(date) === null || dayjs(date).isAfter(dayjs().endOf('day'))
          }])
        }}
        format='YYYY-MM-DD'
        maxDate={dayjs.utc()}
        slotProps={{
          textField: {
            size: 'small',
            sx: {
              padding: '0px 8px 0px 0px',
              '& .MuiOutlinedInput-input': {
                padding: '8px 7px 8px 8px !important',
                fontSize: '13px',
                fontWeight: 500
              },
              '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px'
              }
            }
          }
        }}
        sx={{
          padding: '8px 0px 8px 8px'
        }}
      />
      {
        isDateInFuture
          ? <HtmlTooltip title={
            <Typography sx={{ fontSize: '14px' }}>
              {
               isDateInFuture
                 ? 'Purchase date can not be in future'
                 : ''
              }
            </Typography>
          }
            >
            <IconButton>
              <InfoOutlinedIcon sx={{ fontSize: '15px', color: '#D32F2F' }} />
            </IconButton>
          </HtmlTooltip>
          : ''
  }
    </LocalizationProvider>
  )
}

export default CustomDateComponent
