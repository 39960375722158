import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'

const CustomEditDate = (props) => {
  const { id, field, value: valueProp, minDate } = props
  const apiRef = useGridApiContext()
  const [value, setValue] = useState(valueProp)

  const formatDate = (date) => {
    const parsedDate = date ? date?.split('T')[0] : null
    return parsedDate
  }

  const handleDateChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)
    const selectedRows = Array.from(apiRef.current.getSelectedRows().values())
    const isCurrentRowChecked = selectedRows.some(row => row.id === id)
    // If the current row is checked, update the date for all checked rows
    if (isCurrentRowChecked) {
      selectedRows.forEach((row) => {
        apiRef.current.setEditCellValue({
          id: row.id,
          field: 'endDate',
          value: newValue
        })
      })
    } else {
      // If the current row is not checked, update just this row
      apiRef.current.setEditCellValue({
        id,
        field,
        value: newValue
      })
    }
  }

  useEffect(() => { setValue(valueProp) }, [valueProp])

  return (
    <input
      type='date'
      value={formatDate(value)}
      onChange={handleDateChange}
      min={minDate}
      style={{ height: '100%', width: '100%', background: 'rgba(25, 118, 210, 0)', outline: 'none', border: 'none' }}
    />
  )
}

export default CustomEditDate
