import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Card, CardContent, Grid, TablePagination, Typography } from '@mui/material'
import { formatCurrency } from '../../utils/FormateCurrenyInMilion'
import { useAccountListContext } from '.'

const AccountGrid = () => {
  const { accounts } = useAccountListContext()
  const [page, setPage] = useState(0)
  const cardsPerPage = 12
  const startIndex = page * cardsPerPage
  const endIndex = startIndex + cardsPerPage
  const [accountsToDisplay, setAccountsToDisplay] = useState(accounts.slice(startIndex, endIndex))

  // recalculate account to display when page changes
  useEffect(() => {
    const startIndex = page * cardsPerPage
    const endIndex = startIndex + cardsPerPage
    setAccountsToDisplay(accounts.slice(startIndex, endIndex))
  }, [page])

  return (
    <Box className='grid-view'>
      <Grid container spacing={3}>
        {accounts?.length
          ? <>
            {
              (accountsToDisplay || []).map((item, index) => {
                return (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <Card variant='outlined' className='grid-card'>
                      <CardContent>
                          <Link className='linkId' to={`/account-review/account-dashboard/${item.accountId}`}>
                            <Box className='client' mt={{ xs: 3, md: 0 }}>
                              <Box sx={{
                                borderRadius: '4px',
                                width: '3.05px',
                                height: '53.8px',
                                marginRight: 2,
                                background: item?.accountType === 'TRADING' ? '#169D6C' : item?.accountType === 'TRANSITION' ? '#EFAE3A' : ''
                              }}
                              />
                              <Box>
                                <Typography variant='h6'>
                                  Mr {item.accountName}
                                </Typography>
                                <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem'> {item.strategyName}</Typography>
                              </Box>
                            </Box>

                            <Grid container>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Total Market Value</Typography>
                                <Typography variant='body1' className='value'>
                                  ${formatCurrency(item.totalMarketValue || 0)}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Unrealized Gain/Loss</Typography>
                                <Typography variant='body1' className='value'>
                                  {item.unrealizedGainLoss < 0
                                    ? (
                                      <>
                                        -${formatCurrency(item.unrealizedGainLoss || 0)} ({(Number(item.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)
                                      </>
                                      )
                                    : (
                                      <>
                                        ${formatCurrency(item.unrealizedGainLoss || 0)} ({(Number(item.unrealizedGainLossWeight || 0) * 100).toFixed(2)}%)
                                      </>
                                      )}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Asset Allocation Drift</Typography>
                                <Box display='flex' alignItems='center' justifyContent='flex-start'>
                                  <div className={Number(item.assetAllocationDrift) < 0.6666 ? 'progressbar bg-blue' : 'progressbar'} />
                                  <div className={(Number(item.assetAllocationDrift) > 0.6666 && Number(item.assetAllocationDrift) < 1.3333) ? 'progressbar bg-blue' : 'progressbar'} />
                                  <div className={Number(item.assetAllocationDrift) > 1.3333 ? 'progressbar bg-blue' : 'progressbar'} />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Tracking Error</Typography>
                                <Typography variant='body1' className='value'>
                                  {(Number(item.trackingError || 0) * 100).toFixed(2)}%
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Income/Div Yield</Typography>
                                <Typography variant='body1' className='value'>
                                  {(Number(item.incomeDivYield || 0) * 100).toFixed(2)}%
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='h5' className='text-title'>Available Cash</Typography>
                                <Typography variant='body1' className='value'>
                                  {(Number(item.cashLevel || 0) * 100).toFixed(2)}%
                                </Typography>
                              </Grid>
                            </Grid>
                          </Link>
                        </CardContent>
                    </Card>
                  </Grid>
                )
              })
            }
            </>
          : <Grid item xs={12}><Typography component='div'>No Data</Typography></Grid>}
      </Grid>
      <TablePagination
        component='div'
        count={accounts.length}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage)
        }}
        rowsPerPage={cardsPerPage}
        rowsPerPageOptions={[]}
      />
    </Box>
  )
}

export default AccountGrid
