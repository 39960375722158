import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { MODEL_WEIGHT_DISPLAY_MODULES } from '../../../contstants/constants'
import useDownloadLogs from '../../../hooks/useDownloadLogs'

const ExportCSV = ({
  value,
  currentPositionValue,
  securityLevelTradeData,
  optmRunSumPro,
  positionData,
  sectorData,
  industryData,
  subIndustryData,
  companyWiseData,
  tradeBlotterData,
  optData,
  accountCode,
  optMapId,
  optId,
  isDILink,
  canAccessModelChangeWithTrade
}) => {
  const [exportCSVAnchorEl, setExportCSVAnchorEl] = useState(null)
  const { createCSVDownloadLog } = useDownloadLogs()

  const downloadCsv = (summaryOnly) => {
    let fileName = ''
    let dataCsv = null

    if (value === 'Trade') {
      const headers = ['Instrument ID', ...(canAccessModelChangeWithTrade ? ['Local Symbol'] : []), 'Trade Code',
        ...(canAccessModelChangeWithTrade ? ['Model Drift', 'Trade Drift'] : []),
        ...(isDILink ? ['Transfer In Weight (%)', 'Transfer Out Weight (%)'] : []),
        'Initial Shares', 'Proposed Shares', ...(canAccessModelChangeWithTrade ? ['Price By Market Value', 'Live Price RGL', 'Trade Value'] : []),
        ...(isDILink ? ['Transfer In Quantity', 'Transfer Out Quantity'] : []),
        'Trade Quantity']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < securityLevelTradeData?.length; i++) {
        const {
          instr_id, localSymbol, trdCode, initShares, propShares, transferInWeight, transferOutWeight, trdQty,
          modelDrift, tradeDrift, priceByMktVal, livePriceRgl, tradeValue, transferInShares, transferOutShares
        } = securityLevelTradeData[i]
        dataCsv.push([
          instr_id || '', ...(canAccessModelChangeWithTrade ? [localSymbol || ''] : []), trdCode || '',
          ...(canAccessModelChangeWithTrade ? [modelDrift, tradeDrift] : []),
          ...(isDILink ? [transferInWeight, transferOutWeight] : []),
          initShares, propShares, ...(canAccessModelChangeWithTrade ? [priceByMktVal, livePriceRgl, tradeValue] : []),
          ...(isDILink ? [transferInShares, transferOutShares] : []),
          trdQty
        ].join(','))
      }
      fileName = 'trade'
    } else if (value === 'Taxlot') {
      const headers = ['Instrument ID', 'Local Symbol', 'Trade Reason', 'Trade Code', 'Trade Quantity',
        ...(isDILink ? ['Transfer Code', 'Transfer In Weight (%)', 'Transfer Out Weight (%)', 'Source Account', 'Destination Account'] : []),
        'Trade Value', 'Initial Shares', 'Proposed Shares', 'Initial Weight', 'Proposed Weight',
        'Initial Market Value', 'Proposed Market Value', 'Age', 'Cost Basis', 'Purchase Date',
        'Original Purchase Price', 'Last Closed Price', 'Initial Unrealized Gain', 'Live Price',
        'Realized Gain', 'RGL (Live)', 'Trade Date']

      dataCsv = [headers.join(',')]

      for (let i = 0; i < optmRunSumPro?.length; i++) {
        const {
          instrId, localSymbol, tradeTags, trdCode, trdQty,
          transferCode, transferInWeight, transferOutWeight, srcAccount, dstAccount,
          trdValue, initShares, propShares, initWeight, propWeight, initMarketValue, propMarketValue,
          age, costBasis, purchaseDate, orgPurchasePrice, liveMarketPrice,
          lastClosePrice, rglLive, initUrgl, rgl, trdDate
        } = optmRunSumPro[i]

        const tradeTag = JSON.stringify(tradeTags?.map(tag => tag.reasonCd).join(', ')) || ''

        dataCsv.push([
          instrId || '', localSymbol || '', tradeTag, trdCode || '', trdQty || '',
          ...(isDILink ? [transferCode || '', transferInWeight || '', transferOutWeight || '', srcAccount || '', dstAccount || ''] : []),
          trdValue || '', initShares || '', propShares || '', initWeight || '', propWeight || '',
          initMarketValue || '', propMarketValue || '', age || '', costBasis || '', purchaseDate ? purchaseDate.split('T')[0] : '',
          orgPurchasePrice || '', lastClosePrice || '', initUrgl || '', liveMarketPrice || '',
          rgl || '', rglLive || '', trdDate ? trdDate.split('T')[0] : ''
        ].join(','))
      }

      fileName = 'taxlot'
    } else if (value === 'Position') {
      if (currentPositionValue === 'security') {
        fileName = currentPositionValue
        const headers = ['Instrument ID', 'Trade Code', 'Model Weight', 'Price By Market Value', 'Initial Weight', 'Proposed Weight', 'Trade Weight',
          ...(isDILink ? ['Transfer In Weight (%), Transfer Out Weight (%)'] : []),
          'Proposed Drift', 'Initial Drift', 'Trade Drift']
        dataCsv = [headers.join(',')]

        for (let i = 0; i < positionData?.length; i++) {
          const { instr_id, trdCode, initDrift, initWeight, modelWeight, priceByMktVal, propDrift, propWeight, tradeDrift, trdWeight, transferInWeight, transferOutWeight } = positionData[i]
          dataCsv.push([instr_id || '', trdCode || '', modelWeight, priceByMktVal, initWeight, propWeight, trdWeight,
            ...(isDILink ? [transferInWeight, transferOutWeight] : []),
            propDrift, initDrift, tradeDrift].join(','))
        }
        createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SECURITY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
      } else {
        fileName = summaryOnly ? currentPositionValue + '-summary' : currentPositionValue
        let headers = []
        if (summaryOnly) {
          headers = ['', 'Model Weight', 'Initial Weight', 'Proposed Weight',
            ...(isDILink ? ['Transfer In Weight (%), Transfer Out Weight (%)'] : []),
            'Proposed Drift', 'Initial Drift', 'Trade Drift']
        } else {
          headers = ['', 'Company Name', 'Model Weight', 'Initial Weight', 'Proposed Weight',
            ...(isDILink ? ['Transfer In Weight (%), Transfer Out Weight (%)'] : []),
            'Proposed Drift', 'Initial Drift', 'Trade Drift']
        }

        if (currentPositionValue === 'sector') {
          headers[0] = 'Sector'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < sectorData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift, transferInWeight, transferOutWeight } = sectorData[i]
            if (summaryOnly && hierarchy.length === 1) {
              dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift])
            } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1] || '', modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SECTOR_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'industry') {
          headers[0] = 'Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < industryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift, transferInWeight, transferOutWeight } = industryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1] || '', modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_INDUSTRY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'sub-industry') {
          headers[0] = 'Sub Industry'
          dataCsv = [headers.join(',')]

          for (let i = 0; i < subIndustryData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift, transferInWeight, transferOutWeight } = subIndustryData[i]
            if (summaryOnly && hierarchy.length === 1) { dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift]) } else if (!summaryOnly) { dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1] || '', modelWeight, initWeight, propWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift]) }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_SUBINDUSTRY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        } else if (currentPositionValue === 'company') {
          headers = ['Company Name', ...(summaryOnly ? [] : ['Instrument']), 'Model Weight', 'Initial Weight', 'Proposed Weight', 'Trade Weight',
            ...(isDILink ? ['Transfer In Weight (%), Transfer Out Weight (%)'] : []),
            'Proposed Drift', 'Initial Drift', 'Trade Drift']
          dataCsv = [headers.join(',')]
          for (let i = 0; i < companyWiseData.length; i++) {
            const { hierarchy, modelWeight, initWeight, initDrift, propWeight, propDrift, tradeDrift, tradeWeight, transferInWeight, transferOutWeight } = companyWiseData[i]
            if (summaryOnly && hierarchy.length === 1) {
              dataCsv.push([hierarchy[0], modelWeight, initWeight, propWeight, tradeWeight,
                ...(isDILink ? [transferInWeight, transferOutWeight] : []),
                propDrift, initDrift, tradeDrift])
            } else if (!summaryOnly) {
              dataCsv.push([hierarchy[0], hierarchy[hierarchy.length - 1], modelWeight, initWeight, propWeight, tradeWeight, ...(isDILink ? [transferInWeight, transferOutWeight] : []), propDrift, initDrift, tradeDrift])
            }
          }
          createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_COMPANY_POSITION, optMapId?.index, `${accountCode}-${fileName?.toUpperCase()}.csv`)
        }
      }
    } else if (value === 'Blotter') {
      const headers = ['Instrument Id', 'Local Symbol', 'Last Close Price', 'Live Price', 'Market Value',
        'Live Market Value', 'Proposed Market Value', 'Price Change (%)', 'Trade Value Change', 'Side', 'Quantity',
        ...(isDILink ? ['Transfer In Quantity', 'Transfer Out Quantity'] : []),
        'Split Effective Date', 'Split Ratio', 'Delist Effective Date']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < tradeBlotterData?.length; i++) {
        const {
          instrId, localSymbol, lastClosePrice, livePrice, marketValue, liveMarketValue, priceChange, marketValueChange, side,
          quantity, transferInShares, transferOutShares, splitEffectiveDate, splitRatio, delistEffectiveDate, propMarketValue
        } = tradeBlotterData[i]

        // Format the priceChange and marketValueChange values as percentages with two decimal places
        const formattedPriceChange = (priceChange * 100).toFixed(2)
        const formattedMarketValueChange = (marketValueChange * 100).toFixed(2)

        dataCsv.push([instrId, localSymbol || '', lastClosePrice, livePrice, marketValue,
          liveMarketValue, propMarketValue, formattedPriceChange, formattedMarketValueChange, side, quantity,
          ...(isDILink ? [transferInShares || '', transferOutShares || ''] : []),
          splitEffectiveDate || '', splitRatio || '', delistEffectiveDate || ''].join(','))
      }
      fileName = 'blotter'
    } else if (value === 'Model Variance') {
      const headers = ['Instrument Id', 'Trade Code', 'Previous Weight', 'Current Weight', 'Weight Change']
      dataCsv = [headers.join(',')]

      for (let i = 0; i < optData?.length; i++) {
        const {
          instrId, trdCode, previousSecurityWeight, currentSecurityWeight, drift
        } = optData[i]

        dataCsv.push([instrId || '', trdCode || '', previousSecurityWeight || '', currentSecurityWeight || '', drift || ''].join(','))
      }
      fileName = 'model-variance'
      createCSVDownloadLog(MODEL_WEIGHT_DISPLAY_MODULES.TRADE_MODEL_VARIANCE, optId, `${accountCode}-${fileName?.toUpperCase()}.csv`)
    }

    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create a temporary link and trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', `${accountCode}-${fileName?.toUpperCase()}.csv`)
    tempLink.click()

    // Clean up the URL object
    URL.revokeObjectURL(csvUrl)
  }

  const showExportCSVButton = () => {
    if ((value === 'Trade') && (securityLevelTradeData?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Taxlot') && (optmRunSumPro?.length > 0)) {
      return {
        summaryExport: false,
        export: true
      }
    } else if ((value === 'Position')) {
      if ((value === 'Position' && currentPositionValue === 'security') && (positionData?.length > 0)) {
        return {
          summaryExport: false,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sector') && (sectorData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'industry') && (industryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'sub-industry') && (subIndustryData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      } else if ((value === 'Position' && currentPositionValue === 'company') && (companyWiseData?.length > 0)) {
        return {
          summaryExport: true,
          export: true
        }
      }
    } else if (value === 'Blotter') {
      if (tradeBlotterData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    } else if (value === 'Model Variance') {
      if (optData?.length > 0) {
        return {
          summaryExport: false,
          export: true
        }
      }
    }
    return {
      summaryExport: false,
      export: false
    }
  }

  const exportButtonConfig = showExportCSVButton()

  return (
    <>
      {exportButtonConfig.export || exportButtonConfig.summaryExport
        ? (
          <>
            <Button
              sx={{ textTransform: 'none' }}
              onClick={(e) => setExportCSVAnchorEl(e.currentTarget)}
            >
              Export CSV
            </Button>
            <Menu
              open={Boolean(exportCSVAnchorEl)}
              onClose={() => setExportCSVAnchorEl(null)}
              anchorEl={exportCSVAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              MenuListProps={{
                sx: { py: '4px' }
              }}
            >
              {exportButtonConfig.summaryExport && (
                <MenuItem
                  sx={{ fontSize: '14px' }}
                  onClick={() => {
                    downloadCsv(true)
                    setExportCSVAnchorEl(null)
                  }}
                >
                  Export Summary CSV
                </MenuItem>
              )}
              {exportButtonConfig.export && (
                <MenuItem
                  sx={{ fontSize: '14px' }}
                  onClick={() => {
                    downloadCsv(false)
                    setExportCSVAnchorEl(null)
                  }}
                >
                  Export Detailed CSV
                </MenuItem>
              )}
            </Menu>
          </>
          )
        : null}
    </>
  )
}

export default ExportCSV
