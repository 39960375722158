import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, TextField, Typography } from '@mui/material'
import { HtmlTooltip } from './CustomTooltip'
import { isNumberOnly, numberOnlyPasteHandler } from '../utils/NumberUtils'

const CustomPurchaseCostField = (params) => {
  const { id, field, api } = params
  const purchaseCostError = params.value === '' || params.value === null || params.value === undefined
  return (
    <>
      <TextField
        type='number'
        value={params?.value}
        onKeyDown={(event) => isNumberOnly(event)}
        onPaste={numberOnlyPasteHandler}
        onChange={(e) => {
          const value = e.target.value
          api.setEditCellValue({ id, field, value })
          api.updateRows([{
            id,
            hasPurchaseCostError: value === '' || value === null || value === undefined
          }])
        }}
        sx={{
          padding: '8px 8px 8px 0px',
          width: '100%',
          maxWidth: '150px',
          '& .MuiInputBase-input': {
            padding: '8px 1px 8px 7px !important',
            fontSize: '13px',
            fontWeight: 500
          },
          '& .MuiFormHelperText-root': {
            fontSize: '10px',
            padding: '0px',
            marginLeft: '0px'
          }
        }}
      />
      {
      purchaseCostError
        ? <HtmlTooltip title={
          <Typography sx={{ fontSize: '14px' }}>
            {
              purchaseCostError ? 'Purchase Cost Required' : ''
            }
          </Typography>
        }
          >
          <IconButton>
            <InfoOutlinedIcon sx={{ fontSize: '15px', color: '#D32F2F' }} />
          </IconButton>
        </HtmlTooltip>
        : ''
    }
    </>
  )
}

export default CustomPurchaseCostField
