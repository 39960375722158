import { useState } from 'react'
import { API } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import dayjs, { utc } from 'dayjs'
import Papa from 'papaparse'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import { DataGridPro, GridActionsCellItem, gridClasses, GridFooterContainer, GridPagination, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { HtmlTooltip } from '../../../components/CustomTooltip'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import Loader from '../../Loader'

dayjs.extend(utc)

const csvConfig = {
  headers: [
    {
      name: 'Local Symbol',
      inputName: 'localSymbol',
      required: true
    },
    {
      name: 'Restriction Code',
      inputName: 'restrictionCode',
      required: true
    },
    {
      name: 'Country Code',
      inputName: 'countryCode',
      required: true
    },
    {
      name: 'Account Code',
      inputName: 'accountCd',
      required: true
    },
    {
      name: 'Start Date',
      inputName: 'startDate',
      required: true
    },
    {
      name: 'End Date',
      inputName: 'endDate',
      required: false
    }
  ]
}

const CustomFooterComponent = ({ restrictionRowCount }) => {
  return (
    <GridFooterContainer sx={{ justifyContent: restrictionRowCount ? 'space-between' : 'flex-end', alignItems: 'center', paddingX: '8px', backgroundColor: '#fff', zIndex: 1, flexWrap: 'nowrap' }}>
      {
        restrictionRowCount
          ? (
            <Typography variant='small'>
              *Restrictions with past start dates will be reset to today's date.
            </Typography>
            )
          : ''
      }
      <GridPagination />
    </GridFooterContainer>
  )
}

const FileUploadToolbar = (props) => {
  const {
    fileInputRef, rows, setIsRestrictionPreProcessApiLoading, setRows, setData , setSrcAccNames
  } = props
  const [errorModalContent, setErrorModalContent] = useState([])
  const [errorPopupTitle, setErrorPopupTitle] = useState('')
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [reviewPopUpTableData, setReviewPopUpTableData] = useState([])
  const [uploadRestrictionApiLoading, setUploadRestrictionApiLoading] = useState(false)
  const { user } = useAuth()
  const params = useParams()
  const { showSuccess } = useSuccessToast()
  const { showError, sendSentryError } = useErrorToast()

  const downloadGroupRestrictionCsv = () => {
    const headers = ['Local Symbol', 'Security Name', 'Restriction Code', 'Country Code', 'Account Code', 'Start Date', 'End Date']
    const dataCsv = [headers.join(',')]
    rows?.forEach(row => {
      const {
        localSymbol: symbol,
        instrName: name,
        restrictionCode,
        countryCode = '',
        sourceAccountCode,
        startDate,
        endDate
      } = row

      const formatDate = (date) => {
        if (!date) return ''
        const dateInCurrentTimezone = new Date(date)
        const d = new Date(dateInCurrentTimezone?.getTime() + dateInCurrentTimezone?.getTimezoneOffset() * 1000 * 60)
        const year = d.getFullYear()
        const month = String(d.getMonth() + 1).padStart(2, '0')
        const day = String(d.getDate()).padStart(2, '0')
        return `${year}${month}${day}`
      }

      dataCsv?.push([
        symbol || '',
        name || '',
        restrictionCode || '',
        countryCode,
        sourceAccountCode,
        formatDate(startDate),
        formatDate(endDate)
      ].join(','))
    })
    const csvBlob = new Blob([dataCsv.join('\n')], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvBlob)
    const tempLink = document.createElement('a')
    tempLink.href = csvUrl
    tempLink.setAttribute('download', 'Group-Restrictions.csv')
    tempLink.click()
    URL.revokeObjectURL(csvUrl)
  }

  const getRestrictionTableData = (data, retry = 2) => {
    API.post('baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/preprocess-bulk-restrictions/${params?.aggGroupId}`, {
      body: [...data]
    }).then((res) => {
      if (res && res?.data) {
        const sortedData = res?.data.sort((a, b) => {
          const aData = a.hasError ? 1 : 0
          const bData = b.hasError ? 1 : 0
          return bData - aData
        })
        setReviewPopUpTableData(sortedData.map(data => ({ ...data, id: randomId() })))
        setShowReviewModal(true)
      }
    }).catch((error) => {
      if (retry > 0 && error.response?.data?.errorInfo?.data?.timeoutReached) {
        getRestrictionTableData(data, retry - 1)
      } else {
        showError(error, false, {}, 'Issue in file processing. Please re-upload file.')
        setIsRestrictionPreProcessApiLoading(false)
      }
    }).finally(() => {
      setIsRestrictionPreProcessApiLoading(false)
      fileInputRef.current.value = ''
    })
  }

  const invalidErrorModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 3,
    py: 2,
    width: '100%',
    minWidth: '600px',
    maxWidth: '800px',
    borderRadius: '4px',
    height: '650px',
    maxHeight: '100vh'
  }

  const reviewTableColumns = [
    {
      field: 'localSymbol',
      headerName: 'Local Symbol',
      flex: 1
    },
    {
      field: 'restrictionCode',
      headerName: 'Restriction Code',
      flex: 1
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      flex: 1
    },
    {
      field: 'accountCd',
      headerName: 'Account Code',
      flex: 1
    },
    {
      field: 'startDate',
      type: 'date',
      headerName: 'Start Date',
      flex: 1,
      valueGetter: (params) => {
        const startDate = params?.row?.startDate ? params?.row?.startDate || '' : null
        if (!startDate) return null
        const date = new Date(startDate)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.startDate ? params?.row?.startDate?.split('T')[0] || '' : ''
    },
    {
      field: 'endDate',
      type: 'date',
      headerName: 'End Date',
      flex: 1,
      valueGetter: (params) => {
        const endDate = params?.row?.endDate ? params?.row?.endDate || '' : null
        if (!endDate) return null
        const date = new Date(endDate)
        return new Date(date?.getTime() + date?.getTimezoneOffset() * 1000 * 60)
      },
      renderCell: (params) => params?.row?.endDate ? params?.row?.endDate?.split('T')[0] || '' : ''
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            sx={{ visibility: params?.row?.hasError ? 'visible' : 'hidden' }}
            key={params.id}
            icon={<HtmlTooltip
tooltipStyle={{ marginRight: '10px' }}
              title={
                <Typography sx={{ fontSize: '14px' }} my={1}>{params?.row?.errorMessage.join(',') || 'N/A'}</Typography>
              }
                  >
              <InfoOutlinedIcon />
                  </HtmlTooltip>}
            label='comment'
            color='inherit'
          />,
          <GridActionsCellItem
            key={params.id}
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleRowEntryDeleteClick(params)}
            color='inherit'
          />
        ]
      }
    }
  ]

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (!file) return
    const fileSize = (file.size / 1024).toFixed(2) // KB
    if (file.type !== 'text/csv' && file.type !== 'text/plain' && file.type !== 'application/vnd.ms-excel') {
      showError('Please upload csv or text file')
      event.target.value = ''
    } else if (fileSize > 1024) {
      showError('Please upload file size less than 1 MB')
      event.target.value = ''
    } else {
      // Passing file data to parse using Papa.parse
      Papa.parse(file, {
        delimitersToGuess: [','],
        skipEmptyLines: true,
        complete: (results) => {
          const errors = {
            headerErrors: [],
            rowErrors: []
          }
          const portfolioJsonData = []
          if (results?.data?.length) {
            const headersArray = results.data[0]
            const originalFileContent = results.data

            // Find mandatory columns are present or not and store error message for missing header
            const mandatoryColumns = csvConfig.headers.filter(column => column.required)
            const validationOfColumns = mandatoryColumns.every(ele =>
              headersArray.some(header => header.replace(/\s+/g, '').toLowerCase() === ele.name.replace(/\s+/g, '').toLowerCase())
            )

            if (!validationOfColumns) {
              const headerErrors = mandatoryColumns.filter(column =>
                !headersArray.some(header => header.replace(/\s+/g, '').toLowerCase() === column.name.replace(/\s+/g, '').toLowerCase())
              ).map(column => `Header name ${column.name} is not correct or missing`)
              errors.headerErrors = [...headerErrors]
            }

            // Store index of column position in file data
            const positionOfColumnInFileJson = {}
            for (const column of csvConfig.headers) {
              // Ignore column without target field
              if (column?.inputName) {
                positionOfColumnInFileJson[column?.inputName] = headersArray.findIndex(name =>
                  name.replace(/\s+/g, '').toLowerCase() === column?.name.replace(/\s+/g, '').toLowerCase()
                )
              }
            }

            for (let rowIndex = 1; rowIndex < originalFileContent.length; rowIndex++) {
              const columns = originalFileContent[rowIndex]
              const rowJson = {}
              csvConfig?.headers?.forEach(header => {
                // Ignore column without target field
                if (header?.inputName) {
                  rowJson[header?.inputName] = (positionOfColumnInFileJson && columns[positionOfColumnInFileJson[header?.inputName]]) || null
                }
              })

              // If all mandatory columns are present, store row
              if (mandatoryColumns.every(header => rowJson[header?.inputName])) {
                portfolioJsonData.push({ ...rowJson })
              } else {
                errors.rowErrors = [...errors?.rowErrors, `Mandatory field ${mandatoryColumns.map(column => column?.name || '').join(' or ')} is missing in the row ${rowIndex}`]
              }
            }
            if ((errors?.rowErrors?.length > 0) || (errors?.headerErrors?.length > 0)) {
              setErrorModalContent(errors)
              setIsErrorModelOpen(true)
              setErrorPopupTitle('Missing Information in Uploaded File')
            } else {
              setErrorModalContent([])
              if (Array.isArray(portfolioJsonData) && portfolioJsonData?.length > 0) {
                const updatedCsvData = portfolioJsonData?.map((item) => (
                  {
                    localSymbol: item?.localSymbol,
                    countryCode: item?.countryCode,
                    restrictionCode: item?.restrictionCode,
                    accountCd: item?.accountCd,
                    startDate: item.startDate,
                    ...(item?.endDate ? { endDate: item.endDate } : {})
                  }
                ))
                setIsRestrictionPreProcessApiLoading(true)
                getRestrictionTableData(updatedCsvData || portfolioJsonData)
              } else {
                showError('No rows available in the uploaded file. Please re-upload file with rows')
              }
            }
          }
        }
      })
    }
  }

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleRowEntryDeleteClick = (params) => () => {
    setReviewPopUpTableData((prevData) => prevData.filter((row) => row.id !== params.id))
  }

  const uploadBulkRestrictionApi = (csvDataProcessed, retry = 2) => {
    setUploadRestrictionApiLoading(true)
    API.post('baseUriAggregateMaster2', `aggregate-master/v1/${user?.userGroup}/agg-group/bulk-restrictions/${params?.aggGroupId}`, {
      body: csvDataProcessed?.map(({ startDate, endDate, isExternal, accountId, instrId, restrictionId }) => ({
        startDate,
        endDate,
        isExternal,
        accountId,
        instrId,
        restrictionId
      }))
    }).then((res) => {
      if (res && res?.success) {
        showSuccess(res?.message)
        if (res?.data?.length) {
          const newRows = res?.data?.map(obj => ({ ...obj, id: randomId(), isNew: true }))
          setData((oldRows) => [...newRows, ...oldRows])
          setRows((oldRows) => [...newRows, ...oldRows])
          setSrcAccNames(prevSet => [...new Set([...prevSet, ...newRows.map(row => row.sourceAccName)])])
        }
      }
    }).catch((error) => {
      if (retry > 0 && error.response?.data?.errorInfo?.data?.timeoutReached) {
        uploadBulkRestrictionApi(csvDataProcessed, retry - 1)
      } else {
        showError(error, false, {}, 'Failed to upload bulk restrictions.')
        setUploadRestrictionApiLoading(false)
      }
    }).finally(() => {
      setUploadRestrictionApiLoading(false)
      setShowReviewModal(false)
    })
  }

  return (
    <>
      {uploadRestrictionApiLoading ? <Loader /> : null}
      <Tooltip title='Upload Bulk Restrictions'>
        <IconButton
          variant='outlined'
          size='small'
          sx={{
            minWidth: 'auto',
            border: '2px solid #dee2e6',
            padding: 1,
            borderRadius: '10px',
            color: 'rgb(52, 71, 90)',
            marginRight: '0px',
            textAlign: 'center',
            ':hover': {
              background: 'transparent',
              border: '2px solid #dee2e6'
            }
          }}
          className='tooltip-trade'
          onClick={handleFileUpload}
        >
          <FileUploadOutlinedIcon fontSize='12px' />
        </IconButton>
        <input
          type='file'
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'none',
            opacity: 0,
            cursor: 'pointer'
          }}
          ref={fileInputRef}
          onChange={handleFileChange}
          accept='.csv,.txt'
        />
      </Tooltip>
      <Tooltip title='Download Restrictions'>
        <IconButton
          variant='outlined'
          size='small'
          sx={{
            minWidth: 'auto',
            border: '2px solid #dee2e6',
            padding: 1,
            borderRadius: '10px',
            color: 'rgb(52, 71, 90)',
            marginRight: '0px',
            textAlign: 'center',
            ':hover': {
              background: 'transparent',
              border: '2px solid #dee2e6'
            }
          }}
          className='tooltip-trade'
          onClick={() => downloadGroupRestrictionCsv()}
        >
          <FileDownloadOutlinedIcon fontSize='12px' />
        </IconButton>
      </Tooltip>
      {
        isErrorModelOpen && (
          <Modal
            open={isErrorModelOpen}
            oonClick={() => {
              setIsErrorModelOpen(false)
              fileInputRef.current.value = ''
            }}
            centered
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box sx={invalidErrorModalStyle} overflow='auto'>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography id='modal-title' variant='h6' component='h2'>
                  {errorPopupTitle}
                </Typography>
                <IconButton onClick={() => {
                  setIsErrorModelOpen(false)
                  fileInputRef.current.value = ''
                }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box sx={{ mt: 2 }}>
                {(errorModalContent?.headerErrors?.length > 0 || errorModalContent?.rowErrors?.length > 0) && (
                  <TableContainer>
                    <Table
                      stickyHeader
                      sx={{
                        width: '100%',
                        '.MuiTableCell-root': {
                          padding: '6px 8px !important'
                        }
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell align='left'>Error Message</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {errorModalContent?.headerErrors?.length
                          ? <TableRow key='header'>
                            <TableCell width='80px' sx={{ verticalAlign: 'top' }}>Header</TableCell>
                            <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                              <Box sx={{ maxHeight: errorModalContent?.rowErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                                <ul>
                                  {errorModalContent?.headerErrors?.map((errorMessage, index) => (
                                    <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                      <Typography key={index} sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                        {errorMessage}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </TableCell>
                            </TableRow>
                          : ''}
                        {errorModalContent?.rowErrors?.length
                          ? <TableRow key='rows'>
                            <TableCell sx={{ verticalAlign: 'top' }}>Rows</TableCell>
                            <TableCell sx={{ color: '#34475a', p: '4px', fontWeight: 600 }}>
                              <Box sx={{ maxHeight: errorModalContent?.headerErrors?.length === 0 ? '80vh' : '40vh', overflowY: 'auto' }}>
                                <ul>
                                  {errorModalContent?.rowErrors?.map((errorMessage, index) => (
                                    <li key={index} style={{ marginBottom: '4px', marginLeft: '20px' }}>
                                      <Typography sx={{ whiteSpace: 'normal', wordBreak: 'break-word', color: '#34475a', fontWeight: 600 }}>
                                        {errorMessage}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </TableCell>
                            </TableRow>
                          : ''}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Modal>
        )
      }
      {showReviewModal && (
        <Dialog
          open={showReviewModal} fullScreen
        >
          <DialogTitle>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' style={{ fontWeight: 400, color: '#34475A' }}>Upload Restriction</Typography>
              <IconButton
                onClick={() => {
                  setShowReviewModal(false)
                  fileInputRef.current.value = ''
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DataGridPro
              rows={reviewPopUpTableData}
              columns={reviewTableColumns}
              density='compact'
              disableRowSelectionOnClick
              autoHeight
              getRowId={(row) => row?.id}
              pagination
              pageSizeOptions={[15, 25, 50, 100]}
              getRowClassName={(params) => {
                return params.row.hasError ? 'error-row' : ''
              }}
              initialState={{
                ...reviewPopUpTableData?.initialState,
                pagination: { paginationModel: { pageSize: 15 } }
              }}
              slots={{
                footer: CustomFooterComponent
              }}
              slotProps={{
                footer: { restrictionRowCount: reviewPopUpTableData?.length }
              }}
              sx={{
                '& .error-row': {
                  backgroundColor: '#f05f5f1a',
                  '&:hover': {
                    backgroundColor: '#f05f5f1a'
                  }
                },
                '& .MuiDataGrid-cell': {
                  cursor: 'pointer'
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important'
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none !important'
                },
                [`.${gridClasses.main}`]: {
                  height: 'calc(100vh - 72px - 55px - 60px)'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                },
                [`.${gridClasses.columnHeaderTitleContainerContent}`]: {
                  color: '#74788d',
                  fontWeight: 600
                },
                [`.${gridClasses.virtualScroller}`]: {
                  overflowY: 'auto !important',
                  scrollbarGutter: 'stable',
                  scrollbarWidth: 'none'
                }
              }}
            />
            <Box sx={{
              textAlign: 'right'
            }}
            >
              <Button
                variant='contained'
                disabled={reviewPopUpTableData?.find((data) => data?.hasError) || !reviewPopUpTableData?.length}
                onClick={() => uploadBulkRestrictionApi(reviewPopUpTableData)}
                className='PortfolioEndBtn'
              >
                Submit
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default FileUploadToolbar
