import { API } from 'aws-amplify'
import { useAuth } from '../contexts/AuthContext'
import { useErrorToast } from './useErrorToast'

export default function useDownloadLogs () {
  const { user } = useAuth()
  const { sendSentryError } = useErrorToast()

  const createCSVDownloadLog = (dataDefinition, dataId, dataDownloaded, additionalInfo = {}) => {
    API.post('baseDataValidation', `data-validation/v1/${user?.userGroup}/data/download/log`, {
      body: {
        dataDefinition: dataDefinition || '',
        dataId: dataId || '',
        dataDownloaded: dataDownloaded || '',
        additionalInfo: additionalInfo || {}
      }
    })
      .then(res => {
      })
      .catch((error) => {
        sendSentryError(error)
      })
  }

  return { createCSVDownloadLog }
}
