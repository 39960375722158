import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, TextField, Typography } from '@mui/material'
import { HtmlTooltip } from './CustomTooltip'

const CustomSharesField = (params) => {
  const { api } = params
  return (
    <>
      <TextField
        value={params?.value}
        type='number'
        onChange={(e) => {
          const numericValue = e.target.value
          api.setEditCellValue({ id: params.id, field: params.field, value: numericValue })
          api.updateRows([{
            id: params.id,
            hasSharesError: (!numericValue || Number(numericValue) <= 0) && (params?.row?.symbol !== '__USD' && params?.row?.symbol !== '__CASH')
          }])

          if (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH') {
            api.setEditCellValue([{ id: params.id, field: 'purchaseCost', value: numericValue }])
            api.updateRows([{
              id: params.id,
              hasSharesError: (!numericValue || Number(numericValue) === 0) && (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH')
            }])
          }
        }}
        sx={{
          width: '100%',
          maxWidth: '150px',
          padding: '8px 8px 8px 0px',
          '& .MuiInputBase-input': {
            padding: '8px 1px 8px 7px !important',
            fontSize: '13px',
            fontWeight: 500
          },
          '& .MuiFormHelperText-root': {
            fontSize: '10px',
            padding: '0px',
            marginLeft: '0px'
          }
        }}
      />
      {
    ((!params.value || Number(params.value) === 0) && (params?.row?.symbol === '__USD' ||
      params?.row?.symbol === '__CASH')) ||
      (Number(params.value) <= 0 && (params?.row?.symbol !== '__USD' && params?.row?.symbol !== '__CASH'))
      ? <HtmlTooltip title={
        <Typography sx={{ fontSize: '14px' }}>
          {
             !params.value && params.value !== 0
               ? 'Shares Required'
               : Number(params.value) === 0 && (params?.row?.symbol === '__USD' || params?.row?.symbol === '__CASH')
                 ? 'Shares can not be 0'
                 : Number(params.value) <= 0 && (params?.row?.symbol !== '__USD' && params?.row?.symbol !== '__CASH') ? 'Shares should be greater than 0' : ''
          }
        </Typography>
      }
        >
        <IconButton>
          <InfoOutlinedIcon sx={{ fontSize: '15px', color: '#D32F2F' }} />
        </IconButton>
      </HtmlTooltip>
      : ''
  }
    </>
  )
}

export default CustomSharesField
